import { alpha, styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  pointerEvents: 'none',
  svg: {
    color: `${theme.palette.forground.secondary}!important`,
  },
  background: theme.palette.createBrandGradients.primary(
    theme.palette.mode === 'dark'
      ? {
          alpha1: alpha(theme.palette.common.black, 0.1),
          alpha2: alpha(theme.palette.common.black, 0.6),
        }
      : {
          alpha1: alpha(theme.palette.common.white, 0.1),
          alpha2: alpha(theme.palette.common.white, 0.8),
        },
  ),
})) as typeof IconButton;

export default StyledIconButton;
