import { TFnType } from '@tolgee/react';
import * as Yup from 'yup';

export const enterEmailSchema = (t: TFnType) =>
  Yup.object().shape({
    email: Yup.string()
      .email(t('layout.auth.validations.email.invalid', 'Invalid email'))
      .required(
        t('layout.auth.validations.email.required', 'Email is required'),
      ),
  });

export const verifyEmailSchema = (t: TFnType) =>
  Yup.object().shape({
    code: Yup.string().required(
      t(
        'layout.auth.validations.email.verification',
        "Verification code can't be empty",
      ),
    ),
  });

export const passwordSchema = Yup.object().shape({
  password: Yup.string().required(),
  intent: Yup.string().required(),
});

export const enterPasswordSchema = (t: TFnType) =>
  Yup.object().shape({
    password: Yup.string().required(
      t('layout.auth.validations.password.required', "Password can't be empty"),
    ),
    rememberMe: Yup.boolean().default(false),
  });

export const setupPasswordSchema = (t: TFnType) =>
  Yup.object().shape({
    password: Yup.string()
      .required(
        t(
          'layout.auth.validations.password.required',
          "Password can't be empty",
        ),
      )
      .min(
        8,
        t(
          'layout.auth.validations.password.min',
          'Password must be at least 8 characters long',
        ),
      ),
    is_subscribe_marketing: Yup.boolean(),
  });

export const setupNameSchema = (t: TFnType) =>
  Yup.object().shape({
    first_name: Yup.string().required(
      t(
        'layout.auth.validations.profile.firstName.required',
        "First name can't be empty",
      ),
    ),
  });

export const resetPasswordSchema = (t: TFnType) =>
  Yup.object().shape({
    password: Yup.string()
      .required(
        t('layout.auth.validations.password.min', "Password can't be empty"),
      )
      .min(
        8,
        t(
          'layout.auth.validations.password.min',
          'Password must be at least 8 characters long',
        ),
      ),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref('password'), undefined],
        t('layout.auth.validations.password.match', 'Password must match'),
      )
      .required(
        t(
          'layout.auth.validations.password.requiredConfirm',
          "Confirm password can't be empty",
        ),
      ),
  });
