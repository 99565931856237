import Stack from '@mui/material/Stack';

import Text from '@components/text';
import FeaturedIcon from '@components/workspace/setup/sections/steps/components/featured-icon';
import {
  StyledStack,
  StyledText,
} from '@components/workspace/setup/sections/steps/upload/generate-icon-zone/styles';

import ChevRightIcon from '~icons/knowz-iconify/chev-right';
import SmartStarIcon from '~icons/knowz-iconify/smart-star';

import { useTranslate } from '@tolgee/react';

export default function GenerateIcon() {
  const { t } = useTranslate();

  return (
    <StyledStack gap={3}>
      <FeaturedIcon size="small">
        <SmartStarIcon />
      </FeaturedIcon>
      <Stack gap={1.5}>
        <StyledText
          variant="textXs"
          weight="semibold"
        >
          {t(
            'page.workspace.createWorkspaceModal.step2.subSteps.step1.generateIcon.segment1',
            'Generate an icon',
          )}
          <ChevRightIcon fontSize="1.7em" />
        </StyledText>
        <Text
          variant="textXs"
          color="text.disabled"
        >
          {t(
            'page.workspace.createWorkspaceModal.step2.subSteps.step1.generateIcon.segment2',
            'Knowz AI can help you make a custom icon',
          )}
        </Text>
      </Stack>
    </StyledStack>
  );
}
