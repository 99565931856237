import { styled } from '@mui/material';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';

export const StyledStack = styled(Stack)(({ theme }) => ({
  transform: 'translate(-50%, -50%)',
  top: '50%',
  left: '50%',
  position: 'fixed',
  backgroundColor: theme.palette.background.card.light,
  borderRadius: theme.shape.borderRadius * 4,
}));

export const StyledModal = styled(Modal)(({ theme }) => ({
  maxWidth: '100%',
  //? we add one to the modal zIndex to ensure it is always on top of Popovers like the workspaceSetupPopover
  zIndex: theme.zIndex.modal + 1,
}));
