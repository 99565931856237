import { RouterProvider } from 'react-router-dom';

import ToastifyContainer from '@components/toastify';

import Providers from '@providers/index';

import 'react-toastify/dist/ReactToastify.css';

import CookiesNotice from '@layouts/components/cookies-notice';
import router from '@router/index';

export function App() {
  return (
    <Providers>
      <ToastifyContainer />
      <CookiesNotice />
      <RouterProvider router={router} />
    </Providers>
  );
}

export default App;
