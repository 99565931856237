import { useEffect } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';

import FormProvider from '@components/react-hook-form/FormProvider';
import RHFInputField from '@components/react-hook-form/RHFInputField';
import Text from '@components/text';
import FormHeading from '@components/user-authentication/components/form-heading';
import useSendEmailVerification from '@components/user-authentication/hooks/useSendEmailVerification';
import useUserAuth from '@components/user-authentication/hooks/useUserAuth';
import useVerifyEmail from '@components/user-authentication/hooks/useVerifyEmail';
import { verifyEmailSchema } from '@components/user-authentication/schemas';
import ResendCodeVerification from '@components/user-authentication/sections/steps/verify-email/ResendCodeVerification';

import useQueryParams from '@hooks/useQueryParams';

import { StripePaymentStatusType } from '@shared-types/payment';

import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslate } from '@tolgee/react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

const defaultValues = {
  code: '',
};

type VerifyEmailFormType = {
  code: string;
};

export default function VerifyEmail() {
  const {
    handleNextStep,
    email,
    handleSetCode,
    activeStep,
    authenticationJourney,
  } = useUserAuth();
  const { t } = useTranslate();

  const methods = useForm<VerifyEmailFormType>({
    resolver: yupResolver(verifyEmailSchema(t)),
    defaultValues,
  });

  const {
    formState: { errors, isDirty, isSubmitting },
    handleSubmit,
  } = methods;

  const {
    mutateAsync: mutateVerifyEmailAsync,
    isPending: isVerifyEmailPending,
  } = useVerifyEmail();

  const {
    mutateAsync: mutateSendEmailVerificationAsync,
    isPending: isSendEmailVerificationPending,
    error: sendEmailVerificationError,
  } = useSendEmailVerification();

  const { getQueryParamByKey } = useQueryParams();

  const subscriptionQueryParam =
    getQueryParamByKey<StripePaymentStatusType>('subscription');
  const paymentQueryParam =
    getQueryParamByKey<StripePaymentStatusType>('payment');

  const isBusy =
    isSubmitting || isVerifyEmailPending || isSendEmailVerificationPending;

  async function onSubmit(data: VerifyEmailFormType) {
    const { status } = await mutateVerifyEmailAsync({ code: data.code, email });
    if (status === 200) {
      toast.success(
        t(
          'layout.auth.messages.success.verifyEmail',
          'Email verified successfully',
        ),
      );
      handleSetCode(data.code);
      handleNextStep();
    }
  }

  useEffect(() => {
    if (authenticationJourney !== 'register' && activeStep !== 1) return;
    (async function () {
      const sendEmailResponse = await mutateSendEmailVerificationAsync({
        email,
      });
      if (
        sendEmailResponse.status === 200 &&
        sendEmailResponse.data.status === 'Ok'
      ) {
        toast.success(
          t(
            'layout.auth.messages.success.resendCode',
            'Code sent successfully. Please check your email.',
          ),
        );
        return;
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutateSendEmailVerificationAsync]);

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormHeading
        heading={t('layout.auth.steps.verifyEmail.label', 'Sign up code')}
        withBackButton
      />
      <Stack gap={5}>
        {subscriptionQueryParam === 'success' ||
          (paymentQueryParam === 'success' && (
            <Stack sx={{ marginBlockEnd: 5 }}>
              <Text
                variant="textMd"
                sx={{ color: 'success.main' }}
              >
                {t(
                  'layout.auth.messages.success.subscription',
                  "Thanks for subscribing! Please verify your email so you don't mistakenly loose your account",
                )}
              </Text>
            </Stack>
          ))}
        <Text
          component="span"
          variant="textMd"
        >
          {t(
            'layout.auth.steps.verifyEmail.description',
            'Check your email. We have sent you a temporary code to {email} to continue signing up.',
            {
              email,
            },
          )}
        </Text>
        <RHFInputField
          type="text"
          name="code"
          autoComplete="off"
          disabled={isBusy}
          placeholder={t(
            'layout.auth.steps.verifyEmail.form.code.placeholder',
            'Code',
          )}
          error={!!errors.code?.message}
          fullWidth
          helperText={errors.code?.message}
        />
        {sendEmailVerificationError?.message.includes('429') && (
          <Text
            variant="textXs"
            color="error"
          >
            {t(
              'response.errors.code.429',
              'Too many requests. Please try again later.',
            )}
          </Text>
        )}
        <LoadingButton
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          color="primary"
          disabled={!isDirty || isBusy}
          fullWidth
        >
          {t('layout.auth.buttons.verify', 'Verify')}
        </LoadingButton>
        <Stack
          direction="row"
          gap={3}
          sx={{ alignItems: 'center' }}
        >
          <Text variant="textXs">
            {t(
              'layout.auth.steps.verifyEmail.resendCode.segment1',
              "Didn't receive the code?",
            )}
          </Text>
          <ResendCodeVerification />
        </Stack>
      </Stack>
    </FormProvider>
  );
}
