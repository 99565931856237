import { useCallback, useEffect, useState } from 'react';

import { useTranslate } from '@tolgee/react';
import { toast } from 'react-toastify';

export type PermissionName =
  | 'geolocation'
  | 'notifications'
  | 'push'
  | 'microphone'
  | 'camera'
  | 'speaker'
  | 'device-info'
  | 'background-sync'
  | 'bluetooth'
  | 'persistent-storage'
  | 'ambient-light-sensor'
  | 'accelerometer'
  | 'gyroscope'
  | 'magnetometer'
  | 'clipboard-read'
  | 'clipboard-write'
  | 'payment-handler'
  | 'unknown-permission'
  | string;

export interface PermissionState {
  state: PermissionStatus['state'] | 'prompt';
  isLoading: boolean;
  error: string | null;
}

const usePermission = (
  permissionName: PermissionName,
): { permission: PermissionState; requestMicrophone: VoidFunction } => {
  const [permission, setPermission] = useState<PermissionState>({
    state: 'prompt',
    isLoading: false,
    error: null,
  });
  const { t } = useTranslate();

  useEffect(() => {
    if (typeof navigator === 'undefined' || !navigator.permissions) {
      setPermission({
        state: 'denied',
        isLoading: false,
        error: 'Permissions API is not supported',
      });
      return;
    }

    const queryPermission = async () => {
      setPermission((prev) => ({ ...prev, isLoading: true }));

      try {
        const permissionStatus = await navigator.permissions.query({
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          name: permissionName as any,
        });
        setPermission({
          state: permissionStatus.state,
          isLoading: false,
          error: null,
        });

        permissionStatus.onchange = () => {
          setPermission({
            state: permissionStatus.state,
            isLoading: false,
            error: null,
          });
        };
      } catch (error) {
        console.error(error);
        setPermission({
          state: 'denied',
          isLoading: false,
          error:
            error instanceof Error
              ? `Error querying ${permissionName} permission: ${error.message}`
              : 'Unknown error',
        });
      }
    };

    queryPermission();
  }, [permissionName]);

  const requestMicrophone = useCallback(() => {
    if (
      navigator.mediaDevices &&
      typeof navigator.mediaDevices.getUserMedia == 'function'
    ) {
      window.navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(() => {
          setPermission({ error: null, isLoading: false, state: 'granted' });
        })
        .catch(function (error) {
          console.error('Microphone access error:', error);
          setPermission({ error: error, isLoading: false, state: 'denied' });
        });
    } else {
      console.error('getUserMedia not supported');
      toast.info(
        t(
          'page.liveSession.mic.support',
          'Browser does not support getUserMedia please use Chrome.',
        ),
      );
      setPermission({
        error: 'getUserMedia not supported',
        isLoading: false,
        state: 'denied',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { permission, requestMicrophone };
};

export default usePermission;
