import { useState } from 'react';

import { useTheme } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import Input from '@components/input';
import Dropdown from '@components/invitation-form/components/dropdown';
import FieldSetWrapper from '@components/invitation-form/components/FieldSetWrapper';
import CONFIG from '@components/invitation-form/config';
import emailSchema from '@components/invitation-form/schema';
import useWorkspaceSetup from '@components/workspace/setup/hooks/useWorkspaceSetup';

import { RoleType } from '@shared-types/memberships';

import DeleteIcon from '~icons/knowz-iconify/delete-rounded';
import CheckMailIcon from '~icons/knowz-iconify/mail-check-rounded';
import MailIcon from '~icons/knowz-iconify/mail-rounded';

import { useTranslate } from '@tolgee/react';

type Props = {
  id: string;
  email: string;
  role: RoleType;
  isInvitationSent: boolean;
  handleDisableSendInvitesButtonImparatively: (value: boolean) => void;
};

type ValidationMessagesType = 'duplicated' | 'invalid' | 'empty';

const VALIDATION_MESSAGES: Record<ValidationMessagesType, string> = {
  duplicated: 'Duplicate email. Please remove it or enter a different one.',
  invalid: 'Invalid email',
  empty: 'Email cannot be empty',
};

const SHARED_PROPS: ButtonProps = {
  disableElevation: true,
  disableRipple: true,
  disableFocusRipple: true,
};

export default function Field({
  email,
  id,
  role,
  isInvitationSent,
  handleDisableSendInvitesButtonImparatively,
}: Props) {
  const { t } = useTranslate();
  const { membersToInvite, handleDeleteMember, handleUpdateMember } =
    useWorkspaceSetup();
  const theme = useTheme();
  const [fieldError, setFieldError] = useState<ValidationMessagesType | null>(
    null,
  );
  function handleDeleteMemebertoInvite(email: string) {
    return () => {
      handleDeleteMember(email);
    };
  }

  async function handleUpdateMemebr(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    { id, role }: { id: string; role: RoleType },
  ) {
    const isTherADupkicateEmail = membersToInvite.some(
      (member) => member.email === event.currentTarget.value,
    );

    const isEmailValid = emailSchema.isValidSync({
      email: event.currentTarget.value,
    });

    if (isTherADupkicateEmail || !isEmailValid) {
      if (isTherADupkicateEmail) {
        setFieldError('duplicated');
      }

      if (!isEmailValid) {
        setFieldError('invalid');
      }
      handleDisableSendInvitesButtonImparatively(true);
    } else {
      setFieldError(null);
      handleDisableSendInvitesButtonImparatively(false);
    }

    handleUpdateMember({
      email: event.currentTarget.value,
      id,
      role,
      isInvitationSent: false,
    });
  }

  function handleUpdateRole(
    role: RoleType,
    { id, email }: { id: string; email: string },
  ) {
    handleUpdateMember({
      email,
      id,
      role,
      isInvitationSent: false,
    });
  }

  return (
    <FieldSetWrapper>
      <Tooltip
        title={fieldError && VALIDATION_MESSAGES[fieldError]}
        open={Boolean(fieldError)}
        placement="top"
        arrow
      >
        <Input
          value={email}
          error={Boolean(fieldError)}
          readOnly={isInvitationSent}
          disabled={isInvitationSent}
          fullWidth
          type="text"
          onChange={(event) => handleUpdateMemebr(event, { id, role })}
          startAdornment={<MailIcon />}
          placeholder={t(
            'page.workspace.createWorkspaceModal.step3.form.email.placeholder',
            'Ex: member@gmail.com',
          )}
        />
      </Tooltip>
      <FieldSetWrapper sx={{ width: '100%' }}>
        <Stack
          direction={'row'}
          sx={{ width: '100%' }}
          gap={2.5}
        >
          <Dropdown
            disabled={isInvitationSent}
            onChange={(role) => handleUpdateRole(role, { id, email })}
            role={role}
            roleItems={CONFIG.ROLE_ITEMS}
          />

          {isInvitationSent ? (
            <IconButton
              color="outlinedSecondary"
              disabled
            >
              <CheckMailIcon
                fontSize="1.3em"
                color={theme.palette.forground.secondary}
              />
            </IconButton>
          ) : (
            <IconButton
              color="outlinedSecondary"
              onClick={handleDeleteMemebertoInvite(email)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Stack>
      </FieldSetWrapper>
    </FieldSetWrapper>
  );
}
