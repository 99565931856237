import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import SearchIcon from '@mui/icons-material/Search';

import { SearchModeType } from '@components/search-form/types';

const Types = {
  ADVANCED: 'advanced',
  FLUID: 'fluid',
  QUICK: 'quick',
} as const;

const MODES = [Types.ADVANCED, Types.FLUID, Types.QUICK];

const SEARCH_MODES: SearchModeType = {
  advanced: {
    name: Types.ADVANCED,
    defaultName: 'Genius',
    mappedName: 'page.home.hero.search.genius.name',
    defaultTitle: 'Genius',
    mappedTitle: 'page.home.hero.search.genius.title',
    mappedDescription: 'page.home.hero.search.genius.description',
    defaultDescription:
      'Ideal for deep dives and the most thorough, accurate results.',
    icon: (
      <AutoAwesomeIcon
        sx={{
          fontSize: '1.3em',
          marginInline: (theme) => theme.spacing(2.8),
        }}
      />
    ),
  },
  fluid: {
    name: Types.FLUID,
    defaultName: 'Fluid',
    mappedName: 'page.home.hero.search.fluid.name',
    icon: (
      <SearchIcon
        sx={{
          fontSize: '1.3em',
          marginInline: (theme) => theme.spacing(2.8),
        }}
      />
    ),
    defaultTitle: 'Fluid',
    mappedTitle: 'page.home.hero.search.fluid.title',
    mappedDescription: 'page.home.hero.search.fluid.description',
    defaultDescription:
      'Ideal for finding balanced, accurate results with both speed and depth.',
  },
  quick: {
    name: Types.QUICK,
    defaultName: 'Fast',
    mappedName: 'page.home.hero.search.quick.name',
    icon: (
      <RocketLaunchIcon
        sx={{
          fontSize: '1.3em',
          marginInline: (theme) => theme.spacing(2.8),
        }}
      />
    ),
    defaultTitle: 'Fast',
    mappedTitle: 'page.home.hero.search.quick.title',
    mappedDescription: 'page.home.hero.search.quick.description',
    defaultDescription:
      'Perfect for quick answers when you need information in a flash.',
  },
};

const CONFIG = {
  MODES,
  SEARCH_MODES,
} as const;

export default CONFIG;
