import { sourceConfigurations } from '@lib/agent';

import { queryClient } from '@providers/ReactQueryProvider';

import useWorkspace from '@hooks/useWorkspace';

import { SourceConfigurationType } from '@shared-types/sources';

import { useMutation } from '@tanstack/react-query';
import {
  DefaultParamType,
  TFnType,
  TranslationKey,
  useTranslate,
} from '@tolgee/react';
import { toast } from 'react-toastify';

export default useCreateOneSourceConfiguration;

export function useCreateOneSourceConfiguration() {
  const { t } = useTranslate();
  const { workspace } = useWorkspace();

  return useMutation({
    mutationFn: createOneSourceConfiguration,
    mutationKey: ['createOneSourceConfiguration', workspace?.id],
    onError: (error) => onError(t, error),
    onSuccess: (data) => {
      const connectorsCache = queryClient.getQueryData(['connectors']) as {
        data: SourceConfigurationType[];
      };
      if (connectorsCache && connectorsCache.data) {
        const { data: connectorsData } = connectorsCache;
        connectorsData.push(data.data);
        queryClient.setQueryData(['connectors'], connectorsCache);
      }
    },
  });
}

async function createOneSourceConfiguration(body: SourceConfigurationType) {
  return await sourceConfigurations.createOneSourceConfiguration(body);
}

function onError(
  t: TFnType<DefaultParamType, string, TranslationKey>,
  error: unknown,
) {
  toast.error(
    t('response.errors.code.520', 'Unknown error. Please try again later.'),
  );
  console.error(error);
}
