import StyledLink from '@components/enterprise-button/styles';
import Text from '@components/text';

import ChevRightIcon from '~icons/knowz-iconify/chev-right';
import ZKnowzIcon from '~icons/knowz-iconify/knowz-z';

import paths from '@router/paths';
import { useTranslate } from '@tolgee/react';

export default function EnterpriseButton() {
  const { t } = useTranslate();

  return (
    <StyledLink
      to={paths.enterprise.pathname}
      target="_blank"
    >
      <ZKnowzIcon fontSize="1.2em" />
      <Text
        variant="textSm"
        weight="medium"
      >
        {t('page.home.hero.overlineButton', 'Enterprise Solutions')}
      </Text>
      <ChevRightIcon fontSize="1.2em" />
    </StyledLink>
  );
}
