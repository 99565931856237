import { useCallback, useEffect, useState } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import Skeleton from '@mui/material/Skeleton';

import Text from '@components/text';
import useUserAuth from '@components/user-authentication/hooks/useUserAuth';

import { auth } from '@lib/agent';
import convertMillisecondsToTime from '@lib/convertMillisecondsToTime';

import { useIsMutating } from '@tanstack/react-query';
import { useTranslate } from '@tolgee/react';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';

const CODE_VERIFICATION_INTERVAL_IN_MS = 600_000; //? 10 minutes
const SECOND = 1000;

export default function ResendCodeVerification() {
  const [timer, setTimer] = useState(CODE_VERIFICATION_INTERVAL_IN_MS);

  const isMutating = useIsMutating({ mutationKey: ['sendEmailVerification'] });

  const { t } = useTranslate();

  const { email } = useUserAuth();

  const [isCounting, setIsCounting] = useState(
    timer <= CODE_VERIFICATION_INTERVAL_IN_MS,
  );

  useEffect(() => {
    if (isMutating !== 0) return;
    if (timer === 0) return setIsCounting(false);

    const timerInterval = setInterval(() => {
      setTimer(timer - SECOND);
    }, SECOND);

    return () => clearInterval(timerInterval);
  }, [isMutating, timer]);

  const handleResendCode = useCallback(async () => {
    if (email == null) return;
    try {
      //TODO: fetching data strategy should be react query later on we can add loading state for loading button
      await auth.sendEmailVerification({
        email,
      });
      toast(
        t(
          'layout.auth.messages.success.resendCode',
          'Code sent successfully. Please check your email.',
        ),
        {
          type: 'success',
        },
      );
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 429) {
        toast(
          t(
            'response.errors.code.429',
            'Too many requests. Please try again later.',
          ),
          {
            type: 'error',
          },
        );
        return;
      }
      toast(
        t('response.errors.code.520', 'Unknown error. Please try again later.'),
        {
          type: 'error',
        },
      );
    }
    setIsCounting(true);
    setTimer(CODE_VERIFICATION_INTERVAL_IN_MS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  return isCounting ? (
    <Text variant="textXs">
      {isMutating !== 0 ? (
        <Skeleton
          width={40}
          height={21}
        />
      ) : (
        convertMillisecondsToTime(timer)
      )}
    </Text>
  ) : (
    <LoadingButton
      variant="text"
      size="small"
      onClick={handleResendCode}
    >
      {t('layout.auth.steps.verifyEmail.resendCode.segment2', 'Resend code')}
    </LoadingButton>
  );
}
