import { useEffect, useRef, useState } from 'react';

import { useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import {
  StyledAvatarImage,
  StyledAvatarWrapper,
  StyledContainer,
  StyledControlsContainer,
  StyledEditButton,
  StyledLoadingButton,
} from '@components/settings/components/tabs/general/components/avatar/styles';
import useMutateMe from '@components/settings/components/tabs/general/hooks/useMutateMe';

import { storage } from '@lib/agent';
import { setSession } from '@lib/jwt';

import useAuth from '@hooks/useAuth';
import useGetCredentialByKeyLazily from '@hooks/useGetCredentialByKey';
import useCreateRefreshToken from '@hooks/useGetRefreshToken';

import DeleteIcon from '~icons/knowz-iconify/delete-rounded';
import EditRoundedIcon from '~icons/knowz-iconify/edit-rounded';

import { useTranslate } from '@tolgee/react';
import AwsS3 from '@uppy/aws-s3';
import Uppy, { Meta } from '@uppy/core';
import { FileInput, useUppyEvent } from '@uppy/react';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import { toast } from 'react-toastify';

export default function Avatar() {
  const theme = useTheme();
  const { t } = useTranslate();
  const { me, handleUpdateMe } = useAuth();
  const { credentialData } = useGetCredentialByKeyLazily({
    key: me?.profile_picture,
  });

  const { mutateCreateRefreshTokenAsync } = useCreateRefreshToken(
    (variables) => {
      const token = variables.data.token;
      setSession(token);
      handleUpdateMe(token);
    },
  );

  const [blob, setBlob] = useState<string | undefined>(() => {
    return credentialData?.data.url;
  });
  const isThereABlob = Boolean(blob);
  const [isUploading, setIsUploading] = useState(false);
  const inputRef = useRef<FileInput<Meta, Record<string, never>> | null>(null);

  useEffect(() => {
    setBlob(credentialData?.data.url);
  }, [credentialData]);

  const { mutateAsync: mutateMeAsync } = useMutateMe({
    onError: () => {
      toast.error(
        t(
          'layout.main.sections.header.settings.messages.error.updateSettings',
          'An error occurred while updating settings',
        ),
      );
    },
    onSuccess: () => {
      toast.success(
        t(
          'layout.main.sections.header.settings.messages.success.saveSettings',
          'Your profile has been updated',
        ),
      );
    },
  });

  const uppy = initializeUppy();

  useUppyEvent(uppy, 'thumbnail:generated', async (file) => {
    setIsUploading(true);
    setBlob(file.preview);
    await uppy.upload();
    mutateCreateRefreshTokenAsync();
    toast.success('Your profile picture has been updated');
    setIsUploading(false);
  });

  useUppyEvent(uppy, 'upload-error', () => {
    console.error('error');
  });

  const handleDeleteAvatar = async () => {
    setBlob(undefined);
    await mutateMeAsync({
      firstName: me?.first_name,
      lastName: me?.last_name,
      profilePicture: null,
    });
    mutateCreateRefreshTokenAsync();
  };

  const handleEditClick = () => {
    inputRef.current?.plugin?.handleClick();
  };

  return (
    <StyledContainer>
      <StyledAvatarWrapper>
        <StyledAvatarImage
          src={blob}
          alt="Profile avatar"
        />

        <StyledEditButton
          size="small"
          onClick={handleEditClick}
        >
          <EditRoundedIcon
            color={
              theme.palette.mode === 'light'
                ? theme.palette.grey[800]
                : theme.palette.grey[100]
            }
            fontSize="0.55em"
          />
        </StyledEditButton>
      </StyledAvatarWrapper>

      <StyledControlsContainer
        sx={{
          justifyContent: isThereABlob ? 'space-between' : 'flex-end',
        }}
      >
        {isThereABlob && (
          <IconButton
            color="tertiarySecondary"
            onClick={handleDeleteAvatar}
          >
            <DeleteIcon
              fontSize="2.7em"
              color={theme.palette.text.disabled}
            />
          </IconButton>
        )}
        <StyledLoadingButton
          component="label" //? keep this for triggering system file picker
          role={undefined}
          variant="text"
          size="small"
          tabIndex={-1}
          loading={isUploading}
        >
          <FileInput
            uppy={uppy}
            ref={inputRef}
          />
          Update
        </StyledLoadingButton>
      </StyledControlsContainer>
    </StyledContainer>
  );
}

function initializeUppy() {
  return new Uppy({
    restrictions: {
      allowedFileTypes: ['image/jpeg', 'image/jpg', 'image/png'],
    },
    allowMultipleUploadBatches: false,
  })
    .use(ThumbnailGenerator, {
      thumbnailWidth: 100,
      thumbnailHeight: 100,
    })
    .use(AwsS3, {
      async getUploadParameters(file) {
        const name = file.data.name;
        const size = file.data.size;
        const sizeInKB = size / 1024;
        const type = file.type;

        const { data } = await storage.createPolicy<{
          usage: 'profile_picture';
        }>({
          name: name,
          size: sizeInKB,
          mimeType: type,
          context: {
            usage: 'profile_picture',
          },
        });

        return {
          method: 'PUT',
          fields: [],
          headers: {
            'Content-Type': type,
          },
          url: data.url,
        };
      },
    });
}
