import Stack from '@mui/material/Stack';

import Text from '@components/text';

import { StyledCard } from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/sections/components/cta-card/card/styles';

type Props = {
  title: string;
  description: string;
  actionButtons: {
    primary?: React.ReactNode;
    secondary?: React.ReactNode;
  };
};

export default function Card({ title, description, actionButtons }: Props) {
  return (
    <StyledCard
      component={Stack}
      gap={5}
    >
      <Stack gap={1}>
        <Text
          variant="textMd"
          weight="semibold"
          sx={{ marginBlockEnd: 2.5 }}
        >
          {title}
        </Text>
        <Text variant="textSm">{description}</Text>
      </Stack>
      <Stack gap={3}>
        {actionButtons.primary}
        {actionButtons.secondary}
      </Stack>
    </StyledCard>
  );
}
