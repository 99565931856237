//TODO: Custom SVG Icons should not be served as URLs since it results in adding extra requests to the server. Instead, they should be imported as components from our package.
import { useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';

import EnterpriseButton from '@components/enterprise-button';
import StyledText from '@components/pages/home/styles';
import { FormType } from '@components/pages/search/types';
import {
  StyledCenteredContent,
  StyledGradientText,
  StyledViewportHeightStack,
} from '@components/pages/styles';
import SearchForm from '@components/search-form';
import Text from '@components/text';

import useAppSelector from '@hooks/useAppSelector';
import { useCreateOneSearchThread } from '@hooks/useCreateOneThread';

import paths from '@router/paths';
import { useTranslate } from '@tolgee/react';

export default function Home() {
  const { t } = useTranslate();

  const navigateTo = useNavigate();

  const theme = useTheme();

  const imageThreadId = useAppSelector(
    (state) => state.searchResult.image?.threadId,
  );

  const { mutateAsync: createOneSearchThreadAsync } =
    useCreateOneSearchThread();

  async function onSubmit({ name }: FormType) {
    let threadId = imageThreadId;

    if (!threadId) {
      const { data } = await createOneSearchThreadAsync({
        type: 'search',
        name,
      });

      threadId = String(data?.id);
    }

    navigateTo(`${paths.search.pathname}?t=${threadId}`, {
      state: { prompt: name, isUserRedirected: true },
    });
  }

  return (
    <StyledViewportHeightStack>
      <StyledCenteredContent gap={8}>
        <EnterpriseButton />

        <Stack gap={2.5}>
          <Text
            component="h1"
            variant="displayXl"
            weight="semibold"
            sx={{ textAlign: 'center' }}
          >
            <StyledGradientText component="span">
              {t('page.home.hero.title', 'Unleash AI Insights')}
            </StyledGradientText>
          </Text>

          <StyledText
            variant="textMd"
            align="center"
          >
            {t('page.home.hero.subtitle', 'Search no more, Knowz better')}
          </StyledText>
        </Stack>

        <SearchForm
          onSubmit={onSubmit}
          sx={{
            width: theme.spacing(139),
            minWidth: theme.spacing(10),
            [theme.breakpoints.down('md')]: {
              width: theme.spacing(100),
            },
            [theme.breakpoints.down('sm')]: {
              width: theme.spacing(80),
            },
          }}
        />
      </StyledCenteredContent>
    </StyledViewportHeightStack>
  );
}
