import { TFnType } from '@tolgee/react';
import * as Yup from 'yup';

const addAssistantsSchema = (t: TFnType) =>
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .max(
        60,
        t(
          'page.assistants.validations.name.max',
          "Name can't be longer than 60 characters",
        ),
      )
      .required(
        t('page.assistants.validations.name.required', "Name can't be empty"),
      ),
    description: Yup.string()
      .trim()
      .required(
        t(
          'page.assistants.validations.description',
          "Description can't be empty. Please provide a brief description of the assistant.",
        ),
      ),
    supportsPublicFallback: Yup.boolean(),
    supportsPublicDefault: Yup.boolean(),
    voice: Yup.string().default('Intellectual and Analytical'),
    personality: Yup.string().default('Professional and Formal'),
    responseStyle: Yup.string().default('Moderate and Informative'),
  });

export default addAssistantsSchema;
