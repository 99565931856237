import Stack from '@mui/material/Stack';

import Text from '@components/text';
import StyledStack from '@components/upgrade-modal/components/card/popular-badge/styles';

import useResponsive from '@hooks/useResponsive';

import StarIcon from '~icons/knowz-iconify/star';

import { useTranslate } from '@tolgee/react';

type Props = {
  switcher: React.ReactNode;
};

export default function PopularBadge({ switcher }: Props) {
  const { t } = useTranslate();
  const isItDesktop = useResponsive('up', 'md');

  return (
    <StyledStack
      direction={isItDesktop ? 'row' : 'column'}
      gap={3}
    >
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
        gap={2}
      >
        <StarIcon />
        <Text
          variant="textSm"
          weight="semibold"
        >
          {t('layout.upgrade.mostPopular', 'Most popular')}
        </Text>
      </Stack>
      {switcher}
    </StyledStack>
  );
}
