import { useLocation, useNavigate } from 'react-router-dom';

import EditNoteIcon from '@mui/icons-material/EditNote';
import HistoryIcon from '@mui/icons-material/History';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import Logo from '@components/logo';
import useGetOneAssistantByBotId from '@components/pages/hooks/useGetOneAssistantByBotId';
import Text from '@components/text';

import { useCreateOneAssistantThread } from '@hooks/useCreateOneThread';

import useAssistantsSession from '@layouts/assistants-session/hooks/useAssistantsSession';
import HeaderWrapper from '@layouts/components/header-wrapper';
import paths from '@router/paths';
import { useTranslate } from '@tolgee/react';

export default function Header() {
  const { toggleHistoryDrawer } = useAssistantsSession();
  const navigateTo = useNavigate();
  const { state } = useLocation() as {
    state: { assistantId?: number; isUserRedirected: boolean };
  };

  function toggleHistoryPanel() {
    toggleHistoryDrawer();
  }
  const { t } = useTranslate();
  const {
    mutateAsync: mutateCreateOneAssistantThreadAsync,
    isPending: isCreateOneAssistantThreadPending,
  } = useCreateOneAssistantThread();

  const {
    oneAssistantData,
    isGetOneAssistantLoading,
    isGetOneAssistantFetching,
  } = useGetOneAssistantByBotId(state?.assistantId);

  async function handleNewAssistantSession() {
    const name = new Date().toISOString();
    const { data } = await mutateCreateOneAssistantThreadAsync({
      name,
      type: 'assistant',
      bot: Number(oneAssistantData?.id),
    });

    navigateTo(`${paths.assistantsSession.pathname}?t=${data.id}`, {
      state: {
        isUserRedirected: true,
      },
    });
  }

  return (
    <HeaderWrapper>
      <Logo />
      {isGetOneAssistantLoading || isGetOneAssistantFetching ? (
        <Skeleton
          variant="text"
          width={100}
        />
      ) : (
        <Text
          variant="textSm"
          sx={{ color: 'text.secondary' }}
        >
          {oneAssistantData?.name}
        </Text>
      )}
      <Stack
        direction="row"
        gap={2}
      >
        <Tooltip
          arrow
          title={t(
            'layout.assistantSession.header.tooltip.newThread',
            'New thread',
          )}
        >
          <IconButton
            color="tertiarySecondary"
            disabled={isCreateOneAssistantThreadPending}
            onClick={handleNewAssistantSession}
          >
            <EditNoteIcon />
          </IconButton>
        </Tooltip>

        <Tooltip
          arrow
          title={t('layout.assistantSession.header.tooltip.history', 'History')}
        >
          <IconButton
            color="tertiarySecondary"
            onClick={toggleHistoryPanel}
          >
            <HistoryIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    </HeaderWrapper>
  );
}
