import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';

import {
  stepsConfig,
  validatorConfig,
} from '@components/pages/command-designer/config';
import { BlockStepExtension } from '@components/pages/command-designer/extensions/block-step/blockStepExtension';
import useAddPackageButtonExtension from '@components/pages/command-designer/hooks/useAddPackageButtonExtension';
import { useGetCommandBlocksPerPackage } from '@components/pages/command-designer/hooks/useCommandBlocks';
import useStepsDefinition from '@components/pages/command-designer/hooks/useStepsDefinition';
import useViewport from '@components/pages/command-designer/hooks/useViewport';
import RootEditor from '@components/pages/command-designer/sections/root-editor';
import StepEditor from '@components/pages/command-designer/sections/step-editor';
import AddPackageButton from '@components/pages/command-designer/sections/toolbox/AddPackageButton';
import AddPackageModal from '@components/pages/command-designer/sections/toolbox/AddPackageModal';
import {
  StyledApp,
  StyledMenuToggle,
} from '@components/pages/command-designer/styles';
import Text from '@components/text';

import { CommandBlockType } from '@lib/agent';

import useAppSelector from '@hooks/useAppSelector';
import useAuth from '@hooks/useAuth';
import {
  useGetCommandDefinition,
  useSaveCommandDefinition,
  useUpdateOneCommand,
} from '@hooks/useCommands';
import usePortal from '@hooks/usePortal';
import useThemeSettings from '@hooks/useThemeSettings';

import { CommandType } from '@shared-types/agent';
import { HashMap } from '@shared-types/utils';

import ChevDowntIcon from '~icons/knowz-iconify/chev-down';
import Cross from '~icons/knowz-iconify/cross';

import { useTranslate } from '@tolgee/react';
import { toast } from 'react-toastify';
import {
  StepsConfiguration,
  ValidatorConfiguration,
} from 'sequential-workflow-designer';
import {
  SequentialWorkflowDesigner,
  wrapDefinition,
  WrappedDefinition,
} from 'sequential-workflow-designer-react';

const SAVE_DEBOUNCE_IN_SECS = 15;
let debounceTimeout: NodeJS.Timeout | null = null;

function cleanup() {
  if (debounceTimeout) {
    clearTimeout(debounceTimeout);
    debounceTimeout = null;
  }
}

export default function CommandDesigner() {
  const [packageModalOpen, setPackageModalOpen] = useState(false);

  const workFlowDesignRef = useRef<HTMLDivElement | null>(null);
  const addPackageButtonExtension =
    useAddPackageButtonExtension(workFlowDesignRef);

  const {
    toolboxConfiguration,
    isLoadingStepsDefinition,
    command,
    updateToolboxConfiguration,
  } = useStepsDefinition();

  const stepsExtension = useMemo(
    () => ({ steps: BlockStepExtension.create() }),
    [],
  );
  const { viewportExtension, focusStep, setDefaultViewport } = useViewport();
  const { t } = useTranslate();
  const { authMethod } = useAuth(); // TODO: for Amir to look into (authMethod does not exist anymore)
  const { id } = useParams();
  const { themeMode } = useThemeSettings();
  const isThemeModeDark = themeMode === 'dark';
  const [wrappedDefinition, setWrappedDefinition] = useState();
  const [selectedStepId, setSelectedStepId] = useState<string | null>(null);
  const [isEditorCollapsed, setIsEditorCollapsed] = useState(true);
  const [isToolboxCollapsed, setIsToolboxCollapsed] = useState<boolean | null>(
    null,
  );
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const stepsConfiguration: StepsConfiguration = useMemo(() => stepsConfig, []);
  const validatorConfiguration: ValidatorConfiguration = useMemo(
    () => validatorConfig,
    [],
  );

  const { navbarMain } = usePortal();
  const runnerStatus = useAppSelector((state) => state.flowRun.runnerStatus);
  const flow = useAppSelector((state) => state.flowRun.currentEvent);
  const { commandDefinition, isLoadingCommandDefinition } =
    useGetCommandDefinition(id!);
  const { mutateAsync: mutateCommandDefinitionAsync } =
    useSaveCommandDefinition();
  const { mutateAsync: mutateUpdateOneCommandAsync } = useUpdateOneCommand();

  const [actualCommand, setActualCommand] = useState<CommandType | undefined>(
    undefined,
  );

  const [actualBlocks, setActualBlocks] = useState<number[]>([]);

  const { blockPackages } = useGetCommandBlocksPerPackage();
  useEffect(() => {
    if (command) {
      setActualCommand(command);
      setActualBlocks(
        command.commandBlocks.map(
          (commandBlock: CommandBlockType) => commandBlock.id!,
        ),
      );
    }
  }, [command]);

  useEffect(() => {
    if (!wrappedDefinition?.value && commandDefinition) {
      setWrappedDefinition(wrapDefinition(commandDefinition));

      if (isToolboxCollapsed === null) {
        setIsToolboxCollapsed(commandDefinition.sequence.length !== 0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commandDefinition]);

  useEffect(() => {
    const states = flow?.state || [];
    const lastStep = states.findLast((state: string) => state.match(/^STEP_/));
    const stepId = lastStep ? lastStep.replace(/^STEP_/, '') : null;

    setSelectedStepId(stepId);
    stepId && focusStep(stepId); // TODO: restore pre-run viewport
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flow]);

  useEffect(() => {
    runnerStatus === 'idle' && setDefaultViewport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runnerStatus]);
  const save = useCallback(
    function (isManualSave = false) {
      const { value: definition } = wrappedDefinition; //TODO
      // TODO: use a proper hashing once available
      const oldDefinitionFingerprint = JSON.stringify(commandDefinition);
      const newDefinitionFingerprint = JSON.stringify(definition);
      const hasChanges = oldDefinitionFingerprint !== newDefinitionFingerprint;

      if (hasChanges) {
        const payload: HashMap<string> = {};

        for (const key of ['name', 'description', 'icon']) {
          const value = definition.properties[key] || '';
          const isValidChange =
            actualCommand[key] !== value && (key === 'icon' || value); // name can't be empty

          if (isValidChange) payload[key] = value;
        }

        Object.keys(payload).length &&
          mutateUpdateOneCommandAsync({ id: id!, ...payload });
        mutateCommandDefinitionAsync({ commandId: id!, definition });
      }

      if (isManualSave) {
        toast.success(
          t('page.commands.toast.save', 'Changes saved successfully'),
        );
      }

      setHasUnsavedChanges(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, actualCommand, commandDefinition, wrappedDefinition],
  );

  useEffect(() => {
    if (hasUnsavedChanges) {
      if (!debounceTimeout) {
        debounceTimeout = setTimeout(save, SAVE_DEBOUNCE_IN_SECS * 1000);
      }
    } else {
      cleanup();
    }

    return () => cleanup();
  }, [hasUnsavedChanges, save]);

  const isLoading =
    isLoadingCommandDefinition ||
    !wrappedDefinition ||
    isLoadingStepsDefinition;

  if (isLoading) {
    return (
      <LinearProgress
        sx={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          width: '50%',
          maxWidth: '400px',
          transform: 'translate(-50%, -50%)',
        }}
      />
    );
  }

  function onDefinitionChange(
    wrappedDefinition: WrappedDefinition<{
      properties: Record<string, never>;
      sequence: [];
    }>,
  ) {
    setHasUnsavedChanges(true);
    setWrappedDefinition(wrappedDefinition);
  }

  return (
    <StyledApp
      isRunning={runnerStatus !== 'idle'}
      isLoggedIn={authMethod === 'email'}
    >
      {workFlowDesignRef.current &&
        createPortal(
          <AddPackageButton setPackageModalOpen={setPackageModalOpen} />,
          workFlowDesignRef.current,
        )}
      {/**SAVE NOW TEXT TOP LEFT  **/}
      {navbarMain &&
        createPortal(
          hasUnsavedChanges && (
            <Button
              variant="text"
              onClick={() => save(true)}
              sx={{
                opacity: 0.4,
                color: (theme) => theme.palette.text.secondary,

                '&:hover': {
                  opacity: 1,
                  backgroundColor: 'transparent!important',
                  color: (theme) => theme.palette.text.secondary,
                },
              }}
            >
              {t('page.commandDesigner.save', 'Save now')}
            </Button>
          ),
          navbarMain,
        )}
      {packageModalOpen && (
        <AddPackageModal
          id={id!}
          packageModalOpen={packageModalOpen}
          setPackageModalOpen={setPackageModalOpen}
          command={actualCommand}
          setActualCommand={setActualCommand}
          actualBlocks={actualBlocks}
          setActualBlocks={setActualBlocks}
          blockPackages={blockPackages}
          updateToolboxConfiguration={updateToolboxConfiguration}
        />
      )}
      {/**HEADER OF CONFIGURATION SECTION AT THE RIGHT  **/}
      <StyledMenuToggle
        onClick={() => setIsEditorCollapsed(!isEditorCollapsed)}
        className={isEditorCollapsed ? 'collapsed' : undefined}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text variant="displayXs">
            {t('page.commands.buttons.configuration', 'Configuration')}
          </Text>
          {isEditorCollapsed ? <ChevDowntIcon /> : <Cross />}
        </Stack>
      </StyledMenuToggle>
      {/**LEFT TOOLBOX AND MIDDLE SEQUENCE **/}
      <SequentialWorkflowDesigner
        key={themeMode}
        theme={isThemeModeDark ? 'dark' : 'light'}
        definition={wrappedDefinition}
        onDefinitionChange={onDefinitionChange}
        stepsConfiguration={stepsConfiguration}
        validatorConfiguration={validatorConfiguration}
        toolboxConfiguration={toolboxConfiguration}
        controlBar
        contextMenu={false}
        rootEditor={
          /**CONTENT OF CONFIGURATION SECTION AT THE RIGHT  WHEN THERE IS NO STEP SELECTED**/
          <RootEditor
            onDefinitionChange={onDefinitionChange}
            commandId={id!}
            save={save}
          />
        }
        stepEditor={
          /**CONTENT OF CONFIGURATION SECTION AT THE RIGHT  WHEN A STEP IS SELECTED**/
          <StepEditor />
        }
        keyboard={false}
        selectedStepId={selectedStepId}
        onSelectedStepIdChanged={setSelectedStepId}
        isEditorCollapsed={isEditorCollapsed}
        isToolboxCollapsed={
          isToolboxCollapsed === null ? false : isToolboxCollapsed
        }
        onIsEditorCollapsedChanged={setIsEditorCollapsed}
        onIsToolboxCollapsedChanged={setIsToolboxCollapsed}
        extensions={[
          viewportExtension,
          stepsExtension,
          { uiComponents: [addPackageButtonExtension] },
        ]}

        // isReadonly?: boolean;
        // undoStackSize?: number;
        // controller?: SequentialWorkflowDesignerController;
        // customActionHandler?: CustomActionHandler;
        // extensions?: DesignerExtension[];
      />
    </StyledApp>
  );
}
