import { useState } from 'react';

import * as Icons from '@mui/icons-material';
import { keyframes } from '@mui/material';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import EllipsisText from '@components/ellipsis-typography';
import { StyledListItem } from '@components/pages/commands/styles';

import useThemeSettings from '@hooks/useThemeSettings';

import { CommandMinType } from '@shared-types/agent';

import { useTranslate } from '@tolgee/react';

const PULSATE_SIZE = 8;
const PULSATE_MARGIN = 8;
const PULSATE_AURA = PULSATE_SIZE + PULSATE_SIZE * 0.5;

export default function CommandListItem({
  command: { name, description, icon, isEnabled },
  onOpen,
  onToggle,
  onRemove,
}: {
  command: CommandMinType;
  onOpen: (e: React.MouseEvent) => void;
  onToggle: (e: React.MouseEvent) => void;
  onRemove: (e: React.MouseEvent) => void;
}) {
  const { themeMode } = useThemeSettings();
  const { t } = useTranslate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);
  const iconName = icon || 'Whatshot';
  const { [iconName]: Icon } = Icons;

  const pulsate = keyframes`
    0% { transform: scale(0.1, 0.1); opacity: 0.0; }
    50% { opacity: 1.0; }
    100% { transform: scale(1.2, 1.2); opacity: 0.0; }
  `;

  return (
    <StyledListItem
      components={{ Root: 'div' }}
      onClick={(event) => isOpen || setAnchorEl(event.currentTarget)}
    >
      <Box
        sx={{
          position: 'relative',
          textAlign: 'center',
          width: '100%',
          borderBottom: '1px solid',
          borderColor: 'background.secondary',
          p: 2,
        }}
      >
        <Icon
          sx={{
            fontSize: 48,
            opacity: themeMode === 'dark' ? 0.9 : 0.7,
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: '0px',
            right: '0px',
          }}
        >
          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{
                width: PULSATE_SIZE,
                height: PULSATE_SIZE,
                backgroundColor: (theme) =>
                  isEnabled
                    ? theme.palette.success.main
                    : theme.palette.neutral.grey,
                borderRadius: '50%',
                position: 'absolute',
                top: PULSATE_MARGIN,
                right: PULSATE_MARGIN,
              }}
            ></Box>

            {isEnabled && (
              <Box
                sx={{
                  border: (theme) => `3px solid ${theme.palette.success.main}`,
                  borderRadius: (theme) => theme.shape.borderRadius * 7.5,
                  height: PULSATE_AURA,
                  width: PULSATE_AURA,
                  position: 'absolute',
                  top: PULSATE_MARGIN - (PULSATE_AURA - PULSATE_SIZE) / 2,
                  right: PULSATE_MARGIN - (PULSATE_AURA - PULSATE_SIZE) / 2,
                  opacity: '0.0',
                  animation: `${pulsate} 1.6s ease-out`,
                  animationIterationCount: 'infinite',
                }}
              ></Box>
            )}
          </Box>
        </Box>
      </Box>

      <Box sx={{ p: 3 }}>
        <EllipsisText
          variant="textMd"
          weight="semibold"
          component="h2"
          lineClamp={2}
          title={name}
        >
          {name}
        </EllipsisText>
        <EllipsisText
          variant="textXs"
          color="text.disabled"
          lineClamp={2}
          title={description}
        >
          {description}
        </EllipsisText>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={(event) => {
            setAnchorEl(null);
            onToggle(event);
          }}
        >
          {isEnabled
            ? t('page.commands.disable', 'Deactivate')
            : t('page.commands.enable', 'Activate')}
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            setAnchorEl(null);
            onOpen(event);
          }}
        >
          {t('page.commands.open', 'Edit Command')}
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            setAnchorEl(null);
            onRemove(event);
          }}
        >
          {t('page.commands.delete', 'Delete Command')}
        </MenuItem>
      </Menu>
    </StyledListItem>
  );
}
