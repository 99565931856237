import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MicIcon from '@mui/icons-material/Mic';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import SimpleDropdown from '@components/dropdown/simple';
import {
  StyledCard,
  StyledIcon,
} from '@components/pages/live/sections/recorder/styles';
import { RoomAudioWave } from '@components/pages/live-session/recorder/audio-wave';
import PhoneCallDialog, {
  GOOGLE_MEET_PHONE_KIND,
  JoinByPhoneType,
} from '@components/pages/live-session/recorder/desktop/PhoneCallDialog';
import StyledButton from '@components/pages/live-session/recorder/desktop/styles';
import Timer from '@components/pages/live-session/recorder/LiveTimer';
import Text from '@components/text';

import useAuth from '@hooks/useAuth';

import {
  useMediaDeviceSelect,
  useRoomContext,
} from '@livekit/components-react';
import paths from '@router/paths';
import { useTranslate } from '@tolgee/react';
import { useDialogs } from '@toolpad/core';
import { ConnectionState } from 'livekit-client';
import { useConfirm } from 'material-ui-confirm';

const GOOGLE_MEET_CHANNEL = 'gmeet';
const CONNECT_PHONE_TOPIC = 'connect_phone';
const DISCONNECT_PHONE_TOPIC = 'disconnect_phone';
const MEET_ICON =
  'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9b/Google_Meet_icon_%282020%29.svg/512px-Google_Meet_icon_%282020%29.svg.png'; // TODO

export default function Recorder() {
  const room = useRoomContext();
  const { t } = useTranslate();
  const confirm = useConfirm();
  const navigateTo = useNavigate();
  const { devices, activeDeviceId, setActiveMediaDevice } =
    useMediaDeviceSelect({
      kind: 'audioinput',
    });

  const isRecording = room.state !== ConnectionState.Disconnected;
  const { me } = useAuth();
  const dialogs = useDialogs();
  const [activeChannel, setActiveChannel] = useState<string | null>(
    activeDeviceId,
  );
  const [phoneConnection, setPhoneConnection] =
    useState<JoinByPhoneType | null>(null);

  const channels = useMemo(() => {
    if (me?.alpha) {
      return [
        ...devices.map((device) => ({
          label: device.label,
          deviceId: device.deviceId,
        })),
        { label: 'Google Meet', deviceId: GOOGLE_MEET_CHANNEL },
      ];
    }

    return [...devices];
  }, [me, devices]);

  useEffect(() => {
    setActiveChannel(activeDeviceId);
  }, [activeDeviceId]);

  async function handleDeviceSelection(deviceId: string) {
    const encoder = new TextEncoder();

    if (activeChannel === GOOGLE_MEET_CHANNEL) {
      try {
        await confirm({
          title: t(
            'page.liveSession.confirmDisconnectCall.title',
            'Disconnect ongoing call',
          ),
          description: t(
            'page.liveSession.confirmDisconnectCall.content',
            'Are you sure you want to disconnect the ongoing call?',
          ),
          confirmationText: t(
            'page.liveSession.confirmDisconnectCall.yes',
            'Yes, I want to select a new device',
          ),
          cancellationText: t(
            'page.liveSession.confirmDisconnectCall.no',
            'No, keep call connected',
          ),
        });

        const encodedData = encoder.encode(JSON.stringify(phoneConnection));
        room.localParticipant.publishData(encodedData, {
          reliable: true,
          topic: DISCONNECT_PHONE_TOPIC,
        });
      } catch (err) {
        return;
      }
    }

    if (deviceId === GOOGLE_MEET_CHANNEL) {
      const phone = await dialogs.open(PhoneCallDialog, GOOGLE_MEET_PHONE_KIND);

      if (!phone) {
        if (activeChannel === GOOGLE_MEET_CHANNEL) {
          room.localParticipant.setMicrophoneEnabled(true);
          setActiveMediaDevice(devices[0].deviceId);
        }

        return;
      }

      room.localParticipant.setMicrophoneEnabled(false);

      const encodedData = encoder.encode(JSON.stringify(phone));
      room.localParticipant.publishData(encodedData, {
        reliable: true,
        topic: CONNECT_PHONE_TOPIC,
      });

      setActiveChannel(deviceId);
      setPhoneConnection(phone);
    } else {
      if (activeChannel === GOOGLE_MEET_CHANNEL) {
        room.localParticipant.setMicrophoneEnabled(true);
      }

      setActiveMediaDevice(deviceId);
    }
  }

  function handleStopRecording() {
    navigateTo(paths.live.pathname);
  }

  return (
    <StyledCard
      component={Stack}
      direction="row"
      gap={4}
      sx={{ alignItems: 'center', overflow: 'initial' }}
    >
      <Stack
        direction="row"
        gap={2.5}
        sx={{ alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Stack
          direction="row"
          gap={2.5}
          sx={{ alignItems: 'center' }}
        >
          <Tooltip
            arrow
            title="This will end the current Live session "
            placement="top-start"
          >
            <StyledButton
              color="error"
              onClick={handleStopRecording}
              disableRipple
              disableTouchRipple
              sx={{ flexGrow: 1, justifyContent: 'start' }}
            >
              <StyledIcon className={isRecording ? 'playing' : undefined} />
            </StyledButton>
          </Tooltip>

          {!isRecording && (
            <Text
              variant="textMd"
              sx={{ md: 'block', xs: 'none' }}
            >
              Start new recording
            </Text>
          )}
          {isRecording && (
            <>
              <Text
                variant="textMd"
                sx={{ md: 'block', xs: 'none' }}
              >
                {t('page.liveSession.recorder.recording', 'Recording')}
              </Text>
              <Timer />
            </>
          )}
        </Stack>

        {isRecording && (
          <SimpleDropdown
            disablePortal
            options={channels.map((device) => ({
              text: device.label,
              value: device.deviceId,
              onClick: () => handleDeviceSelection(device.deviceId),
              icon: {
                iconStart: (
                  <>
                    {
                      <CheckIcon
                        fontSize="small"
                        sx={{
                          visibility:
                            activeChannel === device.deviceId
                              ? 'visible'
                              : 'hidden',
                        }}
                      />
                    }
                    {device.deviceId === GOOGLE_MEET_CHANNEL && (
                      <img
                        width="16"
                        height="13.19"
                        src={MEET_ICON}
                        alt="Google Meet"
                      />
                    )}
                  </>
                ),
              },
            }))}
            placement="top"
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, 10],
                },
              },
            ]}
            trigger={{
              element: (
                <Button
                  color="inherit"
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  size="small"
                  endIcon={<ExpandMoreIcon />}
                  startIcon={<MicIcon />}
                ></Button>
              ),
            }}
          />
        )}
      </Stack>

      <Stack
        direction="row"
        sx={{ flexGrow: 1 }}
      >
        <RoomAudioWave />
      </Stack>
    </StyledCard>
  );
}
