import { live } from '@lib/agent/endpoints/live';

import { LiveLiveRequestType } from '@shared-types/live';

import { useMutation } from '@tanstack/react-query';
import { useTranslate } from '@tolgee/react';
import { toast } from 'react-toastify';

export default function useMutateLiveToken() {
  const { t } = useTranslate();

  const {
    mutateAsync: mutateLiveTokenAsync,
    isPending: isMutateLiveTokenPending,
  } = useMutation({
    mutationKey: ['createLiveToken'],
    mutationFn: async (body: LiveLiveRequestType) =>
      await live.createLiveToken(body),
    onError: (error) => {
      toast.error(
        t('response.errors.code.520', 'Unknown error. Please try again later.'),
      );
      console.error('Error while creating live token', error);
    },
  });

  return {
    isMutateLiveTokenPending,
    mutateLiveTokenAsync,
  };
}
