import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';

import createFolderSchema from '@components/pages/knowledge-base/components/modals/create-folder/schemas';
import StyledCard from '@components/pages/knowledge-base/components/modals/styles';
import useKnowledgeBaseData from '@components/pages/knowledge-base/hooks/useKnowledgeBaseData';
import useMutateFoldersById from '@components/pages/knowledge-base/hooks/useMutateFoldersByParentId';
import FormProvider from '@components/react-hook-form/FormProvider';
import RHFInputField from '@components/react-hook-form/RHFInputField';
import Text from '@components/text';

import { queryClient } from '@providers/ReactQueryProvider';

import useGetFolderDataByPath from '@hooks/useGetFolderDataByPath';
import useQueryParams from '@hooks/useQueryParams';
import useWorkspace from '@hooks/useWorkspace';

import { FolderType } from '@shared-types/folders';

import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslate } from '@tolgee/react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

const defaultValues = {
  name: '',
};

export default function CreateFolder() {
  const { t } = useTranslate();
  const { getQueryParamByKey } = useQueryParams();
  const currentPath = getQueryParamByKey<'path' | null>('path');

  const { toggleCreateFolderModal, isCreateFolderModalOpen } =
    useKnowledgeBaseData();

  const methods = useForm({
    resolver: yupResolver(createFolderSchema(t)),
    defaultValues,
  });

  const { folderData } = useGetFolderDataByPath({
    path: currentPath,
  });

  const {
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = methods;

  const { getNamespacedQueryKey } = useWorkspace();

  const { mutateFoldersAsync } = useMutateFoldersById({
    id: folderData?.id,
    onMutate: ({ name }) => {
      const queryKey = getNamespacedQueryKey(
        'folders',
        typeof folderData?.id === 'undefined' ? null : folderData.id,
      );
      const lastFolders = queryClient.getQueryData(queryKey);
      if (lastFolders == null || typeof folderData === 'undefined') return;
      const isItRootFolder = folderData === null;
      queryClient.setQueryData(
        queryKey,
        (
          oldOnes: Array<FolderType & { inCache: boolean | null }>,
        ): Array<FolderType & { inCache: boolean | null }> => {
          return [
            ...oldOnes,
            {
              name,
              parentId: isItRootFolder ? null : folderData.id,
              breadcrumb: isItRootFolder ? '' : folderData.breadcrumb,
              isCollected: false,
              path: isItRootFolder ? name : folderData.path,
              id: oldOnes.length + 1,
              inCache: true,
            },
          ];
        },
      );
      reset();
      toggleCreateFolderModal();
    },
    onSuccess: () => {
      toast.success(
        t(
          'response.success.createFolder',
          'You have successfully created a folder.',
        ),
      );
    },
    onSettled: () => {
      const queryKey = getNamespacedQueryKey(
        'folders',
        typeof folderData?.id === 'undefined' ? null : folderData.id,
      );
      queryClient.invalidateQueries({
        queryKey,
      });
    },
  });

  async function onSubmit(data: { name: string }) {
    await mutateFoldersAsync({
      name: data.name,
    });
  }

  return (
    <Modal
      onClose={toggleCreateFolderModal}
      open={isCreateFolderModalOpen}
      disableRestoreFocus
    >
      <StyledCard
        component={Stack}
        gap={4}
      >
        <Text
          variant="textXl"
          fontWeight={600}
        >
          {t('page.knowledgeBase.modals.createFolder.title', 'Create folder')}
        </Text>
        <FormProvider
          methods={methods}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Stack gap={1}>
            <Text variant="textMd">
              {t(
                'page.knowledgeBase.modals.createFolder.form.name.label',
                'Folder name',
              )}
            </Text>
            <RHFInputField
              autoFocus
              type="text"
              name="name"
              helperText={errors.name?.message}
              placeholder={t(
                'page.knowledgeBase.modals.createFolder.form.name.placeholder',
                'Enter title',
              )}
            />
          </Stack>
          <Stack
            gap={2}
            direction="row"
            sx={{ justifyContent: 'end', mt: 6 }}
          >
            <Button
              variant="outlined"
              onClick={toggleCreateFolderModal}
            >
              {t(
                'page.knowledgeBase.modals.createFolder.form.buttons.cancel',
                'Cancel',
              )}
            </Button>
            <LoadingButton
              onClick={handleSubmit(onSubmit)}
              disabled={!isDirty}
              variant="contained"
            >
              {t(
                'page.knowledgeBase.modals.createFolder.form.buttons.submit',
                'Save',
              )}
            </LoadingButton>
          </Stack>
        </FormProvider>
      </StyledCard>
    </Modal>
  );
}
