import { useState } from 'react';

import ListItem, { ListItemProps } from '@mui/material/ListItem';

import Settings from '@components/settings';
import UserAuthenticationProvider from '@components/user-authentication/providers/UserAuthenticationProvider';

import useAuth from '@hooks/useAuth';

import SettingsIcon from '~icons/knowz-iconify/settings';

import {
  StyledActiveListItemButton,
  StyledListItemText,
} from '@layouts/main/sections/header/sections/user-account-popover/styles';
import { StyledStack } from '@layouts/main/sections/header/styles';
import { useTranslate } from '@tolgee/react';

type Props = {
  SHARED_PROPS?: ListItemProps;
  onClose?: () => void;
};

export default function SettingsButton({ SHARED_PROPS, onClose }: Props) {
  const { t } = useTranslate();
  const [open, setOpen] = useState(false);
  const { isUserAuthenticated } = useAuth();
  function handleOpenSettings() {
    setOpen(true);
  }

  function handleCloseSettings() {
    setOpen(false);
    onClose?.();
  }

  return (
    <>
      <ListItem {...SHARED_PROPS}>
        <StyledActiveListItemButton
          disableRipple
          disableTouchRipple
          disabled={!isUserAuthenticated}
          onClick={handleOpenSettings}
        >
          <StyledStack
            direction="row"
            gap={2}
          >
            <SettingsIcon fontSize="1.35rem" />
            <StyledListItemText
              primary={t(
                'layout.main.sections.header.navigation.settings',
                'Settings',
              )}
              primaryTypographyProps={{
                variant: 'textSm',
              }}
            />
          </StyledStack>
        </StyledActiveListItemButton>
      </ListItem>
      <UserAuthenticationProvider>
        <Settings
          open={open}
          onClose={handleCloseSettings}
        />
      </UserAuthenticationProvider>
    </>
  );
}
