// TODO: we need to create a useObserver hook and a way of making lastElementRef fit into it.
import { useCallback, useRef } from 'react';

import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';

import ThreadListItem from '@components/pages/history/sections/thread-listitem';
import Skeleton from '@components/pages/history/sections/thread-listitem/skeleton';

import useGetInfiniteThreadsLazily from '@hooks/useGetInfiniteThreadsLazily';

import { GetAllThreadsResponseType } from '@shared-types/threads';

import { useTranslate } from '@tolgee/react';

const FIRST_PAGE = 0;
const SKELETON_LIMIT = 10;

export default function Search() {
  const {
    data,
    isLoading: isGetInfiniteSearchThreadsLoading,
    hasNextPage: hasGetInfiniteSearchThreadsNextPage,
    isError: isGetInfiniteSearchThreadsError,
    error: getInfiniteSearchThreadsError,
    fetchNextPage: fetchNextGetInfiniteSearchThreadsPage,
    isFetching: isGetInfiniteSearchThreadsFetching,
  } = useGetInfiniteThreadsLazily<GetAllThreadsResponseType>({
    // TODO: replace with search's specific type
    type: 'search',
  });

  const { t } = useTranslate();
  const observer = useRef<IntersectionObserver>();

  const lastElementRef = useCallback(
    (node: HTMLAnchorElement) => {
      if (isGetInfiniteSearchThreadsLoading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          hasGetInfiniteSearchThreadsNextPage &&
          !isGetInfiniteSearchThreadsFetching
        ) {
          fetchNextGetInfiniteSearchThreadsPage();
        }
      });

      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      fetchNextGetInfiniteSearchThreadsPage,
      hasGetInfiniteSearchThreadsNextPage,
      isGetInfiniteSearchThreadsFetching,
      isGetInfiniteSearchThreadsLoading,
    ],
  );

  if (isGetInfiniteSearchThreadsLoading) {
    return (
      <List
        component={Stack}
        gap={5}
      >
        {Array.from({ length: SKELETON_LIMIT }, (_, i) => {
          return <Skeleton key={i} />;
        })}
      </List>
    );
  }

  if (data?.pages[FIRST_PAGE].data.length === 0) {
    return (
      <Alert severity="info">
        {t('emptyState.historyThreads', 'No threads found. start a new thread')}
      </Alert>
    );
  }

  if (isGetInfiniteSearchThreadsError) {
    console.error(getInfiniteSearchThreadsError.message);
    return (
      <Alert severity="error">
        {t(
          'response.errors.code.502',
          'Unknown error. Please try again later.',
        )}
      </Alert>
    );
  }

  return data?.pages.map((page, index) => (
    <List
      key={index}
      component={Stack}
      gap={5}
    >
      {page?.data.map(
        ({ id, description, lastInteraction, name, thumbnail }) => {
          return (
            <ThreadListItem
              ref={lastElementRef}
              id={id}
              lastInteraction={lastInteraction}
              key={id}
              name={name}
              description={description}
              thumbnail={thumbnail}
            />
          );
        },
      )}
      {hasGetInfiniteSearchThreadsNextPage &&
        isGetInfiniteSearchThreadsFetching && (
          <Stack sx={{ alignItems: 'center' }}>
            <CircularProgress />
          </Stack>
        )}
    </List>
  ));
}
