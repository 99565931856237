import LinkIcon from '@mui/icons-material/Link';

import ActionButton from '@components/pages/knowledge-base/components/action-button';
import useSourcePreview from '@components/source-preview/hooks/useSourcePreview';

import { useTranslate } from '@tolgee/react';

type Props = {
  sourceId: number | undefined;
};

export default function SourcePreviewButton({ sourceId }: Props) {
  const { handleSourcePreviewCardClick } = useSourcePreview();
  const { t } = useTranslate();

  return (
    <ActionButton
      aria-label={t(
        'page.knowledgeBase.actions.sourcePreviewer',
        'Source Preview',
      )}
      title={t('page.knowledgeBase.actions.sourcePreviewer', 'Source Preview')}
      onClick={() => {
        handleSourcePreviewCardClick({ sourceId: JSON.stringify(sourceId) });
      }}
    >
      <LinkIcon fontSize="small" />
    </ActionButton>
  );
}
