import { useState } from 'react';

import { useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';

import Input from '@components/input';
import KnowledgeSourceIcon from '@components/search-form/knowledge-source-icon';
import {
  HEADER_HEIGHT,
  MAX_MODAL_HEIGHT,
} from '@components/settings/components/modal-wrapper';
import ModeSelector from '@components/settings/components/tabs/search/components/mode-selector';
import Row from '@components/settings/components/tabs/search/components/row';
import {
  StyledFormControlLabel,
  StyledIconContainer,
  StyledRadio,
  StyledRadioLabel,
} from '@components/settings/components/tabs/search/style';
import CONFIG from '@components/settings/config';
import Switch from '@components/switch';
import Text from '@components/text';

import { SearchScopeType } from '@providers/AppSettings';

import useAppDispatch from '@hooks/useAppDispatch';
import useAppSettings from '@hooks/useAppSettings';
import useAuth from '@hooks/useAuth';
import useResponsive from '@hooks/useResponsive';

import { scopeUpdated } from '@state/slices/search-result';

import ArrowLeftIcon from '~icons/knowz-iconify/arrow-left';
import EditRoundedIcon from '~icons/knowz-iconify/edit-rounded';
import EditIcon from '~icons/knowz-iconify/edit-squared';
import SearchIcon from '~icons/knowz-iconify/search-smart';
import UploadIcon from '~icons/knowz-iconify/upload-rounded';

import { useTranslate } from '@tolgee/react';

type Props = {
  onClose: () => void;
};

export default function Search({ onClose }: Props) {
  const isItDesktop = useResponsive('up', 'md');
  const { t } = useTranslate();
  const {
    handleSetUploadedToKnowledgeBase,
    handleSetSearchScope,
    handleSetUseCustomStyle,
    handleSetCustomStyle,
    userSpecificSettings,
  } = useAppSettings();

  const { me } = useAuth();
  const userSettings = me && userSpecificSettings?.[me?.uuid];
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [responseStyle, setResponseStyle] = useState(() => {
    if (!me || !userSpecificSettings) return '';
    return userSpecificSettings[me.uuid]?.customStyle.responseStyle;
  });

  function handleResponseStyle(responseStyle: string) {
    if (!me || !userSpecificSettings) return;
    setResponseStyle(responseStyle);
    handleSetCustomStyle({
      ...userSpecificSettings[me.uuid]?.customStyle,
      isUpToDate: false,
      responseStyle,
    });
  }

  function setSearchScope(e: React.ChangeEvent<HTMLInputElement>) {
    const scope = e.target.value as SearchScopeType;
    handleSetSearchScope(scope);
    dispatch(scopeUpdated(scope));
  }

  if (userSettings == null) return null;

  return (
    <>
      <CONFIG.FormHeader
        title={t('layout.main.sections.header.settings.tabs.search', 'Search')}
      >
        {!isItDesktop && (
          <IconButton
            color="tertiarySecondary"
            onClick={onClose}
          >
            <ArrowLeftIcon />
          </IconButton>
        )}
      </CONFIG.FormHeader>

      <Stack
        width="100%"
        sx={{
          overflow: 'auto',
          maxHeight: `calc(${MAX_MODAL_HEIGHT} - ${HEADER_HEIGHT})`,
          padding: theme.spacing(5),
        }}
        gap={4}
      >
        <Row
          icon={
            <StyledIconContainer>
              <SearchIcon color="grey" />
            </StyledIconContainer>
          }
          title={t(
            'layout.main.sections.header.settings.search.defaultSearchMode',
            'Default search mode',
          )}
        >
          <ModeSelector />
        </Row>

        <Divider />

        <Row
          icon={
            <StyledIconContainer>
              <UploadIcon color="grey" />
            </StyledIconContainer>
          }
          title={t(
            'layout.main.sections.header.settings.search.saveUploadToKnoweledgeBase.title',
            'Save Uploads to Knowledge Base',
          )}
          description={t(
            'layout.main.sections.header.settings.search.saveUploadToKnoweledgeBase.description',
            'Save files uploaded automatically to the Knowledge Base. File uploads not available in Fast search mode.',
          )}
        >
          <Switch
            checked={userSettings.shouldSaveUploadsToKnowledgeBase}
            onChange={() =>
              handleSetUploadedToKnowledgeBase(
                !userSettings.shouldSaveUploadsToKnowledgeBase,
              )
            }
          />
        </Row>

        <Divider />

        {/* Enable once auto-play is implemented
        <Row
          icon={
            <StyledIconContainer>
              <PlayIcon color="grey" />
            </StyledIconContainer>
          }
          title="Playback automatically on search"
          description="Automatically speak aloud search results"
        >
          <Switch
            checked={shouldPlayAudioAutomatically}
            onChange={() =>
              handleSetPlayAudioAutomatically(!shouldPlayAudioAutomatically)
            }
          />
        </Row>

        <Divider /> */}

        <Row
          icon={
            <StyledIconContainer>
              <EditIcon color="grey" />
            </StyledIconContainer>
          }
          title={t(
            'layout.main.sections.header.settings.search.customizeResponse.title',
            'Customize response style?',
          )}
          description={t(
            'layout.main.sections.header.settings.search.customizeResponse.description',
            'You can customize how Knowz responds - whether you prefer short and concise replies, rhyming answers, informal tone, emoji-filled messages, or something entirely unique.',
          )}
        >
          <Switch
            checked={userSettings.useCustomStyle}
            onChange={() =>
              handleSetUseCustomStyle(!userSettings.useCustomStyle)
            }
          />
        </Row>

        <Stack gap={3}>
          <Text
            variant="textSm"
            display="flex"
            placehoder={t(
              'layout.main.sections.header.settings.search.customizeResponse.form.placeholder',
              'Respectful, professional & formal.',
            )}
            alignItems="center"
            gap={1}
          >
            {t(
              'layout.main.sections.header.settings.search.customizeResponse.form.title',
              'Customized response style',
            )}
          </Text>

          <Input
            value={responseStyle}
            name="responseStyle"
            onChange={(e) => handleResponseStyle(e.target.value)}
            placeholder="Respectful, professional & formal."
            endAdornment={<EditRoundedIcon width={13} />}
            disabled={!userSettings.useCustomStyle}
          />

          <Text
            variant="textSm"
            color="grey"
          >
            {t(
              'layout.main.sections.header.settings.search.customizeResponse.form.hint',
              'Edit the response style if you want Knowz to respond differently',
            )}
          </Text>
        </Stack>

        <Divider />

        <Stack gap={3}>
          <Row
            title={t(
              'layout.main.sections.header.settings.search.whereToSearch.title',
              'Where to search?',
            )}
            description={t(
              'layout.main.sections.header.settings.search.whereToSearch.subtitle',
              'Select if Knowz searches just the web, your workspace Knowledge Base or both for answers.',
            )}
          />

          <RadioGroup
            sx={{ gap: 2 }}
            value={userSettings.searchScope}
            onChange={setSearchScope}
          >
            <StyledFormControlLabel
              value="web_kb"
              labelPlacement="start"
              control={<StyledRadio />}
              label={
                <StyledRadioLabel>
                  <KnowledgeSourceIcon mode="web_kb" />
                  <Text variant="textSm">Web & Knowledge Base</Text>
                </StyledRadioLabel>
              }
            />

            <StyledFormControlLabel
              value="kb_only"
              labelPlacement="start"
              control={<StyledRadio />}
              label={
                <StyledRadioLabel>
                  <KnowledgeSourceIcon mode="kb_only" />
                  <Text variant="textSm">Knowledge Base Only</Text>
                </StyledRadioLabel>
              }
            />

            <StyledFormControlLabel
              value="web_only"
              labelPlacement="start"
              control={<StyledRadio />}
              label={
                <StyledRadioLabel>
                  <KnowledgeSourceIcon mode="web_only" />
                  <Text variant="textSm">Web Only</Text>
                </StyledRadioLabel>
              }
            />
          </RadioGroup>
        </Stack>
      </Stack>
    </>
  );
}
