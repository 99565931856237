import { styled } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import Stack from '@mui/material/Stack';

export const StyledStackContent = styled(Stack)(({ theme }) => ({
  alignItems: 'flex-start',
  gap: theme.spacing(2),
}));

export const StyledTextContent = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  flexDirection: 'row',
  gap: theme.spacing(3),
}));

export const StyledButton = styled(ButtonBase)(({ theme }) => ({
  marginBlock: theme.spacing(4),
  color: theme.palette.primary.main,
}));
