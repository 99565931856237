import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';

import useQueryParams from '@hooks/useQueryParams';

import useUserFunnelType, {
  UserFunnelType,
} from '@layouts/components/credit-usage/hooks/useUserFunnelType';
import CreateNewWorkspaceButton from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/sections/components/create-new-workspace-buttton';
import CallToActionCard from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/sections/components/cta-card';
import LoadingDropdown from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/sections/components/LoadingDropdown';
import PackPlanCallToActionTooltip from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/sections/components/pack-plan-cta-tooltip';
import Dropdown from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/sections/owned-workspaces/workspaces/dropdown';
import useGetOwnedWorkspaces from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/sections/owned-workspaces/workspaces/hooks/useGetOwnedWorkspaces';
import { useTranslate } from '@tolgee/react';

const TOTAL_WORKSPACE_SLOTS = 4;

const WORKSPACE_SLOTS_LIMIT: Record<
  Extract<UserFunnelType, 'pack' | 'subscribed'>,
  number
> = {
  pack: 2,
  subscribed: 4,
};

export default function Workspaces() {
  const { ownedWorkspacesData, isWorkspaceFetching, hasWorkspaceError } =
    useGetOwnedWorkspaces();
  const { t } = useTranslate();
  const { setQueryParam } = useQueryParams();
  const { userFunnelType } = useUserFunnelType();
  const shouldShowCreateButton =
    userFunnelType === 'pack' || userFunnelType === 'subscribed';

  function handleOpenWorkspaceModalSetup() {
    setQueryParam('action', 'setup-workspace');
  }

  const { numberOfOwnedWorkspaces } = useGetOwnedWorkspaces();

  if (isWorkspaceFetching) {
    return <LoadingDropdown />;
  }

  if (hasWorkspaceError) {
    return (
      <Alert severity="error">
        {t('response.errors.code.520', {
          default: 'Unknown error. Please try again later.',
        })}
      </Alert>
    );
  }

  return (
    <Dropdown options={ownedWorkspacesData}>
      <Box>
        {shouldShowCreateButton ? (
          Array.from(
            { length: TOTAL_WORKSPACE_SLOTS - numberOfOwnedWorkspaces },
            (_, i) => {
              const oneIndexedIndex = i + 1;
              return (
                <Tooltip
                  placement="top"
                  arrow
                  key={i}
                  title={
                    oneIndexedIndex >=
                      WORKSPACE_SLOTS_LIMIT[userFunnelType] && (
                      <PackPlanCallToActionTooltip />
                    )
                  }
                >
                  <span>
                    <MenuItem
                      disableRipple
                      disableTouchRipple
                      disabled={
                        oneIndexedIndex >= WORKSPACE_SLOTS_LIMIT[userFunnelType]
                      }
                      onClick={handleOpenWorkspaceModalSetup}
                    >
                      <CreateNewWorkspaceButton />
                    </MenuItem>
                  </span>
                </Tooltip>
              );
            },
          )
        ) : (
          <CallToActionCard userAccountType={userFunnelType} />
        )}
      </Box>
    </Dropdown>
  );
}
