import { folders } from '@lib/agent';

import { queryClient } from '@providers/ReactQueryProvider';

import useWorkspace from '@hooks/useWorkspace';

import { FolderType } from '@shared-types/folders';

import { useMutation } from '@tanstack/react-query';
import { useTranslate } from '@tolgee/react';
import { toast } from 'react-toastify';

type Props = {
  id?: number;
};

export default function useMoveFolderByCurrentFolderId({ id }: Props) {
  const { getNamespacedQueryKey } = useWorkspace();
  const { t } = useTranslate();
  const queryKey = getNamespacedQueryKey('folders', id);

  return useMutation({
    mutationKey: ['Dnd', 'folder'],
    mutationFn,
    onMutate: (variables) => {
      const lastFolders = queryClient.getQueryData<FolderType[] | null>(
        queryKey,
      );

      if (lastFolders == null) return;
      queryClient.setQueryData(queryKey, () => {
        return lastFolders.filter((folder) => folder.id !== variables.id);
      });
    },
    onSuccess: (_data, { name }) => {
      toast.success(
        `${t(
          'page.knowledgeBase.admin.actions.dragAndDrop.success.folder.segment1',
          'Folder',
        )} "${name}" ${t(
          'page.knowledgeBase.admin.actions.dragAndDrop.success.folder.segment2',
          'successfully moved',
        )}`,
      );
    },
    onError: (error, { name }) => {
      toast.error(
        `${t(
          'page.knowledgeBase.admin.actions.dragAndDrop.error.folder.segment1',
          'Source',
        )} "${name}" ${t(
          'page.knowledgeBase.admin.actions.dragAndDrop.error.folder.segment2',
          'could not be moved, please try again',
        )}`,
      );
      console.error(error);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey,
      });
    },
  });
}

type MutationFnProps = {
  id: number;
  folderToMove: number;
  name: string;
};

async function mutationFn({ folderToMove, id }: MutationFnProps) {
  return await folders.updateOne({ id, parent: folderToMove });
}
