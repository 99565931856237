import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useMutateLiveToken from '@components/pages/live/hooks/useMutateLiveToken';

import useAppSelector from '@hooks/useAppSelector';
import useAppSettings from '@hooks/useAppSettings';
import usePermission from '@hooks/useNavigatorPermissions';
import useTimer from '@hooks/useTimer';

import paths from '@router/paths';
import { useTranslate } from '@tolgee/react';
import { toast } from 'react-toastify';

export default function useStartRecordingSession() {
  const [isLiveSessionStarting, setIsLiveSessionStaring] = useState(false);

  const { t } = useTranslate();

  const groupedTrainingSets = useAppSelector(
    (state) => state.live.groupedTrainingSets,
  );

  const { userGeolocation } = useAppSettings();

  const navigateTo = useNavigate();

  const { factChecker, knowzBot, noteTaker } = useAppSelector(
    (state) => state.live.selectedAssistants,
  );

  const timer = useTimer();

  const { mutateLiveTokenAsync, isMutateLiveTokenPending } =
    useMutateLiveToken();

  //TODO: useGeolocation should be handled like this with useNavigatorPermissions that I wrote
  const {
    permission: { state: permissionState, isLoading: isPermissionLoading },
    requestMicrophone,
  } = usePermission('microphone');

  useEffect(() => {
    if (permissionState === 'granted' && isLiveSessionStarting) {
      (async function () {
        try {
          const trainingSets = Array.from(
            new Set(Object.values(groupedTrainingSets).flat()),
          );

          const { data: liveKitToken } = await mutateLiveTokenAsync({
            factCheck: factChecker,
            knowzBot,
            semantic: trainingSets.length ? { trainingSets } : null,
            restart: false,
            enableRecording: true,
            noteTaker,
            context: {
              latLng: [userGeolocation.lat, userGeolocation.lng],
              language: navigator.language,
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
          });

          navigateTo(
            `${paths.liveSession.pathname}?t=${liveKitToken.threadId}`,
            {
              state: {
                token: liveKitToken.token,
              },
            },
          );
        } catch (error) {
          console.error(error);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionState, isLiveSessionStarting]);

  const handleStartRecoding = useCallback(() => {
    (async function () {
      if (permissionState === 'denied') {
        toast.info(
          t(
            'page.liveSession.mic.denied',
            'Your mic access denied please allow it first',
          ),
          { autoClose: false },
        );
        return;
      }
      requestMicrophone();
      timer.startTimer();
      setIsLiveSessionStaring(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionState, requestMicrophone]);

  return {
    handleStartRecoding,
    isMutateLiveTokenPending,
    isPermissionLoading,
  };
}
