import TooltipContent from '@components/search-form/react-hook-form-search-mode-select/tooltip/content';
import { StyledTooltip } from '@components/search-form/styles';

import useAuth from '@hooks/useAuth';
import useGetCredit from '@hooks/useGetCredit';

import { useTranslate } from '@tolgee/react';

type Props = {
  children: JSX.Element;
  sx?: object;
};

export default function Tooltip({ children, sx }: Props) {
  const { me } = useAuth();
  const { creditsData } = useGetCredit();
  const { t } = useTranslate();

  const hasSecureAccount =
    me?.auth_type === 'email' || me?.auth_type === 'oauth2';

  const hasCredits = creditsData && creditsData.creditAsPercentage > 0;

  if (hasSecureAccount && hasCredits) return children;

  return (
    <StyledTooltip
      sx={sx}
      placement="top"
      title={
        !hasSecureAccount ? (
          <TooltipContent
            hint={t(
              'page.home.hero.search.genius.subtitle',
              'Sign up now to use our most advanced mode.',
            )}
          />
        ) : !hasCredits ? (
          <TooltipContent
            hint={
              'Looks like you have found a premium feature. Upgrade to Premium to try a faster version of smart search'
            }
          />
        ) : null
      }
    >
      {children}
    </StyledTooltip>
  );
}
