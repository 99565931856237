//TODO: we need to get plan content from stripe dynamically
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { SxProps, useTheme } from '@mui/material';
import MuiLink from '@mui/material/Link';
import Stack from '@mui/material/Stack';

import { StyledGradientText } from '@components/pages/styles';
import PLANS from '@components/plans/config';
import GeniusSearchTab from '@components/plans/genius-search-tab';
import { GeniusSearchPlanType } from '@components/plans/types';
import Text from '@components/text';
import Card from '@components/upgrade-modal/components/card';
import { StyledStack } from '@components/upgrade-modal/styles';

import useAuth from '@hooks/useAuth';
import usePaymentWithStripe from '@hooks/usePaymentWithStripe';
import useResponsive from '@hooks/useResponsive';

import paths from '@router/paths';
import { useTranslate } from '@tolgee/react';

type PlansKindType =
  | 'creditPack'
  | 'geniusSearchMonthly'
  | 'geniusSearchYearly'
  | 'geniusUnlimited';

const SHARED_SX: SxProps = {
  borderTopRightRadius: 0,
  borderTopLeftRadius: 0,
};

const CREDIT_PACK_PRICE_ID = String(import.meta.env.VITE_CREDIT_PACK);
const GENIUS_SEARCH_PLAN_MONTHLY_PRICE_ID = String(
  import.meta.env.VITE_GENIUS_SEARCH_PLAN_MONTHLY,
);
const GENIUS_SEARCH_PLAN_YEARLY_PRICE_ID = String(
  import.meta.env.VITE_GENIUS_SEARCH_PLAN_YEARLY,
);
const GENIUS_UNLIMITED_PRICE_ID = String(import.meta.env.VITE_GENIUS_UNLIMITED);

export default function Plans() {
  const [geniusSearchPlan, setGeniusSearchPlan] =
    useState<GeniusSearchPlanType>('monthly');
  const { t } = useTranslate();
  const isItDesktop = useResponsive('up', 'md');
  const { isStripeRequestPending, handleRedirectToStripe } =
    usePaymentWithStripe();
  const { isUserAuthenticated } = useAuth();

  const isBusy = isStripeRequestPending || !isUserAuthenticated;

  const theme = useTheme();

  function togglePlan(
    _event: React.SyntheticEvent,
    value: GeniusSearchPlanType,
  ) {
    setGeniusSearchPlan(value);
  }

  function handleRedirectUserToRequiredPlan(planKind: PlansKindType) {
    return (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      switch (planKind) {
        case 'creditPack':
          handleRedirectToStripe({
            priceId: CREDIT_PACK_PRICE_ID,
            mode: 'payment',
          });
          break;
        case 'geniusSearchMonthly':
          handleRedirectToStripe({
            priceId: GENIUS_SEARCH_PLAN_MONTHLY_PRICE_ID,
            mode: 'subscription',
          });
          break;
        case 'geniusSearchYearly':
          handleRedirectToStripe({
            priceId: import.meta.env.VITE_GENIUS_SEARCH_PLAN_YEARLY,
            mode: 'subscription',
          });
          break;
        case 'geniusUnlimited':
          handleRedirectToStripe({
            priceId: import.meta.env.VITE_GENIUS_UNLIMITED,
            mode: 'subscription',
          });
          break;
        default:
          planKind satisfies never;
      }
    };
  }

  return (
    <StyledStack gap={8}>
      <Stack sx={{ textAlign: 'center' }}>
        <Text variant="displayLg">
          {t('layout.upgrade.title.segment1', 'A plan for')}
          &nbsp;
          <StyledGradientText
            component="span"
            sx={{
              background: theme.palette.gradients('to top').primary,
            }}
          >
            {t('layout.upgrade.title.segment2', 'every need')}
          </StyledGradientText>
        </Text>
        <Text
          variant="textMd"
          weight="semibold"
          color="text.secondary"
        >
          {t(
            'layout.upgrade.subtitle',
            'Pick a plan today to see why Knowz better',
          )}
        </Text>
      </Stack>
      <Stack
        direction={isItDesktop ? 'row' : 'column'}
        gap={4}
        sx={{ alignItems: 'flex-end', width: '100%' }}
      >
        <Card
          title={t(
            'layout.upgrade.plans.creditPack.title',
            '5,000 Credit Pack',
          )}
          subtitle={t(
            'layout.upgrade.plans.creditPack.subtitle',
            'Top up your credits anytime to keep exploring smarter',
          )}
          priceInfo={{
            priceLine:
              PLANS[CREDIT_PACK_PRICE_ID].symbol +
              PLANS[CREDIT_PACK_PRICE_ID].price,
            creditLine: '5,000 Credits',
          }}
          keyFeatures={[
            t(
              'layout.upgrade.plans.creditPack.keyFeatures.0',
              '5,000 credits top-up to use Knowz as you please',
            ),
            t(
              'layout.upgrade.plans.creditPack.keyFeatures.1',
              'Pay as you go, no commitment',
            ),
            t(
              'layout.upgrade.plans.creditPack.keyFeatures.2',
              'Credits never expire!',
            ),
          ]}
          button={{
            onClick: handleRedirectUserToRequiredPlan('creditPack'),
            text: t('layout.upgrade.plans.creditPack.button', 'Buy now'),
            isLoading: isBusy,
          }}
        />
        {geniusSearchPlan === 'monthly' ? (
          <Card
            title={t(
              'layout.upgrade.plans.monthlyGeniusSearch.title',
              'Genius Search Plan',
            )}
            subtitle={t(
              'layout.upgrade.plans.monthlyGeniusSearch.subtitle',
              'Subscribe to incredible AI value with 5,000 credits per month',
            )}
            priceInfo={{
              priceLine:
                PLANS[GENIUS_SEARCH_PLAN_MONTHLY_PRICE_ID].symbol +
                PLANS[GENIUS_SEARCH_PLAN_MONTHLY_PRICE_ID].price,
              creditLine: '/Month + 5,000 Credits',
            }}
            switcher={
              <GeniusSearchTab
                geniusSearchPlan={geniusSearchPlan}
                togglePlan={togglePlan}
              />
            }
            isMostPopular
            keyFeatures={[
              t(
                'layout.upgrade.plans.monthlyGeniusSearch.keyFeatures.0',
                '5,000 credits top up each month',
              ),
              t(
                'layout.upgrade.plans.monthlyGeniusSearch.keyFeatures.1',
                '50MB Storage*',
              ),
              t(
                'layout.upgrade.plans.monthlyGeniusSearch.keyFeatures.2',
                'Most popular plan',
              ),
              t(
                'layout.upgrade.plans.monthlyGeniusSearch.keyFeatures.3',
                'More precise results with Genius',
              ),
              t(
                'layout.upgrade.plans.monthlyGeniusSearch.keyFeatures.4',
                'Monthly or annual plans',
              ),
              t(
                'layout.upgrade.plans.monthlyGeniusSearch.keyFeatures.5',
                'First access to new features',
              ),
            ]}
            sx={SHARED_SX}
            button={{
              onClick: handleRedirectUserToRequiredPlan('geniusSearchMonthly'),
              text: t(
                'layout.upgrade.plans.monthlyGeniusSearch.button',
                'Subscribe and Save',
              ),
              isLoading: isBusy,
            }}
          />
        ) : (
          <Card
            title={t(
              'layout.upgrade.plans.yearlyGeniusSearch.title',
              'Genius Search Plan',
            )}
            subtitle={t(
              'layout.upgrade.plans.yearlyGeniusSearch.subtitle',
              'Subscribe to incredible AI value with 5,000 credits per month',
            )}
            priceInfo={{
              creditLine: t(
                'layout.upgrade.plans.yearlyGeniusSearch.priceInfo.creditLine',
                '/Year + 60,000 Credits',
              ),
              priceLine:
                PLANS[GENIUS_SEARCH_PLAN_YEARLY_PRICE_ID].symbol +
                PLANS[GENIUS_SEARCH_PLAN_YEARLY_PRICE_ID].price,
            }}
            switcher={
              <GeniusSearchTab
                geniusSearchPlan={geniusSearchPlan}
                togglePlan={togglePlan}
              />
            }
            isMostPopular
            sx={SHARED_SX}
            keyFeatures={[
              t(
                'layout.upgrade.plans.geniusSearch.keyFeatures.0',
                '60,000 credits to use when and how you please',
              ),
              t(
                'layout.upgrade.plans.geniusSearch.keyFeatures.1',
                '50MB Storage*',
              ),
              t(
                'layout.upgrade.plans.geniusSearch.keyFeatures.2',
                'Best value & most popular plan',
              ),
              t(
                'layout.upgrade.plans.geniusSearch.keyFeatures.3',
                'More precise results with Genius',
              ),
              t(
                'layout.upgrade.plans.geniusSearch.keyFeatures.4',
                'Monthly or annual plans',
              ),
              t(
                'layout.upgrade.plans.geniusSearch.keyFeatures.5',
                'First access to new features',
              ),
            ]}
            button={{
              onClick: handleRedirectUserToRequiredPlan('geniusSearchYearly'),
              text: t(
                'layout.upgrade.plans.geniusSearch.button',
                'Subscribe and Save',
              ),
              isLoading: isBusy,
            }}
          />
        )}
        <Card
          title={t(
            'layout.upgrade.plans.geniusUnlimited.title',
            'Genius Unlimited',
          )}
          subtitle={t(
            'layout.upgrade.plans.geniusUnlimited.subtitle',
            'Foundation member offer with a yearly plan of smart searching and access exclusive betas.',
          )}
          priceInfo={{
            priceLine:
              PLANS[GENIUS_UNLIMITED_PRICE_ID].symbol +
              PLANS[GENIUS_UNLIMITED_PRICE_ID].price,
            creditLine: t(
              'layout.upgrade.plans.geniusUnlimited.priceInfo.creditLine',
              '/Year + UNLIMITED Credits',
            ),
          }}
          keyFeatures={[
            t(
              'layout.upgrade.plans.geniusUnlimited.keyFeatures.0',
              'UNLIMITED Credits to use Knowz',
            ),
            t(
              'layout.upgrade.plans.geniusUnlimited.keyFeatures.1',
              'Advanced Smart Search tools',
            ),
            t(
              'layout.upgrade.plans.geniusUnlimited.keyFeatures.2',
              'More precise Search results',
            ),
            t(
              'layout.upgrade.plans.geniusUnlimited.keyFeatures.3',
              'Free 3-day Trial, no risk',
            ),
            t(
              'layout.upgrade.plans.geniusUnlimited.keyFeatures.4',
              'Shape the features we create next',
            ),
          ]}
          button={{
            onClick: handleRedirectUserToRequiredPlan('geniusUnlimited'),
            text: t(
              'layout.upgrade.plans.geniusUnlimited.button',
              'Join us Today',
            ),
            isLoading: isBusy,
          }}
        />
      </Stack>
      <Text
        variant="textXs"
        color="text.secondary"
      >
        {t(
          'layout.upgrade.extraInfo.segment1',
          '*Purchase additional storage at 1 credit per 10MB, for larger needs, inquire about our',
        )}
        <MuiLink
          target="_blank"
          sx={{ textDecoration: 'underline' }}
          component={Link}
          to={paths.enterprise.pathname}
        >
          {t('layout.upgrade.extraInfo.segment2', 'Enterprise Packages')}
        </MuiLink>
        .
      </Text>
    </StyledStack>
  );
}
