/* eslint-disable import/prefer-default-export */
import { useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { useCreateOneSecret } from '@components/pages/command-designer/hooks/useSecrets';
import Text from '@components/text';
import Textarea from '@components/textarea';

import { useTranslate } from '@tolgee/react';
import { DialogProps } from '@toolpad/core';

export function SecretDefinitionDialog({
  open,
  onClose,
}: DialogProps<undefined, void>) {
  const { t } = useTranslate(); // Added the t() function from useTranslate
  const [name, setName] = useState('');
  const [value, setValue] = useState('');
  const hasValidName = useMemo(() => !!name.match(/^[a-zA-Z0-9]+$/), [name]);
  const { status, mutateAsync: mutateCreateOneSecretAsync } =
    useCreateOneSecret();

  async function handleDefineSecret() {
    await mutateCreateOneSecretAsync({ name, value });
    onClose();
  }

  return (
    <Dialog
      sx={{
        ...(status === 'pending'
          ? {
              opacity: 0.8,
              pointerEvents: 'none',
            }
          : {}),
      }}
      fullWidth
      open={open}
      onClose={(_, reason) => {
        if (reason && reason === 'backdropClick' && status === 'pending')
          return;
        onClose(null);
      }}
    >
      <DialogTitle>
        <Text
          variant="displaySm"
          component="span"
          sx={{ px: 2 }}
        >
          {t(
            'page.commandDesigner.secretDefinition.defineSecret',
            'Define a secret',
          )}
        </Text>

        <Text
          variant="textMd"
          sx={{ px: 2 }}
        >
          {t(
            'page.commandDesigner.secretDefinition.secretDescription',
            "Secrets can be used in a safe manner across different commands. Once configured, you won't be able to see the value of this secret anymore.",
          )}
        </Text>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ px: 2 }}>
          <TextField
            fullWidth
            size="small"
            data-gramm="false"
            data-gramm_editor="false"
            data-enable-grammarly="false"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            placeholder={t(
              'page.commandDesigner.secretDefinition.secretNamePlaceholder',
              'Secret name',
            )}
            sx={{
              mb: 2,
              ...(hasValidName || !name
                ? {}
                : {
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderWidth: '1px !important',
                      borderColor: (theme) =>
                        `${theme.palette.error.main} !important`,
                    },
                  }),
            }}
            onChange={(el) => setName(el.target.value)}
          />

          <Textarea
            minRows={3}
            data-gramm="false"
            data-gramm_editor="false"
            data-enable-grammarly="false"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            placeholder={t(
              'page.commandDesigner.secretDefinition.secretValuePlaceholder',
              'Secret value',
            )}
            onChange={(el) => setValue(el.target.value)}
          />

          <Text
            color="GrayText"
            variant="textXs"
          >
            {t(
              'page.commandDesigner.secretDefinition.secretInfoText',
              "You can also use secrets in blocks, and share those blocks with the certainty that secrets won't be compromised. (TO DO: THIS PROMISE NEEDS TO BE FULFILLED AND IT IS CURRENTLY NOT)",
            )}
          </Text>
        </Box>
      </DialogContent>

      <DialogActions sx={{ mt: 3 }}>
        <Button
          onClick={() => onClose()}
          variant="outlined"
        >
          {t('common.cancel', 'Cancel')}
        </Button>

        {status !== 'idle' ? (
          <Stack
            sx={{ px: 5 }}
            justifyContent="center"
          >
            <CircularProgress size="24px" />
          </Stack>
        ) : (
          <Button
            disabled={!hasValidName || !value.trim()}
            onClick={handleDefineSecret}
            variant="contained"
          >
            {t('common.save', 'Save')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
