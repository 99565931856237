import { auth } from '@lib/agent';

import { LoginBodyType } from '@shared-types/auth';

import { useMutation } from '@tanstack/react-query';
import { useTranslate } from '@tolgee/react';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';

const login = async (data: LoginBodyType) => await auth.login(data);

export default function useLogin() {
  const { t } = useTranslate();

  return useMutation({
    mutationFn: login,
    onSuccess: () => {
      toast.success(
        t('response.success.login', 'You have successfully logged in.'),
      );
    },
    onError: (error) => {
      if (isAxiosError(error) && error.response?.status === 401) {
        toast.error(
          t(
            'response.errors.code.401',
            'Invalid credentials. Please try again.',
          ),
        );
        return;
      }
      toast.error(
        t(
          'response.errors.code.520',
          'Something went wrong. Please try again later.',
        ),
      );
      console.error(error);
    },
  });
}
