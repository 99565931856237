import { useLocation } from 'react-router-dom';

import paths from '@router/paths';

export default function useIsKnowledgeBaseUrlActive() {
  const { pathname } = useLocation();

  return {
    isActive: pathname === paths.knowledgeBase.pathname,
  };
}
