import { styled } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary, {
  accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper, { paperClasses } from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';

import Text from '@components/text';

export const StyledPaper = styled(Paper)(({ theme }) => ({
  paddingInline: theme.spacing(2),
  paddingBlock: theme.spacing(1.5),
  overflow: 'auto',
  maxHeight: theme.spacing(92.5),
}));

export const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.modal + 1,
  width: theme.spacing(67),
  [theme.breakpoints.up('md')]: {
    width: theme.spacing(78),
  },
  borderRadius: theme.spacing(3),
  border: '1px solid',
  borderColor: theme.palette.border.secondary,
}));

export const StyledRoleItemStack = styled(Stack)(({ theme }) => ({
  alignItems: 'start',
  borderRadius: theme.shape.borderRadius * 2,
})) as typeof Stack;

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  [`&.${paperClasses.root}`]: {
    padding: 0,
    borderRadius: theme.spacing(0.15),
    backgroundColor: 'transparent',
  },
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: 'list-item!important',
  listStyleType: 'disc',
  fontSize: '0.8rem',
  marginInlineStart: theme.spacing(5),
}));

export const StyledListItemText = styled(ListItemText)(() => ({
  margin: 0,
}));

export const SytyledBoxWrapper = styled(Box)(() => ({
  width: '100%',
}));

export const StyledTitleText = styled(Text)(() => ({
  textTransform: 'capitalize',
}));

export const StyledSubtitleText = styled(Text)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  [`.${accordionSummaryClasses.expandIconWrapper}`]: {
    transform: 'unset!important',
  },
}));
