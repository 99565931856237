import { ListItemIcon } from '@mui/material';

import Text from '@components/text';

import PlusIcon from '~icons/knowz-iconify/plus';

import StyledStack from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/sections/components/create-new-workspace-buttton/styles';
import { StyledChip } from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/sections/owned-workspaces/styles';
import StyledAvatar from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/styles';
import { useTranslate } from '@tolgee/react';

export default function CreateNewWorkspaceButton() {
  const { t } = useTranslate();

  return (
    <StyledStack direction="row">
      <ListItemIcon>
        <StyledAvatar>
          <PlusIcon />
        </StyledAvatar>
      </ListItemIcon>
      <StyledStack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Text variant="textSm">
          {t('page.workspace.createButton.dropdown', 'Create New')}
        </Text>
        <StyledChip
          size="small"
          label="0"
        />
      </StyledStack>
    </StyledStack>
  );
}
