import { Link } from 'react-router-dom';

import Text from '@components/text';

import {
  StyledMuiLink,
  StyledStack,
} from '@layouts/main/sections/toolbar/components/legal-links/styles';
import paths from '@router/paths';
import { useTranslate } from '@tolgee/react';

export default function LegalLinks() {
  const { t } = useTranslate();

  return (
    <StyledStack
      direction="row"
      gap={3.75}
    >
      <StyledMuiLink
        component={Link}
        target="_blank"
        to={paths.pp.pathname}
        sx={{
          textAlign: 'right',
        }}
      >
        <Text variant="textXs">
          {t('layout.toolbar.buttons.pp', 'Privacy Policy')}
        </Text>
      </StyledMuiLink>
      <StyledMuiLink
        component={Link}
        target="_blank"
        to={paths.tos.pathname}
        sx={{
          textAlign: 'left',
        }}
      >
        <Text variant="textXs">
          {t('layout.toolbar.buttons.tos', 'Terms of Service')}
        </Text>
      </StyledMuiLink>
    </StyledStack>
  );
}
