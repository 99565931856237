import { KnowzStepDefinitionType } from '@lib/step/types';

const TimeoutYieldStepDefinition: KnowzStepDefinitionType = {
  order: 801,
  group: 'Yields',
  step: {
    componentType: 'task',
    type: 'timeout-yield',
    name: 'Timeout',
  },
  propertyTypes: [
    {
      id: 'timeout',
      name: 'Timeout',
      description:
        'Select a lapse for the command to pause for. ' +
        'The command execution will continue once the timeout has been reached.',
      usage: 'definition',
      options: [
        '15 minutes',
        '30 minutes',
        '1 hour',
        '3 hours',
        '6 hours',
        '12 hours',
        '1 day',
        '3 days',
        '1 week',
        '2 weeks',
        '1 month',
      ],
      wrapper: 'item',
      types: ['string'],
    },
  ],
};

export default TimeoutYieldStepDefinition;
