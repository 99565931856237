import Button from '@mui/material/Button';
// import ButtonBase from '@mui/material/ButtonBase';
import Stack from '@mui/material/Stack';

import InvitationForm from '@components/invitation-form';
import ButtonWrapper from '@components/workspace/setup/components/button-wrapper';
import Header from '@components/workspace/setup/components/header';
import useWorkspaceSetup from '@components/workspace/setup/hooks/useWorkspaceSetup';
import FeaturedIcon from '@components/workspace/setup/sections/steps/components/featured-icon';
import StyledBoxWrapper from '@components/workspace/setup/sections/steps/invite-members/styles';

import useQueryParams from '@hooks/useQueryParams';

import CubeIcon from '~icons/knowz-iconify/cube';

import { useTranslate } from '@tolgee/react';

export default function InviteMembers() {
  const { handleGoToPreviousStep, handleReset } = useWorkspaceSetup();
  const { t } = useTranslate();
  const { removeQueryParamByKey } = useQueryParams();
  // function handleNext() { //TODO: uncomment this after merging remote sources
  //   handleGoToStep('connectors');
  // }

  // TODO: remove this after merging remote sources
  function handleFinishWorkspaceSetup() {
    removeQueryParamByKey('action');
    handleReset();
  }

  return (
    <>
      <Header
        Icon={
          <FeaturedIcon>
            <CubeIcon />
          </FeaturedIcon>
        }
        title={t(
          'page.workspace.createWorkspaceModal.step3.title',
          'Knowledge Base Invitations',
        )}
        subtitle={t(
          'page.workspace.createWorkspaceModal.step3.subtitle',
          'Knowz makes collaboration easy- invite coworkers, family, or friends to create and share Knowledge Bases tailored to your needs.',
        )}
      />
      <StyledBoxWrapper>
        <InvitationForm />
      </StyledBoxWrapper>
      <ButtonWrapper>
        <Stack
          direction="row"
          sx={{
            justifyContent: 'flex-end', //TODO: make this 'space-between' after merging remote sources
          }}
        >
          {/* <ButtonBase //TODO: uncomment this after merging remote sources
            disableRipple
            disableTouchRipple
            onClick={handleSkipStep}
          >
            {t('page.workspace.createWorkspaceModal.skipButton', 'Skip step')}
          </ButtonBase> */}
          <Stack
            direction="row"
            gap={3}
          >
            <Button
              size="large"
              onClick={handleGoToPreviousStep}
              variant="outlined"
              color="secondary"
            >
              {t('page.workspace.createWorkspaceModal.backButton', 'Back')}
            </Button>
            <Button
              // onClick={handleNext} //TODO: uncomment this after merging remote sources
              onClick={handleFinishWorkspaceSetup} //TODO: remove this after merging remote sources
              size="large"
              variant="contained"
            >
              {/* {t('page.workspace.createWorkspaceModal.nextButton', 'Next')} //TODO: uncomment this after merging remote sources */}
              {t('layout.toolbar.popover.buttons.done', 'Done')}
            </Button>
          </Stack>
        </Stack>
      </ButtonWrapper>
    </>
  );
}
