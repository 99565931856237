import { TFnType } from '@tolgee/react';
import * as Yup from 'yup';

const MAX_CHARACTERS = 50;

const userFormSchema = (t: TFnType) =>
  Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .required(
        t(
          'layout.main.sections.header.settings.validations.firstName.required',
          'First name is required.',
        ),
      )
      .max(
        MAX_CHARACTERS,
        t(
          'layout.main.sections.header.settings.validations.firstName.long',
          'First name is too long',
        ),
      ),
    lastName: Yup.string()
      .trim()
      .required(
        t(
          'layout.main.sections.header.settings.validations.lastName.required',
          'Last name is required.',
        ),
      )
      .max(
        MAX_CHARACTERS,
        t(
          'layout.main.sections.header.settings.validations.lastName.long',
          'Last name is too long',
        ),
      ),
  });

export default userFormSchema;
