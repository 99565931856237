import { alpha, styled } from '@mui/material';
import Card from '@mui/material/Card';

export const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: theme.spacing(75.5),
  background: `${theme.palette.createBrandGradients.primary(
    theme.palette.mode === 'dark'
      ? {
          alpha1: alpha(theme.palette.common.white, 0),
          alpha2: alpha(theme.palette.common.black, 0.8),
        }
      : {
          alpha1: alpha(theme.palette.common.white, 0.8),
          alpha2: alpha(theme.palette.common.black, 0.1),
        },
  )}!important`,
  marginBlockStart: theme.spacing(2),
  padding: theme.spacing(3),
})) as typeof Card;

export const amir = '';
