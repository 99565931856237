import Stack from '@mui/material/Stack';

import CodeBlock from '@components/code-block';
import Heading from '@components/pages/assistants/sections/drawer/heading';
import Text from '@components/text';

import { useTranslate } from '@tolgee/react';
import { anOldHope } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const CODE = `(function (w, d, s, o, f, js, fjs) {
    w[o] =
      w[o] ||
      function () {
        (w[o].q = w[o].q || []).push(arguments);
      };
    (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
    js.id = o;
    js.src = f;
    js.async = 1;
    fjs.parentNode.insertBefore(js, fjs);
  })(window, document, 'script', 'aw1', './widget.js');
  aw1('init', { minimized: true, disableDarkMode: true });`;

export default function IntegrateAssistantsDrawer() {
  const { t } = useTranslate();

  return (
    <>
      <Heading
        title={t(
          'page.assistants.drawer.integratedAssistants.title',
          'Integrate Assistants',
        )}
        subTitle={t(
          'page.assistants.drawer.integratedAssistants.subtitle',
          'Copy and past this code into your site.',
        )}
      />
      <Stack sx={{ p: 4 }}>
        <Text
          variant="textSm"
          sx={{ marginBlockEnd: 2 }}
        >
          {t(
            'page.assistants.drawer.integratedAssistants.embeddedCode',
            'Embedded code',
          )}
        </Text>
        <CodeBlock
          style={anOldHope}
          code={CODE}
          language="javascript"
        />
      </Stack>
    </>
  );
}
