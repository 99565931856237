import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Stack from '@mui/material/Stack';

import ButtonWrapper from '@components/workspace/setup/components/button-wrapper';
import Header from '@components/workspace/setup/components/header';
import useWorkspaceSetup from '@components/workspace/setup/hooks/useWorkspaceSetup';
import ImagePreview from '@components/workspace/setup/sections/steps/components/image-preview';
import GenerateIconZone from '@components/workspace/setup/sections/steps/upload/generate-icon-zone';
import {
  StyledButtonBase,
  StyledUploadZoneStack,
} from '@components/workspace/setup/sections/steps/upload/styles';
import UploadZone from '@components/workspace/setup/sections/steps/upload/upload-zone';

import { useTranslate } from '@tolgee/react';

export default function Upload() {
  const {
    handleGoToStep,
    handleGoToPreviousStep,
    handleSetUploadedAvatar,
    handleSelectedUploadOption,
  } = useWorkspaceSetup();
  const { t } = useTranslate();

  function handleRedirectToGenerateIconStep() {
    handleSelectedUploadOption('generateIcon');
    handleSetUploadedAvatar(null);
    handleGoToStep('generateIcon');
  }

  function handleBack() {
    handleGoToPreviousStep();
  }

  function handleSkipStep() {
    handleGoToStep('invitation');
  }

  return (
    <>
      <Header
        Icon={<ImagePreview />}
        title={t(
          'page.workspace.createWorkspaceModal.step2.title',
          'Customize your Knowledge Base',
        )}
        subtitle={t(
          'page.workspace.createWorkspaceModal.step2.description',
          'Make your Knowledge Base instantly recognizable with a unique icon - use your logo, branding elements, a photo, or let Knowz create one for you.',
        )}
      />
      <StyledUploadZoneStack>
        <StyledButtonBase
          disableRipple
          disableTouchRipple
        >
          <UploadZone />
        </StyledButtonBase>
        <StyledButtonBase
          onClick={handleRedirectToGenerateIconStep}
          disableRipple
          disableTouchRipple
        >
          <GenerateIconZone />
        </StyledButtonBase>
      </StyledUploadZoneStack>
      <ButtonWrapper>
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <ButtonBase
            disableRipple
            disableTouchRipple
            onClick={handleSkipStep}
          >
            {t('page.workspace.createWorkspaceModal.skipButton', 'Skip step')}
          </ButtonBase>
          <Stack
            direction="row"
            gap={3}
          >
            <Button
              size="large"
              onClick={handleBack}
              variant="outlined"
              color="secondary"
            >
              {t('page.workspace.createWorkspaceModal.backButton', 'Back')}
            </Button>
            <Button
              onClick={handleSkipStep}
              size="large"
              variant="contained"
            >
              {t('page.workspace.createWorkspaceModal.nextButton', 'Next')}
            </Button>
          </Stack>
        </Stack>
      </ButtonWrapper>
    </>
  );
}
