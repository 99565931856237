import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';

import DataLoader from '@components/data-loader';
import ArcProgressWithTooltip from '@components/pages/knowledge-base/components/capacity-indicator/acr-progress-with-tooltip';
import Tooltip from '@components/pages/knowledge-base/components/capacity-indicator/tooltip';
import Text from '@components/text';

import useGetCredit from '@hooks/useGetCredit';
import useResponsive from '@hooks/useResponsive';

import HelpRoundedIcon from '~icons/knowz-iconify/help-rounded';

import { useTranslate } from '@tolgee/react';

export default function CapacityIndicator() {
  const theme = useTheme();
  const { t } = useTranslate();
  const { creditsData, isCreditLoading, isCreditError } = useGetCredit();
  const isItMobileOrTablet = useResponsive('down', 'md');

  return (
    <Stack
      direction="row"
      gap={4}
    >
      <Tooltip>
        <Stack
          direction="row"
          gap={1.5}
          sx={{
            alignItems: 'center',
            display: { xs: 'none', md: 'flex' },
          }}
        >
          <Text
            variant="textSm"
            fontWeight={600}
            sx={{
              color: theme.palette.text.secondary,
            }}
          >
            {t('page.knowledgeBase.table.capacity', 'Knowledge Capacity')}
          </Text>
          <HelpRoundedIcon color={theme.palette.text.secondary} />
        </Stack>
      </Tooltip>
      <DataLoader
        loading={{
          isLoading: isCreditLoading,
          description: 'Loading knowledge capacity ...',
        }}
        error={{
          isError: isCreditError,
          title: t(
            'response.errors.code.520',
            'Unknown error. Please try again later.',
          ),
        }}
      >
        {creditsData && (
          <ArcProgressWithTooltip
            withTooltip={isItMobileOrTablet}
            variant="determinate"
            value={Math.round(creditsData.knowledgeCapacityPercentage)}
          />
        )}
      </DataLoader>
    </Stack>
  );
}
