import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import TabContext from '@mui/lab/TabContext';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import EllipsisText from '@components/ellipsis-typography';
import Details from '@components/pages/knowledge-base/components/table/action-drawer/panels/details';
import LoadingSkeleton from '@components/pages/knowledge-base/components/table/action-drawer/panels/details/loading-skeleton';
import {
  StyledTab,
  StyledTabList,
  StyledTabPanel,
} from '@components/pages/knowledge-base/components/table/action-drawer/styles';
import Wrapper from '@components/pages/knowledge-base/components/table/action-drawer/wrapper';
import useGetSourceDetailsByIdLazily from '@components/pages/knowledge-base/hooks/useGetSourceDetailsByIdLazily';
import Text from '@components/text';

import calculateFullName from '@lib/calculateFullName';

import useQueryParams from '@hooks/useQueryParams';

import paths from '@router/paths';
import { useTranslate } from '@tolgee/react';
import qs from 'qs';

const DRAWER_WIDTH = 400;

type TabValueType = 'details' | 'summary' | 'permission';

type Props = Omit<DrawerProps, 'children' | 'onClose'> & {
  isDrawerOpenById: number | null;
  onClose: VoidFunction;
  shouldShowInFolderButton?: boolean;
  shouldShowOpenFileButton?: boolean;
};

export default function ActionDrawer({
  onClose,
  isDrawerOpenById,
  shouldShowInFolderButton,
  shouldShowOpenFileButton,
  ...otherProps
}: Props) {
  const [value, setValue] = useState<TabValueType>('details');
  const navigateTo = useNavigate();
  const { allQueryParams } = useQueryParams();

  const { sourceDetailsData, isSourceDetailsLoading } =
    useGetSourceDetailsByIdLazily({
      id: isDrawerOpenById,
    });

  const { t } = useTranslate();

  const handleChange = (
    _event: React.SyntheticEvent,
    newValue: TabValueType,
  ) => {
    setValue(newValue);
  };

  const handleShowInFolder = () => {
    if (!sourceDetailsData) return;

    const rootPath = paths.knowledgeBase.pathname;
    const path = qs.stringify({
      ...allQueryParams,
      page: 1, //? reset page to 1 when folder is clicked
      path: sourceDetailsData.folder?.path,
      source: sourceDetailsData.id,
      search: sourceDetailsData.name,
    });
    navigateTo(`${rootPath}?${path}`);
  };

  return (
    <Drawer
      {...otherProps}
      open={Boolean(isDrawerOpenById)}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: 'background.card.light',
          width: {
            xs: '100%',
            sm: DRAWER_WIDTH,
          },
        },
      }}
    >
      <Stack
        direction="row"
        sx={{ p: 4, justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Stack gap={2}>
          {isSourceDetailsLoading ? (
            <Skeleton
              width={200}
              height={45}
            />
          ) : (
            <Text
              variant="textXl"
              fontWeight={600}
            >
              {sourceDetailsData?.name}
            </Text>
          )}
        </Stack>
        <IconButton
          color="tertiarySecondary"
          onClick={() => onClose()}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider />
      <Box sx={{ p: 4 }}>
        <TabContext value={value}>
          <StyledTabList
            variant="fullWidth"
            onChange={handleChange}
            aria-label="action drawer tabs"
            TabIndicatorProps={{
              style: {
                bottom: '50%',
              },
              sx: {
                height: 'calc(100% - 5px)',
                transform: 'translateY(50%)',
                backgroundColor: 'background.card.light',
                borderRadius: 2,
              },
            }}
          >
            <StyledTab
              label={t(
                'page.knowledgeBase.admin.header.actionDrawer.tab1.details',
                'Details',
              )}
              value="details"
            />
            <StyledTab
              label={t(
                'page.knowledgeBase.admin.header.actionDrawer.tab2.summary',
                'Summary',
              )}
              value="summary"
            />
            {/* //TODO: implement permission tab */}
            {/* <StyledTab
              label={t('page.admin.header.actionDrawer.tab3.permission','Permission')}
              value="permission"
            /> */}
          </StyledTabList>
          <StyledTabPanel value="details">
            <Wrapper>
              {isSourceDetailsLoading ? (
                <LoadingSkeleton />
              ) : (
                sourceDetailsData && (
                  <Details
                    name={sourceDetailsData.name}
                    size={sourceDetailsData.size}
                    createdAt={sourceDetailsData.createdAt}
                    createdBy={calculateFullName({
                      firstName: sourceDetailsData.createdBy?.firstName,
                      lastName: sourceDetailsData.createdBy?.lastName,
                    })}
                    format={sourceDetailsData.fileType}
                    location={
                      sourceDetailsData.sourceConfiguration == null
                        ? 'Knowz'
                        : sourceDetailsData.sourceConfiguration.handler
                    }
                    updatedAt={sourceDetailsData.updatedAt}
                  />
                )
              )}
            </Wrapper>
          </StyledTabPanel>
          <StyledTabPanel value="summary">
            <Wrapper>
              <EllipsisText
                variant="textSm"
                lineClamp={27}
              >
                {sourceDetailsData?.summary.length === 0
                  ? t(
                      'page.knowledgeBase.admin.header.actionDrawer.tab2.placeholder',
                      'Waiting for summary',
                    )
                  : sourceDetailsData?.summary}
              </EllipsisText>
            </Wrapper>
          </StyledTabPanel>
          {/* //TODO: implement permission tab */}
          {/* <StyledTabPanel value="permission">
            {t('comingSoon', 'Coming soon')}
          </StyledTabPanel> */}
        </TabContext>
        {shouldShowInFolderButton && (
          <Button
            fullWidth
            sx={{ mt: 2 }}
            variant="contained"
            onClick={handleShowInFolder}
          >
            {t(
              'page.knowledgeBase.admin.header.actionDrawer.button.showInFolder',
              'Show in folder',
            )}
          </Button>
        )}
      </Box>
    </Drawer>
  );
}
