import { UserType } from '@shared-types/auth';
import { LanguageType } from '@shared-types/translation';

export function getOrdinal(n: number, lang: LanguageType = 'en'): string {
  const ordinals: { [key in LanguageType]: string[] } = {
    en: ['th', 'st', 'nd', 'rd'],
    es: ['º', 'º', 'º', 'º'],
    de: ['.', '.', '.', '.'],
    pt: ['º', 'º', 'º', 'º'],
    id: ['.', '.', '.', '.'],
    fr: ['e', 'er', 'e', 'e'],
  };

  let ord = ordinals[lang][0];

  if (n % 10 === 1 && n % 100 !== 11) {
    ord = ordinals[lang][1];
  } else if (n % 10 === 2 && n % 100 !== 12) {
    ord = ordinals[lang][2];
  } else if (n % 10 === 3 && n % 100 !== 13) {
    ord = ordinals[lang][3];
  }

  return ord;
}

export function getInterval(
  interval: NonNullable<UserType['subscription']>['interval'],
  lang: LanguageType = 'en',
): string {
  const intervals: { [key in LanguageType]: string[] } = {
    en: ['month', 'year'],
    es: ['mes', 'año'],
    de: ['Monat', 'Jahr'],
    pt: ['mês', 'ano'],
    id: ['bulan', 'tahun'],
    fr: ['mois', 'an'],
  };

  if (interval === 'year') {
    return intervals[lang][1];
  }

  return intervals[lang][0];
}
