import { memberships } from '@lib/agent';

import { queryClient } from '@providers/ReactQueryProvider';

import useWorkspace from '@hooks/useWorkspace';

import { useMutation } from '@tanstack/react-query';
import { useTranslate } from '@tolgee/react';
import { toast } from 'react-toastify';

export default function useDeleteMembershipById() {
  const { t } = useTranslate();
  const { getNamespacedQueryKey } = useWorkspace();

  const { mutate: mutateMembership } = useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      const { data } = await memberships.deleteOne({
        membershipId: id,
      });
      return data;
    },
    onError: (error) => {
      throw new Error(`An error occurred while deleting a user: ${error}`);
    },
    onSuccess: (_data) => {
      toast.success(
        t(
          'page.users.admin.actions.delete.toast.success',
          'User has been deleted successfully.',
        ),
      );
      queryClient.invalidateQueries({
        queryKey: getNamespacedQueryKey('allMemberships'),
      });
    },
  });

  return {
    mutateMembership,
  };
}
