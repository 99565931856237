import { storage } from '@lib/agent';

import { UploadByLinksRequestType } from '@shared-types/images';

import { useMutation } from '@tanstack/react-query';
import { useTranslate } from '@tolgee/react';
import { toast } from 'react-toastify';

export default function useUploadByLinks() {
  const { t } = useTranslate();
  return useMutation({
    mutationKey: ['uploadByLinks'],
    mutationFn: async (params: UploadByLinksRequestType) => {
      const { data } = await storage.uploadByLinks(params);
      return data;
    },
    onError: (error) => {
      console.error(error);
      toast.error(
        t('response.errors.code.520', 'Unknown error. Please try again later.'),
      );
    },
  });
}
