import { useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import SwitchAccessShortcutAddIcon from '@mui/icons-material/SwitchAccessShortcutAdd';
import { LinearProgress } from '@mui/material';
import Button from '@mui/material/Button';

import ListboxComponent from '@components/autocomplete-with-carousel/listbox';
import Input from '@components/input';
import CommandListItem from '@components/pages/commands/components/CommandListItem';
import { StyledStack } from '@components/pages/commands/styles';
import Text from '@components/text';

import {
  useCreateOneCommand,
  useDeleteOneCommand,
  useGetAllCommands,
  useUpdateOneCommand,
} from '@hooks/useCommands';
import useResponsive from '@hooks/useResponsive';

import { CommandMinType } from '@shared-types/agent';

import paths from '@router/paths';
import { useTranslate } from '@tolgee/react';
import { useConfirm } from 'material-ui-confirm';

const IMAGE_SIZE_WIDTH = 300;
const IMAGE_SIZE_HEIGHT = 200;

export default function Commands() {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const [commandForDeletion, setCommandForDeletion] =
    useState<CommandMinType | null>(null);
  const { mutateAsync: mutateDeleteOneCommandAsync } = useDeleteOneCommand();
  const { mutateAsync: mutateUpdateOneCommandAsync } = useUpdateOneCommand();
  const { mutateAsync: mutateCreateOneCommandAsync } = useCreateOneCommand();
  const { commandsData, isLoadingCommands } = useGetAllCommands();
  const isItMobileOrTablet = useResponsive('down', 'md');
  const data = isLoadingCommands ? [] : commandsData?.data || [];
  const [filterText, setFilterText] = useState('');
  const confirm = useConfirm();

  useEffect(() => {
    const confirmDeletion = async () => {
      if (commandForDeletion) {
        try {
          await confirm({
            title: t(
              'page.commands.deleteConfirmation.title',
              'Delete Command',
            ),
            description: t(
              'page.commands.deleteConfirmation.content',
              'Are you sure you want to delete this command?',
            ),
            confirmationText: t(
              'page.commands.deleteConfirmation.yes',
              'Yes, please',
            ),
            cancellationText: t(
              'page.commands.deleteConfirmation.no',
              "No, I'm good",
            ),
          });
          onRemoveCommandHandler();
        } catch (err) {
          setCommandForDeletion(null);
          console.error(err);
        }
      }
    };

    confirmDeletion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commandForDeletion, confirm, t]);

  async function onNewCommandHandler() {
    const { data } = await mutateCreateOneCommandAsync({
      name: 'My Command',
      icon: '',
      description: 'New Command',
    });

    onOpenCommandHandler(data);
  }

  function onOpenCommandHandler({ id }: CommandMinType) {
    navigate(generatePath(paths.editCommand.pathname, { id }));
  }

  function onToggleCommandHandler({ id, isEnabled }: CommandMinType) {
    mutateUpdateOneCommandAsync({ id, isEnabled: !isEnabled });
  }

  function onRemoveCommandHandler() {
    commandForDeletion && mutateDeleteOneCommandAsync(commandForDeletion.id);
    setCommandForDeletion(null);
  }

  return (
    <>
      <img
        src="https://assets.knowz.com/assets/svg/waves.svg"
        alt="knowz Commands"
        width={IMAGE_SIZE_WIDTH}
        height={IMAGE_SIZE_HEIGHT}
      />

      <Text
        component="h1"
        variant="displaySm"
      >
        {t('page.commands.hero.title', 'Hook AI everywhere')}
      </Text>

      <Button
        startIcon={<SwitchAccessShortcutAddIcon />}
        variant="contained"
        size={isItMobileOrTablet ? 'medium' : 'large'}
        onClick={onNewCommandHandler}
      >
        {t('page.commands.hero.button', 'Create new command')}
      </Button>

      {data.length > 0 ? (
        <StyledStack gap={5}>
          <Input
            placeholder={t('page.commands.hero.search', 'Find Commands')}
            onChange={(event) =>
              setFilterText(event.target.value.toLocaleLowerCase())
            }
          />

          <ListboxComponent>
            {/* TODO check how to handle when data is too big */}
            {data
              ?.filter(
                (item) =>
                  !filterText ||
                  item.name.toLocaleLowerCase().includes(filterText) ||
                  item.description.toLocaleLowerCase().includes(filterText),
              )
              .map((item) => {
                return (
                  <CommandListItem
                    key={item.id}
                    command={item}
                    onOpen={() => onOpenCommandHandler(item)}
                    onToggle={() => onToggleCommandHandler(item)}
                    onRemove={() => setCommandForDeletion(item)}
                  />
                );
              })}
          </ListboxComponent>
        </StyledStack>
      ) : isLoadingCommands || commandsData === undefined ? (
        <LinearProgress
          sx={{
            width: '60%',
          }}
        />
      ) : (
        data.length === 0 && (
          <Text
            component="p"
            variant="textMd"
          >
            {t('page.commands.hero.empty', 'No commands created yet')}
          </Text>
        )
      )}
    </>
  );
}
