import { memberships } from '@lib/agent';

import { useMutation } from '@tanstack/react-query';
import { useTranslate } from '@tolgee/react';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';

type Props = {
  onSuccess:
    | ((
        data: unknown,
        variables: { token: string },
        context: unknown,
      ) => Promise<unknown> | unknown)
    | undefined;
};

export default function useAcceptInvitation({ onSuccess }: Props) {
  const { t } = useTranslate();

  const { mutateAsync: mutateAcceptInvitationAsync } = useMutation({
    mutationKey: ['acceptInvitation'],
    mutationFn: async ({ token }) => {
      return await memberships.acceptInvitation({
        token,
      });
    },
    onSuccess,
    onError: (error) => {
      if (isAxiosError(error) && error.response?.data.code === 403) {
        toast.error(
          t(
            'page.knowledgeBase.member.membership.invalid',
            'You do not have permission to accept this invitation.',
          ),
        );
        return;
      }
      if (isAxiosError(error) && error.response?.data.code === 409) {
        toast.error(
          t(
            'page.knowledgeBase.member.membership.invalid',
            'You are already a member of this workspace.',
          ),
        );
        return;
      }
      if (isAxiosError(error) && error.response?.data.code === 412) {
        toast.error(
          t(
            'page.knowledgeBase.member.membership.alreadyAccepted',
            'You have already accepted this invitation.',
          ),
        );
        return;
      }
      console.error(error);
    },
  });

  return { mutateAcceptInvitationAsync };
}
