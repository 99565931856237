import { alpha, styled, Theme } from '@mui/material';
import Button, { buttonClasses } from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

const SHARED_ACTIVE_STYLES = (theme: Theme) => ({
  outline: '2px solid',
  outlineOffset: 2.5,
  outlineColor: theme.palette['Colors/Effects/Focus rings/focus-ring'],
});

export const StyledButton = styled(Button, {
  shouldForwardProp: (props) => props !== 'isActive',
})<{ isActive: boolean }>(({ theme, isActive }) => ({
  justifyContent: 'left',
  paddingInline: theme.spacing(3),
  alignItems: 'center',
  height: theme.spacing(17),
  marginBlockEnd: theme.spacing(2),
  [`.${buttonClasses.startIcon}`]: {
    display: 'flex',
    marginInlineEnd: theme.spacing(3),
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.spacing(10),
    height: theme.spacing(10),
    background: theme.palette.createBrandGradients.primary(
      theme.palette.mode === 'dark'
        ? {
            alpha1: alpha(theme.palette.common.white, 0.5),
            alpha2: alpha(theme.palette.common.black, 0.1),
          }
        : {
            alpha1: alpha(theme.palette.common.white, 0.1),
            alpha2: alpha(theme.palette.common.black, 0.5),
          },
    ),
    borderRadius: '50%!important',
    color: theme.palette.utility.gray50,
    ...(isActive && SHARED_ACTIVE_STYLES(theme)),
  },
  [`.${buttonClasses.endIcon}`]: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: theme.spacing(10),
    height: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.utility.gray700,
  },
}));

export const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (props) => props !== 'isActive',
})<{ isActive: boolean }>(({ theme, isActive }) => ({
  color: theme.palette.utility.gray50,
  borderRadius: '50%!important',
  marginInlineEnd: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    marginBlockEnd: theme.spacing(3),
    marginInlineEnd: 0,
  },
  background: theme.palette.createBrandGradients.primary(
    theme.palette.mode === 'dark'
      ? {
          alpha1: alpha(theme.palette.common.white, 0.5),
          alpha2: alpha(theme.palette.common.black, 0.1),
        }
      : {
          alpha1: alpha(theme.palette.common.white, 0.1),
          alpha2: alpha(theme.palette.common.black, 0.5),
        },
  ),
  ...(isActive && SHARED_ACTIVE_STYLES(theme)),
}));
