import Stack from '@mui/material/Stack';

import EllipsisText from '@components/ellipsis-typography';
import Text from '@components/text';

import useWorkspace from '@hooks/useWorkspace';

import ChevSelectorVertical from '~icons/knowz-iconify/chev-selector-vertical';
import EditIconRoundedIcon from '~icons/knowz-iconify/edit-rounded';

import useIsKnowledgeBaseUrlActive from '@layouts/main/sections/toolbar/components/workspaces-popover/hooks/useIsKnowledgeBaseUrlActive';
import usePopover from '@layouts/main/sections/toolbar/components/workspaces-popover/hooks/usePopover';
import {
  StyledButton,
  StyledIconButton,
} from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/trigger-button/styles';
import useGetWorkspaces from '@layouts/main/sections/toolbar/hooks/useGetAllWorkspacesById';
import { useTranslate } from '@tolgee/react';

type Props = {
  type: 'iconButton' | 'button';
};

export default function TriggerButton({ type }: Props) {
  const { handleOpen } = usePopover();
  const { refetchWorkspaces } = useGetWorkspaces();
  function handleOpenPopover(event: React.MouseEvent<HTMLButtonElement>) {
    handleOpen(event);
    refetchWorkspaces();
  }
  const { t } = useTranslate();
  const { isActive } = useIsKnowledgeBaseUrlActive();
  const { workspace } = useWorkspace();

  if (type === 'button') {
    return (
      <StyledButton
        disableFocusRipple
        disableRipple
        disableTouchRipple
        disableElevation
        fullWidth
        isActive={isActive}
        size="medium"
        variant="text"
        color="inherit"
        onClick={handleOpenPopover}
        aria-label="trigger knowledge base popover"
        startIcon={<EditIconRoundedIcon />}
        endIcon={<ChevSelectorVertical />}
      >
        <Stack
          sx={{
            textAlign: 'left',
          }}
        >
          <Text
            variant="textMd"
            weight="semibold"
          >
            {t('page.knowledgeBase.admin.header.title', 'Knowledge Base')}
          </Text>
          <EllipsisText
            variant="textSm"
            weight="semibold"
            color="text.secondary"
            lineClamp={1}
            title={'You nameYou nameYou name'}
          >
            {isActive
              ? workspace?.name ||
                t(
                  'layout.toolbar.popover.tabs.ownedKnowledgeBases.title',
                  'Your Knowledge Base',
                )
              : ''}
          </EllipsisText>
        </Stack>
      </StyledButton>
    );
  }

  return (
    <StyledIconButton
      isActive={isActive}
      onClick={handleOpenPopover}
      aria-label="trigger knowledge base popover"
    >
      <EditIconRoundedIcon />
    </StyledIconButton>
  );
}
