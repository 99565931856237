import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';

import CreditUsage from '@layouts/components/credit-usage';
import ToolbarExpansionButton from '@layouts/main/sections/toolbar/components/expand-button';
import ExpandedListItem from '@layouts/main/sections/toolbar/components/expanded-list-item';
import LegalLinks from '@layouts/main/sections/toolbar/components/legal-links';
import {
  StyledExpandedToolbar,
  StyledStack,
} from '@layouts/main/sections/toolbar/expanded/styles';
import useToolbar from '@layouts/main/sections/toolbar/hooks/useToolbar';
import { StyledListStack } from '@layouts/main/sections/toolbar/mini/desktop/styles';
import Socials from '@layouts/main/sections/toolbar/socials';
import { StyledExpandedToolbarGridItem } from '@layouts/main/sections/toolbar/styles';
import { StyledStackWrapper } from '@layouts/styles';

export default function ExpandedToolbar() {
  const {
    toolbarExpansionStatus,
    handleHideExpandedButton,
    handleDisplayExpandedButton,
  } = useToolbar();
  const isToolbarExpanded = toolbarExpansionStatus === 'on';

  return (
    <StyledExpandedToolbarGridItem
      component={Collapse}
      in={isToolbarExpanded}
      orientation="horizontal"
      onMouseEnter={handleDisplayExpandedButton}
      onMouseLeave={handleHideExpandedButton}
    >
      <StyledExpandedToolbar disableGutters>
        <StyledStack>
          <StyledListStack
            disablePadding
            component={Stack}
          >
            <ExpandedListItem />
          </StyledListStack>
          <StyledStackWrapper>
            <Stack gap={4}>
              <Socials />
              <LegalLinks />
            </Stack>
            <CreditUsage variant="gradient" />
          </StyledStackWrapper>
        </StyledStack>
        <ToolbarExpansionButton />
      </StyledExpandedToolbar>
    </StyledExpandedToolbarGridItem>
  );
}
