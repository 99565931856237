import { sources } from '@lib/agent';

import { queryClient } from '@providers/ReactQueryProvider';

import useQueryParams from '@hooks/useQueryParams';
import useWorkspace from '@hooks/useWorkspace';

import { SourceType, SourceWithMetaDataType } from '@shared-types/sources';

import { useMutation } from '@tanstack/react-query';
import { useTranslate } from '@tolgee/react';
import { toast } from 'react-toastify';

type Props = {
  parentFolderId?: number | null;
};

export default function useDeleteSourceById({ parentFolderId }: Props) {
  const { t } = useTranslate();
  const { getNamespacedQueryKey } = useWorkspace();
  const { getQueryParamByKey } = useQueryParams();
  const currentPage = parseInt(getQueryParamByKey('page', '1'));
  const isItRootFolder = parentFolderId === null;
  const queryKey = getNamespacedQueryKey('sources', {
    ...(!isItRootFolder && { folderId: parentFolderId }),
    page: currentPage,
  });

  const { mutate: mutateSource } = useMutation({
    mutationFn: async ({ id }: { id: number }) => {
      const { data } = await sources.deleteOne({
        id,
      });
      return data;
    },
    onMutate: async ({ id }) => {
      await queryClient.cancelQueries({
        queryKey,
      });

      const oldSources =
        queryClient.getQueryData<SourceWithMetaDataType | null>(queryKey);

      if (oldSources == null) {
        console.error('could not find oldSources is null');
        return;
      }

      queryClient.setQueryData(queryKey, (): SourceWithMetaDataType => {
        return {
          meta: oldSources.meta,
          data: oldSources.data.filter(
            (source: SourceType) => source.id !== id,
          ),
        };
      });
    },
    onError: (error) => {
      toast.error(
        t('response.errors.code.520', 'Unknown error. Please try again later.'),
      );
      console.error(`An error occurred while deleting a source: ${error}`);
    },
    onSuccess: (_data) => {
      toast.success(
        t(
          'page.knowledgeBase.modals.admin.actions.delete.source.toast',
          'Source has been deleted successfully.',
        ),
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey,
      });
    },
  });

  return {
    mutateSource,
  };
}
