import { useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';

import {
  StyledMenuItem,
  StyledStack,
  StyledText,
} from '@components/language-selector/styles';
import Text from '@components/text';

import { AVAILABLE_LANGUAGES } from '@providers/TranslationProvider';

import useLocalStorage from '@hooks/useLocalStorage';

import CheckIcon from '~icons/knowz-iconify/check-rounded';
import DeFlagIcon from '~icons/knowz-iconify/de-flag-color-tone';
import EsFlagIcon from '~icons/knowz-iconify/es-flag-color-tone';
import FrFlagIcon from '~icons/knowz-iconify/fr-flag-color-tone';
import McFlagIcon from '~icons/knowz-iconify/mc-flag-color-tone';
import PtFlagIcon from '~icons/knowz-iconify/pt-flag-color-tone';
import UsFlagIcon from '~icons/knowz-iconify/us-flag-color-tone';

import { useTolgee } from '@tolgee/react';

type LanguageCodesType = (typeof AVAILABLE_LANGUAGES)[number];
type LabelsType =
  | 'English'
  | 'Español'
  | 'Deutsch'
  | 'Français'
  | 'Bahasa Indonesia'
  | 'Português';

const LANGUAGES: Record<
  LanguageCodesType,
  { label: LabelsType; flag: React.ReactNode }
> = {
  en: {
    label: 'English',
    flag: <UsFlagIcon />,
  },
  es: {
    label: 'Español',
    flag: <EsFlagIcon />,
  },
  de: {
    label: 'Deutsch',
    flag: <DeFlagIcon />,
  },
  fr: {
    label: 'Français',
    flag: <FrFlagIcon />,
  },
  id: {
    label: 'Bahasa Indonesia',
    flag: <McFlagIcon />,
  },
  pt: {
    label: 'Português',
    flag: <PtFlagIcon />,
  },
} as const;

export default function LanguageSelector() {
  const { value: selectedLang, setValue: setSelectedLang } =
    useLocalStorage<LanguageCodesType>('lang', 'en');
  const { changeLanguage } = useTolgee();
  const theme = useTheme();
  async function handleLangOnchage(event: SelectChangeEvent) {
    changeLanguage(event.target.value as LanguageCodesType);
    setSelectedLang(event.target.value as LanguageCodesType);
  }

  return (
    <Select
      MenuProps={{
        slotProps: {
          paper: {
            sx: {
              p: 0,
            },
          },
        },
      }}
      value={selectedLang}
      onChange={handleLangOnchage}
      variant="outlined"
      renderValue={(langCode: LanguageCodesType) => {
        const { flag, label } = LANGUAGES[langCode];

        return (
          <Stack
            key={langCode}
            direction="row"
            gap={2}
          >
            {flag}
            <Text variant="textSm">{label}</Text>
          </Stack>
        );
      }}
    >
      <StyledText
        variant="textMd"
        color="text.secondary"
        weight="semibold"
      >
        Language
      </StyledText>
      <Divider sx={{ marginBlock: 1 }} />
      {Object.entries(LANGUAGES).map(
        ([key, value]: [
          string,
          { label: LabelsType; flag: React.ReactNode },
        ]) => {
          const langCode = key as LanguageCodesType;
          const { label, flag } = value;
          return (
            <StyledMenuItem
              key={langCode}
              value={langCode}
            >
              <StyledStack
                direction="row"
                gap={2}
              >
                {flag}
                <Text variant="textSm">{label}</Text>
              </StyledStack>
              {selectedLang === langCode && (
                <CheckIcon color={theme.palette.border.brand} />
              )}
            </StyledMenuItem>
          );
        },
      )}
    </Select>
  );
}
