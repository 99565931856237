import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';

import FormProvider from '@components/react-hook-form/FormProvider';
import Text from '@components/text';
import FormHeading from '@components/user-authentication/components/form-heading';
import useResetPassword from '@components/user-authentication/hooks/useResetPassword';
import useUserAuth from '@components/user-authentication/hooks/useUserAuth';
import { resetPasswordSchema } from '@components/user-authentication/schemas';
import PasswordField from '@components/user-authentication/sections/steps/components/password-field';

import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslate } from '@tolgee/react';
import { useForm } from 'react-hook-form';

const defaultValues = {
  password: '',
  confirmPassword: '',
};

type ResetPasswordFormType = {
  password: string;
  confirmPassword: string;
};

export default function ResetPassword() {
  const { t } = useTranslate();
  const { email, handleCustomStep, resetToken } = useUserAuth();
  const {
    mutateAsync: mutateAsyncResetPassword,
    isPending: isResetPending,
    isError: isResetError,
  } = useResetPassword();

  const methods = useForm<ResetPasswordFormType>({
    resolver: yupResolver(resetPasswordSchema(t)),
    defaultValues,
  });

  const {
    formState: { errors, isDirty, isSubmitting },
    handleSubmit,
  } = methods;

  const isBusy = isResetPending || isSubmitting;

  async function onSubmit(data: ResetPasswordFormType) {
    await mutateAsyncResetPassword({
      auth_id: email,
      token: resetToken,
      plain_password: {
        first: data.password,
        second: data.confirmPassword,
      },
    });
    if (isResetError) return;
    handleCustomStep(0);
  }

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormHeading
        heading={t(
          'layout.auth.steps.resetPassword.label',
          'Reset Your Password',
        )}
        withBackButton
      />
      <Stack gap={5}>
        <Text variant="textSm">
          {t(
            'layout.auth.steps.resetPassword.form.password.label',
            'Enter your new password',
          )}
        </Text>
        <PasswordField
          placeholder={t(
            'layout.auth.steps.resetPassword.form.password.placeholder',
            'New Password',
          )}
          name="password"
          disabled={isBusy}
          helperText={errors.password?.message}
        />
        <PasswordField
          placeholder={t(
            'layout.auth.steps.resetPassword.form.confirmPassword.placeholder',
            'Confirm new password',
          )}
          name="confirmPassword"
          disabled={isBusy}
          helperText={errors.confirmPassword?.message}
        />
        <LoadingButton
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          fullWidth
          disabled={!isDirty || isBusy}
          loading={isBusy}
        >
          {t('layout.auth.buttons.resetPass', 'Reset Password')}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
