import { useState } from 'react';

import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Text from '@components/text';

import { ThemeModeType } from '@providers/ThemeSettingsProvider';

import useThemeSettings from '@hooks/useThemeSettings';

import { useTranslate } from '@tolgee/react';

export default function ThemeMode() {
  const { toggleThemeMode, themeMode } = useThemeSettings();
  const [selectedThemeMode, setSelectedThemeMode] = useState(themeMode);

  function handleThemeChange(e: SelectChangeEvent) {
    const selectedTheme = e.target.value as ThemeModeType;
    setSelectedThemeMode(selectedTheme);
    toggleThemeMode(selectedTheme);
  }

  const { t } = useTranslate();

  return (
    <Select
      value={selectedThemeMode}
      onChange={handleThemeChange}
      variant="outlined"
    >
      <MenuItem value="system">
        <Text variant="textSm">
          {t(
            'layout.main.sections.header.settings.general.theme.modes.system',
            'System',
          )}
        </Text>
      </MenuItem>
      <MenuItem value="light">
        <Text variant="textSm">
          {t(
            'layout.main.sections.header.settings.general.theme.modes.light',
            'Light',
          )}
        </Text>
      </MenuItem>
      <MenuItem value="dark">
        <Text variant="textSm">
          {t(
            'layout.main.sections.header.settings.general.theme.modes.dark',
            'Dark',
          )}
        </Text>
      </MenuItem>
    </Select>
  );
}
