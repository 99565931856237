// TODO: Too many ts errors, need to fix
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { styled } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import {
  CodeEditorDialog,
  CodeViewer,
} from '@components/pages/command-designer/dialogs/code-editor';
import {
  SmartPromptEditorDialog,
  SmartPromptViewer,
} from '@components/pages/command-designer/dialogs/smart-prompt-editor';
import {
  Folder,
  Source,
  Template,
} from '@components/pages/command-designer/entity-selectors';
import {
  BagItem,
  InputBlockProps,
  InputBlockWithContext,
} from '@components/pages/command-designer/sections/step-editor/InputBlockWithContext';
import { OptionsSelector } from '@components/pages/command-designer/sections/step-editor/OptionsSelector';
import Textarea from '@components/textarea';

import { useDialogs } from '@toolpad/core';

const propsDefaults = {
  wrapper: 'item',
  types: ['string'],
};

export default function SingleObject(props: InputBlockProps) {
  props = { ...propsDefaults, ...props } as InputBlockProps;
  const { types } = props;

  return types!.length === 1 ? (
    <SingleTypeObject {...props} />
  ) : (
    <MultiTypeObject {...props} />
  );
}

function SingleTypeObject({
  id,
  types,
  options,
  name,
  description,
  value,
  variant,
  defaultValue,
  onChange,
}: InputBlockProps) {
  return (
    <InputBlockWithContext
      name={name}
      description={description}
    >
      <ObjectBlock
        id={id}
        options={options}
        variant={variant}
        bagItem={value || { type: types[0] }}
        defaultValue={defaultValue}
        onChange={onChange}
      />
    </InputBlockWithContext>
  );
}

function MultiTypeObject({
  id,
  types,
  name,
  description,
  value,
  variant,
  onChange,
}: InputBlockProps) {
  function addValue(type: string) {
    onChange({ type, value: null });
  }

  return (
    <InputBlockWithContext
      name={name}
      description={description}
    >
      {value && (
        <ObjectBlock
          id={id}
          variant={variant}
          bagItem={value}
          onChange={onChange}
        />
      )}
      <OptionsSelector
        types={types}
        onClick={addValue}
      />
    </InputBlockWithContext>
  );
}

interface ObjectBlockProps {
  id: string;
  variant?: string;
  bagItem: BagItem;
  options?: Array<string>;
  defaultValue?: string | number | boolean;
}

const StyledTextArea = styled('div')`
  textarea,
  textarea:hover,
  textarea:focus {
    border-color: ${({ theme }) => theme.palette.background.card.main};
  }
`;

function ObjectBlock(props: ObjectBlockProps) {
  const {
    bagItem: { type, data },
    options,
    variant,
    defaultValue,
    ...rest
  } = props;

  const dialogs = useDialogs();

  async function handleSmartPromptEditor() {
    const result = await dialogs.open(SmartPromptEditorDialog, {
      markdown: data || '',
      propertyId: props.id,
    });

    result !== null && rest.onChange({ type, data: result });
  }

  async function handleCodeEditor() {
    const result = await dialogs.open(CodeEditorDialog, {
      code: data,
      propertyId: props.id,
    });

    result !== null && rest.onChange({ type, data: result });
  }

  switch (type) {
    case 'id-source':
      return (
        <Source
          value={(data || null) as number | null}
          onChange={(source) => rest.onChange({ type, data: source?.id })}
        ></Source>
      );

    case 'id-template':
      return (
        <Template
          value={(data || null) as number | null}
          onChange={(template) => rest.onChange({ type, data: template?.id })}
        ></Template>
      );

    case 'id-folder':
      return (
        <Folder
          value={(data || null) as number | null}
          onChange={(folder) => rest.onChange({ type, data: folder?.id })}
        ></Folder>
      );

    case 'id-history-block':
      return (
        <TextField
          fullWidth
          size="small"
          value={data}
          placeholder="Enter history block id"
          onChange={(el) => rest.onChange({ type, data: el.target.value })}
        />
      );

    case 'id-thread':
      return (
        <TextField
          fullWidth
          size="small"
          value={data}
          placeholder="Enter thread id"
          onChange={(el) => rest.onChange({ type, data: el.target.value })}
        />
      );

    case 'string':
      if (options) {
        return (
          <Select
            fullWidth
            value={data}
            onChange={(event) => {
              rest.onChange({ type, data: event.target.value });
            }}
          >
            {options.map((o, i) => (
              <MenuItem
                value={o}
                key={`input-${i}-${o}`}
              >
                {o}
              </MenuItem>
            ))}
          </Select>
        );
      }

      if (variant === 'short') {
        return (
          <TextField
            fullWidth
            size="small"
            value={data}
            onChange={(el) => rest.onChange({ type, data: el.target.value })}
          />
        );
      }

      if (variant === 'code') {
        return (
          <CodeViewer
            onClick={handleCodeEditor}
            code={data as string}
          />
        );
      }

      if (props.id) {
        return (
          <SmartPromptViewer
            onClick={handleSmartPromptEditor}
            markdown={(data || '') as string}
          />
        );
      }

      return (
        <StyledTextArea>
          <Textarea
            minRows={3}
            value={data}
            onChange={(el) => rest.onChange({ type, data: el.target.value })}
          />
        </StyledTextArea>
      );

    case 'boolean':
      return (
        <Select
          fullWidth
          value={data === undefined ? defaultValue : data}
          onChange={(event) => {
            rest.onChange({ type, data: event.target.value });
          }}
        >
          <MenuItem value={true}>True</MenuItem>
          <MenuItem value={false}>False</MenuItem>
        </Select>
      );

    case 'number':
      return (
        <TextField
          type="number"
          fullWidth
          size="small"
          value={data}
          onChange={(el) => rest.onChange({ type, data: el.target.value })}
        />
      );

    default:
      console.error(`Unsupported type: ${type}`);
  }
}
