import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import Text from '@components/text';
import StyledGrid from '@components/workspace/setup/components/header/styles';
import useWorkspaceSetup from '@components/workspace/setup/hooks/useWorkspaceSetup';

import useQueryParams from '@hooks/useQueryParams';

import CrossIcon from '~icons/knowz-iconify/cross';

type Props = {
  title: string;
  subtitle: string;
  Icon: React.ReactNode;
};

export default function Header({ title, subtitle, Icon }: Props) {
  const { removeQueryParamByKey } = useQueryParams();
  const { handleReset } = useWorkspaceSetup();

  function handleCloseWorkspaceModalSetup() {
    removeQueryParamByKey('action');
    handleReset();
  }

  return (
    <StyledGrid
      container
      columnSpacing={4}
      rowSpacing={4}
    >
      <Grid
        order={{
          xs: 1,
          md: 1,
        }}
        size={{
          xs: 'grow',
          md: 'auto',
        }}
      >
        {Icon}
      </Grid>
      <Grid
        order={{
          xs: 3,
          md: 2,
        }}
        size={{ xs: 12, md: 'grow' }}
      >
        <Stack>
          <Text
            variant="textLg"
            weight="semibold"
          >
            {title}
          </Text>
          <Text
            variant="textSm"
            color="text.tertiary"
          >
            {subtitle}
          </Text>
        </Stack>
      </Grid>
      <Grid
        order={{
          xs: 2,
          md: 3,
        }}
        size={{ xs: 'auto', md: 'auto' }}
      >
        <IconButton
          size="small"
          color="tertiarySecondary"
          onClick={handleCloseWorkspaceModalSetup}
        >
          <CrossIcon />
        </IconButton>
      </Grid>
    </StyledGrid>
  );
}
