import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import Text from '@components/text';
import DropZoneWrapper from '@components/workspace/setup/sections/steps/components/drop-zone';
import FeaturedIcon from '@components/workspace/setup/sections/steps/components/featured-icon';
import { StyledStack } from '@components/workspace/setup/sections/steps/upload/styles';

import UploadIcon from '~icons/knowz-iconify/upload';

import { useTranslate } from '@tolgee/react';

export default function UploadZone() {
  const { t } = useTranslate();

  return (
    <DropZoneWrapper>
      {({ getRootProps, getInputProps }) => (
        <StyledStack
          {...getRootProps()}
          gap={3}
        >
          <FeaturedIcon
            component="span"
            size="small"
          >
            <UploadIcon />
          </FeaturedIcon>
          <Stack gap={1.5}>
            <Box>
              <Text
                variant="textXs"
                weight="semibold"
                component="span"
              >
                <input {...getInputProps()} />
                {t(
                  'page.workspace.createWorkspaceModal.step2.subSteps.step1.uploadZone.buttonToUpload.segment1',
                  'Click to upload',
                )}
              </Text>
              &nbsp;
              <Text
                variant="textXs"
                color="text.disabled"
                component="span"
              >
                {t(
                  'page.workspace.createWorkspaceModal.step2.subSteps.step1.uploadZone.buttonToUpload.segment2',
                  'or drag and drop',
                )}
              </Text>
            </Box>
            <Text
              variant="textXs"
              color="text.disabled"
              component="span"
            >
              {t(
                'page.workspace.createWorkspaceModal.step2.subSteps.step1.uploadZone.fileRestriction',
                'JPEG, PNG, WEBP, GIF, or SVG up to 20MB',
              )}
            </Text>
          </Stack>
        </StyledStack>
      )}
    </DropZoneWrapper>
  );
}
