import Input from '@components/input';

import ChevTopIcon from '~icons/knowz-iconify/chev-up';

import StyledAvatar from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/styles';
import { useTranslate } from '@tolgee/react';

export default function LoadingDropdown() {
  const { t } = useTranslate();

  return (
    <Input
      fullWidth
      endAdornment={<ChevTopIcon />}
      startAdornment={<StyledAvatar />}
      readOnly
      disabled
      aria-busy
      aria-label={'loading'}
      value={t('common.loading', 'Loading...')}
    />
  );
}
