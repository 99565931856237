import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Skeleton from '@mui/material/Skeleton';

import SectionHeading from '@components/pages/search/components/SectionHeading';

import { useTranslate } from '@tolgee/react';

const HEIGHT = 120;

type Props = {
  displayLimit: number;
  spaceBetween: number;
  mdSpace: number;
  xsSpace: number;
};

export default function Links({
  displayLimit,
  spaceBetween,
  mdSpace,
  xsSpace,
}: Props) {
  const { t } = useTranslate();

  return (
    <SectionHeading
      title={t('page.search.sections.sources', 'Sources')}
      icon={
        <Icon>
          <FormatListBulletedIcon />
        </Icon>
      }
    >
      <Grid
        container
        spacing={spaceBetween}
      >
        {Array.from({ length: displayLimit }, (_, i) => {
          return (
            <Grid
              item
              key={i}
              xs={xsSpace}
              md={mdSpace}
            >
              <Skeleton
                variant="rounded"
                height={HEIGHT}
                key={i}
              />
            </Grid>
          );
        })}
        <Grid
          item
          xs={xsSpace}
          md={mdSpace}
        >
          <Skeleton
            variant="rounded"
            height={HEIGHT}
          />
        </Grid>
      </Grid>
    </SectionHeading>
  );
}
