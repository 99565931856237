import { useCallback } from 'react';

import useQueryParams from '@hooks/useQueryParams';

export default function useSecureAccount() {
  const { setQueryParam, removeQueryParamByKey } = useQueryParams();

  const openSecureAccount = useCallback(() => {
    setQueryParam('action', 'secure-account');
  }, [setQueryParam]);

  const closeSecureAccount = useCallback(() => {
    removeQueryParamByKey('action');
  }, [removeQueryParamByKey]);

  return {
    openSecureAccount,
    closeSecureAccount,
  };
}
