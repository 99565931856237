import CONFIG from '@components/workspace/setup/config';
import useWorkspaceSetup from '@components/workspace/setup/hooks/useWorkspaceSetup';

import { useTranslate } from '@tolgee/react';
import Dropzone, { DropzoneState, FileRejection } from 'react-dropzone';
import { toast } from 'react-toastify';

type Props = {
  children: (props: DropzoneState) => React.ReactNode;
};

export default function DropZoneWrapper({ children }: Props) {
  const { t } = useTranslate();
  const {
    handleSetUploadedAvatar,
    handleSelectedUploadOption,
    handleGoToStep,
    handleSetSelectedIcon,
  } = useWorkspaceSetup();

  function handleDrop(file: File[]) {
    handleSetUploadedAvatar(file[0]);
  }

  function handleRedirectToCropImageStep() {
    handleSetSelectedIcon(''); //? we make sure user goes to the crop step with no selected icon if they uploaded an image
    handleSelectedUploadOption('upload');
    handleGoToStep('cropper');
  }

  function handleRejectedFile(files: FileRejection[]) {
    //? just show the first image since we only accept one image
    const justFirstFile = files[0];
    for (const file of justFirstFile.errors) {
      if (file.code === 'file-too-large') {
        toast.error(
          t(
            'page.workspace.createWorkspaceModal.step2.subSteps.step1.uploadZone.toast.tooLarge.content',

            'File is too large. Please upload a file up to 20MB',
          ),
        );
        return;
      }

      toast.error(
        t('response.errors.code.520', 'Unknown error. Please try again later.'),
      );
    }
  }

  return (
    <Dropzone
      onDrop={handleDrop}
      accept={CONFIG.FILE_TYPES}
      maxFiles={1}
      maxSize={CONFIG.FILE_SIZE_LIMIT}
      multiple={false}
      onDropAccepted={handleRedirectToCropImageStep}
      onDropRejected={handleRejectedFile}
    >
      {(state) => <>{children(state)}</>}
    </Dropzone>
  );
}
