import * as knowzIcons from '@desygner/knowz-iconify';

const defaultIconName = 'knowz-z';

export default function getRawIconAsBase64(iconName: string): string {
  const {
    icons: { icons },
  } = knowzIcons;

  if (!icons[iconName]) {
    console.warn(`Icon "${iconName}" not found`);
    iconName = defaultIconName;
  }

  const meta = 'data:image/svg+xml;base64,';
  const { body, height, width } = icons[iconName];
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 ${width} ${height}">${body}</svg>`;

  return meta + btoa(svg);
}
