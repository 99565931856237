import useWorkspaceSetup from '@components/workspace/setup/hooks/useWorkspaceSetup';
import IconButton from '@components/workspace/setup/sections/steps/generate-icon/components/icon/button';
import Placeholder from '@components/workspace/setup/sections/steps/generate-icon/components/icon/placeholder';

export default function Generator() {
  const {
    handleSetSelectedIcon,
    iconGenerationStatus,
    generatedIcons,
    selectedIcon,
  } = useWorkspaceSetup();

  if (!generatedIcons) return;

  return (
    <Placeholder status={iconGenerationStatus}>
      {generatedIcons.map((icon, index) => (
        <IconButton
          key={index}
          icon={icon}
          handleSetSelectedIcon={handleSetSelectedIcon}
          selectedIcon={selectedIcon}
        />
      ))}
    </Placeholder>
  );
}
