import { TFnType } from '@tolgee/react';
import * as Yup from 'yup';

const createWorkspaceSchema = (t: TFnType) =>
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .max(
        60,
        t(
          'page.workspace.validations.name.max',
          'Name must be less than 60 characters',
        ),
      )
      .required(
        t('page.workspace.validations.name.required', "Name can't be empty"),
      ),
  });

export default createWorkspaceSchema;
