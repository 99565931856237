import { useMemo } from 'react';

import { SxProps } from '@mui/material';

import { StyledButtonBase, StyledMarkdown } from '@components/markdown/styles';
import useSourcePreview from '@components/source-preview/hooks/useSourcePreview';

import useAppSelector from '@hooks/useAppSelector';

import { SourceLinksType } from '@shared-types/search-result/streaming';

import { useTranslate } from '@tolgee/react';
import { Options as MarkdownOptions } from 'react-markdown';

type Props = React.PropsWithChildren<MarkdownOptions & { sx?: SxProps }> & {
  links?: Array<SourceLinksType>;
};

export default function Markdown({ links, children, sx, ...rest }: Props) {
  const { t } = useTranslate();

  const {
    handleSourcePreviewCardClick,
    sourcePreviewCard,
    closeSourcePreviewCard,
    isLoading,
  } = useSourcePreview();
  const streamingLinks = useAppSelector(
    (state) => state.searchResult.knowledgeLinks,
  );
  const filteredLinks = useMemo(
    () =>
      streamingLinks?.filter((link) => link?.kind !== 'customsearch#result'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  ) as Array<SourceLinksType>;

  const linksRef = filteredLinks ?? links;

  return (
    <StyledMarkdown
      sx={sx}
      {...rest}
      components={{
        a(props) {
          const { children, node, href, ...rest } = props;
          const sourceWithSubId = href?.split('id=')[1];
          if (sourceWithSubId) {
            const sourceId = sourceWithSubId.split('-')[0];

            return (
              <StyledButtonBase
                disableRipple
                disableTouchRipple
                onClick={() => {
                  if (sourcePreviewCard.isOpen) {
                    closeSourcePreviewCard();
                    return;
                  }
                  handleSourcePreviewCardClick({
                    sourceId: sourceId,
                    sourceData: linksRef,
                    sourceWithSubId: sourceWithSubId,
                  });
                }}
              >
                {t('page.search.sections.knowledgeSource', 'Knowledge Base')}
              </StyledButtonBase>
            );
          }
          return (
            <a
              href={href}
              {...rest}
              target="_blank"
              rel="noopener noreferrer"
            >
              {children}
            </a>
          );
        },
      }}
    >
      {children?.toString()}
    </StyledMarkdown>
  );
}
