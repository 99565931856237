import Text from '@components/text';

import StyledBox from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/header/styles';
import { useTranslate } from '@tolgee/react';

export default function Header() {
  const { t } = useTranslate();

  return (
    <StyledBox>
      <Text
        variant="textMd"
        weight="semibold"
      >
        {t(
          'layout.toolbar.popover.title',
          'Select the Knowledge Base you want to use',
        )}
      </Text>
      <Text variant="textSm">
        {t(
          'layout.toolbar.popover.subtitle',
          'This will effect the entire platforms Knowledge, including Search and Live, switch at anytime here.',
        )}
      </Text>
    </StyledBox>
  );
}
