import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import Text from '@components/text';

import ChevronRightIcon from '~icons/knowz-iconify/chev-right';

import StyledStack from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/sections/components/pack-plan-cta-tooltip/styles';
import paths from '@router/paths';
import { useTranslate } from '@tolgee/react';

export default function PackPlanCallToActionTooltip() {
  const { t } = useTranslate();

  return (
    <Stack gap={2.5}>
      <Text
        variant="textSm"
        weight="semibold"
      >
        {t(
          'layout.toolbar.popover.cta.tooltip.pack.title',
          'Need More Knowledge Bases?',
        )}
      </Text>
      <Stack>
        <Text
          variant="textSm"
          weight="semibold"
        >
          {t(
            'layout.toolbar.popover.cta.tooltip.pack.content.segment1',
            'Your account can create 3 Knowledge Bases',
          )}
        </Text>
        <Text variant="textSm">
          {t(
            'layout.toolbar.popover.cta.tooltip.pack.content.segment2',
            'Upgrade to the Teams plan to create up to 5 Knowledge Bases and invite 100 users each.',
          )}
        </Text>
      </Stack>
      <StyledStack>
        <Text
          variant="textSm"
          weight="semibold"
        >
          {t(
            'layout.toolbar.popover.cta.tooltip.pack.footer.enterprise',
            'Enterprise?',
          )}
        </Text>
        <Button
          component={Link}
          to={paths.upgradeAccount.pathname}
          endIcon={<ChevronRightIcon />}
        >
          {t(
            'layout.toolbar.popover.cta.tooltip.pack.footer.button',
            'Upgrade Plan',
          )}
        </Button>
      </StyledStack>
    </Stack>
  );
}
