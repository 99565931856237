import { useMemo } from 'react';

import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import EllipsisText from '@components/ellipsis-typography';
import StyledStack from '@components/pages/knowledge-base/components/header/styles';
import CreateFolderModal from '@components/pages/knowledge-base/components/modals/create-folder';
import KNOWLEDGE_BASE_CONFIG from '@components/pages/knowledge-base/config';
import useGetFoldersDataByIdLazily from '@components/pages/knowledge-base/hooks/useGetFoldersDataById';
import useGetSourcesDataByFolderId from '@components/pages/knowledge-base/hooks/useGetSourcesDataByFolderId';
import useKnowledgeBaseData from '@components/pages/knowledge-base/hooks/useKnowledgeBaseData';
import Text from '@components/text';

import useGetFolderDataByPath from '@hooks/useGetFolderDataByPath';
import useQueryParams from '@hooks/useQueryParams';
import useWorkspace from '@hooks/useWorkspace';

import UploadIcon from '~icons/knowz-iconify/upload';

import { useTranslate } from '@tolgee/react';

export default function Header() {
  const { getQueryParamByKey } = useQueryParams();

  const { handleOpenAddKnowledgeBaseModal, toggleCreateFolderModal } =
    useKnowledgeBaseData();

  const { setupMode } = useWorkspace();
  const currentPath = getQueryParamByKey<string | null>('path');
  const { folderData, isFolderDataLoading, isFolderDataPending } =
    useGetFolderDataByPath({
      path: currentPath,
    });

  const { foldersData, isFoldersDataLoading } = useGetFoldersDataByIdLazily({
    folderId: currentPath === null ? null : folderData?.id,
  });

  const { sourcesData, isSourcesDataLoading } = useGetSourcesDataByFolderId({
    folderId: folderData?.id,
    limit: KNOWLEDGE_BASE_CONFIG.PAGINATION.LIMIT,
    page: parseInt(getQueryParamByKey('page', '1')),
  });

  const resources = useMemo(
    () => [...(foldersData || []), ...(sourcesData?.data || [])],
    [foldersData, sourcesData],
  );

  const areResourcesLoading =
    isFolderDataLoading || isFoldersDataLoading || isSourcesDataLoading;

  const areResourcesEmpty = resources.length === 0;

  const { t } = useTranslate();

  const isItRootFolder = folderData === null;

  const canCreateFolders = setupMode === 'on' || setupMode == null;

  return (
    <StyledStack>
      <CreateFolderModal />
      <Stack gap={2}>
        {isFolderDataPending ? (
          <Skeleton
            width={150}
            height={50}
          />
        ) : isItRootFolder ? (
          <Text variant="textXl">
            {t('page.knowledgeBase.admin.header.title', 'Knowledge Base')}
          </Text>
        ) : (
          <EllipsisText
            title={folderData?.name}
            lineClamp={1}
            variant="textXl"
          >
            {folderData && folderData.name}
          </EllipsisText>
        )}
      </Stack>
      <Stack
        direction="row"
        gap={3}
      >
        {canCreateFolders && (
          <Button
            variant="outlined"
            startIcon={<CreateNewFolderOutlinedIcon />}
            disabled={areResourcesLoading}
            onClick={toggleCreateFolderModal}
          >
            {t(
              'page.knowledgeBase.admin.header.buttons.secondary.label',
              'Create a folder',
            )}
          </Button>
        )}
        {!areResourcesLoading && !areResourcesEmpty && (
          <Button
            variant="contained"
            startIcon={<UploadIcon />}
            onClick={handleOpenAddKnowledgeBaseModal}
          >
            {t('page.knowledgeBase.admin.header.buttons.primary.label', 'Add')}
          </Button>
        )}
      </Stack>
    </StyledStack>
  );
}
