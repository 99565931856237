import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';

import useUpdateSpeakerName from '@components/pages/live-session/hooks/useUpdateSpeakerName';
import GuestSpeakerField from '@components/pages/live-session/sections/content/components/transcript/card/dropdown/voice-match/modal/match/forms/guest-speaker';
import verifyName from '@components/pages/live-session/sections/content/components/transcript/card/dropdown/voice-match/modal/match/schemas';
import {
  StyledBox,
  StyledStack,
} from '@components/pages/live-session/sections/content/components/transcript/card/dropdown/voice-match/modal/match/styles';
import StyledStackButton from '@components/pages/live-session/sections/content/components/transcript/card/dropdown/voice-match/modal/styles';
import FormProvider from '@components/react-hook-form/FormProvider';
import Text from '@components/text';

import useAppDispatch from '@hooks/useAppDispatch';
import useQueryParams from '@hooks/useQueryParams';

import { setVoiceMatch } from '@state/slices/live-session';

import CrossIcon from '~icons/knowz-iconify/cross';
import WaveRoundedIcon from '~icons/knowz-iconify/wave-rounded';

import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslate } from '@tolgee/react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

type Props = {
  handleCloseModal: VoidFunction;
  speakerRef: string;
};

export default function Match({ handleCloseModal, speakerRef }: Props) {
  const { t } = useTranslate();
  const methods = useForm({
    resolver: yupResolver(verifyName(t)),
    defaultValues: {
      name: '',
    },
  });
  const { getQueryParamByKey } = useQueryParams();
  const {
    mutateAsync: mutateSpeakerNameAsync,
    isPending: isMutateSpeakerNamePending,
  } = useUpdateSpeakerName();
  const dispatch = useAppDispatch();

  const threadId = getQueryParamByKey<string | null>('t');

  const {
    formState: { isDirty },
    reset,
    handleSubmit,
  } = methods;

  async function onSubmit(data: { name: string }) {
    if (threadId === null) return;
    await mutateSpeakerNameAsync({
      name: data.name,
      threadId,
      speaker: speakerRef,
    });
    dispatch(
      setVoiceMatch({
        [speakerRef]: {
          name: data.name,
        },
      }),
    );
    handleCloseModal();
    toast.success("Speaker's name has been updated successfully"); //TODO: migrate to new toast with actions preferably we should use source viewer toast component
    reset();
  }

  return (
    <StyledBox>
      <FormProvider
        methods={methods}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack gap={1}>
          <StyledStack direction="row">
            <Text
              variant="textLg"
              weight="semibold"
            >
              {t(
                'page.liveSession.transcriptSetting.voiceMatch.title',
                'Voice Match Speaker',
              )}
            </Text>
            <IconButton
              color="tertiarySecondary"
              onClick={handleCloseModal}
            >
              <CrossIcon />
            </IconButton>
          </StyledStack>
          <Text variant="textSm">
            {t(
              'page.liveSession.transcriptSetting.voiceMatch.subtitle.segment1',
              'Match the voice of the speaker to a member of your workspace or to a guest speaker.',
            )}
            &nbsp;
            <Text
              variant="textSm"
              weight="bold"
              component="b"
            >
              {t(
                'page.liveSession.transcriptSetting.voiceMatch.subtitle.segment1',

                'Match the voice of the speaker to a member of your workspace or to a guest speaker.',
              )}
            </Text>
          </Text>
        </Stack>
        <br />
        <Stack>
          <Stack gap={3}>
            <RadioGroup defaultValue="guestSpeaker">
              <GuestSpeakerField />
            </RadioGroup>
          </Stack>
        </Stack>
        <StyledStackButton
          direction="row"
          gap={2}
        >
          <Button
            onClick={handleCloseModal}
            fullWidth
            size="medium"
            variant="outlined"
            color="inherit"
          >
            {t(
              'page.liveSession.transcriptSetting.voiceMatch.buttons.cancel',
              'Cancel',
            )}
          </Button>
          <LoadingButton
            onClick={handleSubmit(onSubmit)}
            disabled={!isDirty || isMutateSpeakerNamePending}
            loading={isMutateSpeakerNamePending}
            fullWidth
            size="medium"
            variant="contained"
            color="primary"
            startIcon={<WaveRoundedIcon />}
          >
            {t(
              'page.liveSession.transcriptSetting.voiceMatch.buttons.confirm',
              'Confirm',
            )}
          </LoadingButton>
        </StyledStackButton>
      </FormProvider>
    </StyledBox>
  );
}
