import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

import ErrorTemplate from '@components/error-template';

import { useTranslate } from '@tolgee/react';

const WIDTH = 504;
const HEIGHT = 264;

export default function Error() {
  const error = useRouteError();

  const { t } = useTranslate();

  console.error(error);

  if (isRouteErrorResponse(error) && error.status === 404) {
    return (
      <ErrorTemplate
        title={t('response.errors.pages.notFound.title', 'Page not found')}
        subtitle={t(
          'response.errors.pages.notFound.subtitle',
          'The page you are looking for does not exist.',
        )}
        img={
          <img
            height={HEIGHT}
            width={WIDTH}
            src={'https://assets.knowz.com/assets/svg/404.svg'}
            alt="page not found"
          />
        }
      />
    );
  }

  if (isRouteErrorResponse(error) && error.status === 401) {
    return (
      <ErrorTemplate
        title={t(
          'response.errors.pages.unauthorized.title',
          'Access Denied. You are not authorized.',
        )}
        subtitle={t(
          'response.errors.pages.unauthorized.subtitle',
          'Looks like you do not have permission to access this page.',
        )}
        img={
          <img
            height={HEIGHT}
            width={WIDTH}
            src={'https://assets.knowz.com/assets/svg/401.svg'}
            alt="unauthorized"
          />
        }
      />
    );
  }

  return (
    <ErrorTemplate
      title={t(
        'response.errors.pages.serverError.title',
        'Uh oh!  There is a glitch in the matrix.',
      )}
      subtitle={t(
        'response.errors.pages.serverError.subtitle',
        'We are working to fix it. Check back soon for everything to be back to normal.',
      )}
      img={
        <img
          height={HEIGHT}
          width={WIDTH}
          src={'https://assets.knowz.com/assets/svg/500.svg'}
          alt="server side error"
        />
      }
    />
  );
}
