import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@mui/material';

import useSecureAccount from '@hooks/useOpenLogin';

import StarIcon from '~icons/knowz-iconify/star';

import { UserFunnelType } from '@layouts/components/credit-usage/hooks/useUserFunnelType';
import Card from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/sections/components/cta-card/card';
import paths from '@router/paths';
import { useTranslate } from '@tolgee/react';

type Props = {
  userAccountType: UserFunnelType;
};

export default function CallToActionCard({ userAccountType }: Props) {
  const { t } = useTranslate();
  const { openSecureAccount } = useSecureAccount();

  const CARDS = useMemo(
    (): Record<UserFunnelType, React.ReactNode> => ({
      visitor: (
        <Card
          title={t(
            'layout.toolbar.popover.cta.card.visitor.title',
            'Need More Knowledge Bases?',
          )}
          description={t(
            'layout.toolbar.popover.cta.card.visitor.description',
            'Login or sign up now and pick a plan to create up to 5 Knowledge Bases and invite up to 100 users to each one!',
          )}
          actionButtons={{
            secondary: (
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<StarIcon />}
                onClick={openSecureAccount}
                fullWidth
              >
                {t(
                  'layout.toolbar.toolbar.popover.card.visitor.buttons.getStarted',
                  'Get Started Now!',
                )}
              </Button>
            ),
          }}
        />
      ),
      free: (
        <Card
          title={t(
            'layout.toolbar.popover.cta.card.free.title',
            'Need More Knowledge Bases?',
          )}
          description={t(
            'layout.toolbar.popover.cta.card.free.description',
            'Grab some credits or pick a plan to create up to 5 Knowledge Bases and invite up to 100 users into each one!',
          )}
          actionButtons={{
            primary: (
              <Button
                variant="contained"
                fullWidth
                component={Link}
                to={paths.upgradeAccount.pathname}
              >
                {t(
                  'layout.toolbar.toolbar.popover.card.free.buttons.pickPlan',
                  'Pick a Plan',
                )}
              </Button>
            ),
            secondary: (
              <Button
                fullWidth
                startIcon={<StarIcon />}
                component={Link}
                to={paths.upgradeAccount.pathname}
                // onClick={}
                variant="outlined"
                color="secondary"
              >
                {t(
                  'layout.toolbar.toolbar.popover.card.free.buttons.upgradeForMore',
                  'Upgrade for more',
                )}
              </Button>
            ),
          }}
        />
      ),
      pack: <></>,
      subscribed: <></>,
    }),
    [openSecureAccount, t],
  );

  return CARDS[userAccountType];
}
