import { useNavigate } from 'react-router-dom';

import ButtonBase from '@mui/material/ButtonBase';
import Stack from '@mui/material/Stack';

import { MOBILE_HEIGHT } from '@components/pages/live/sections/recorder';
import {
  StyledIcon,
  StyledMobileCard,
} from '@components/pages/live/sections/recorder/styles';
import { RoomAudioWave } from '@components/pages/live-session/recorder/audio-wave';
import Timer from '@components/pages/live-session/recorder/LiveTimer';
import Text from '@components/text';

import paths from '@router/paths';
import { useTranslate } from '@tolgee/react';

export default function Recorder() {
  const { t } = useTranslate();

  const isRecording = true; // TODO: Implement this

  const navigateTo = useNavigate();

  function handleStopRecording() {
    navigateTo(paths.live.pathname);
  }

  return (
    <StyledMobileCard
      component={Stack}
      gap={4}
      sx={{ height: MOBILE_HEIGHT }}
      justifyContent="space-between"
      className="mobile"
    >
      <Stack
        direction="row"
        gap={2.5}
        sx={{ justifyContent: 'space-between' }}
      >
        <Stack
          sx={{ flex: '1 1 0' }}
          alignItems="flex-start"
        >
          <Text
            variant="textSm"
            weight="semibold"
          >
            {isRecording
              ? t('page.liveSession.recorder.recording', 'Recording')
              : 'Start new recording'}
          </Text>

          {isRecording && <Timer />}
        </Stack>

        <ButtonBase
          component={Stack}
          onClick={handleStopRecording}
          disableRipple
          disableTouchRipple
          direction="row"
          gap={2.5}
          alignItems="center"
          sx={{ flex: '1 1 0' }}
        >
          <StyledIcon className={isRecording ? 'playing' : undefined} />
        </ButtonBase>

        <Stack
          alignItems="flex-end"
          sx={{ flex: '1 1 0' }}
        >
          {/* TODO: mic selection */}
        </Stack>
      </Stack>

      <RoomAudioWave />
    </StyledMobileCard>
  );
}
