import { Close } from '@mui/icons-material'; //Todo replace with knowz-icon
import { SxProps } from '@mui/material';
import Stack from '@mui/material/Stack';

import FormProvider from '@components/react-hook-form/FormProvider';
import RHFSearchModeSelect from '@components/search-form/react-hook-form-search-mode-select';
import RHFUploadSelect from '@components/search-form/react-hook-form-upload-select';
import searchFormSchema from '@components/search-form/schema';
import ScopeSelector from '@components/search-form/scope-selector';
import {
  STAR_SIZE,
  StyledIconButton,
  StyledImage,
  StyledImageBox,
  StyledPreviewContainer,
  StyledRHFInputField,
  StyledSearchButton,
  StyledSearchFormWrapper,
  StyledStack,
  StyledStarBox,
  StyledStarStack,
} from '@components/search-form/styles';

import { storage } from '@lib/agent';

import useAppDispatch from '@hooks/useAppDispatch';
import useAppSelector from '@hooks/useAppSelector';
import useGetCredit from '@hooks/useGetCredit';

import { imageUploaded } from '@state/slices/search-result';

import { AskAISearchType } from '@shared-types/search-result/types';

import SendIcon from '~icons/knowz-iconify/send';
import StarIcon from '~icons/knowz-iconify/star';

import { yupResolver } from '@hookform/resolvers/yup';
import CreditUsage from '@layouts/components/credit-usage';
import CircularProgress from '@layouts/main/sections/header/sections/user-account-popover/credit-circular-progress-with-avatar/circular-progress';
import PopoverProvider from '@layouts/main/sections/toolbar/components/workspaces-popover/providers/PopoverProvider';
import { useTranslate } from '@tolgee/react';
import { SubmitHandler, useForm } from 'react-hook-form';

type SearchFormValuesType = {
  name: string;
  mode: AskAISearchType['mode'];
  image?: string;
};

type SearchFormType = {
  onSubmit: SubmitHandler<SearchFormValuesType>;
  sx?: SxProps;
};

export default function SearchForm({ onSubmit, sx }: SearchFormType) {
  const { t } = useTranslate();

  const { creditsData } = useGetCredit();

  const mode = useAppSelector((state) => state.searchResult.mode);

  const uploadedImages = useAppSelector((state) => state.searchResult.image);

  const dispatch = useAppDispatch();

  const defaultValues: SearchFormValuesType = {
    name: '',
    mode,
    image: undefined,
  };

  const methods = useForm({
    resolver: yupResolver(searchFormSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isDirty, isSubmitting },
    reset,
  } = methods;

  async function handleRemoveImage(name: string) {
    if (!uploadedImages) return;
    const { previews } = uploadedImages;
    const updatedUploadImages = previews.filter((image) => image.name !== name);

    const imageToRemove = previews.find((image) => image.name === name);

    dispatch(
      imageUploaded({
        threadId: uploadedImages.threadId,
        previews: updatedUploadImages,
        currentImageKey: null,
      }),
    );

    if (!imageToRemove) return;

    await storage.deleteFile({ key: imageToRemove.key }); //TODO: we need to make it a useMutation hook so we can use it everywhere, errors are not handled as well,
  }

  return (
    <StyledSearchFormWrapper sx={sx}>
      <PopoverProvider>
        <FormProvider
          methods={methods}
          onSubmit={handleSubmit((args) => {
            onSubmit(args);
            reset();
          })}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            sx={{ mb: '12px' }}
          >
            <StyledStack
              direction="row"
              gap={1}
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                position: 'relative',
              }}
            >
              <Stack
                direction="row"
                gap={1}
                sx={{
                  alignItems: 'center',
                }}
              >
                <CreditUsage
                  variant="tooltip"
                  tooltipPlacement="top"
                >
                  <StyledStarStack sx={{ mr: 2 }}>
                    <StyledStarBox>
                      <CircularProgress
                        size={STAR_SIZE}
                        value={creditsData?.creditAsPercentage}
                      />
                    </StyledStarBox>
                    <StarIcon fontSize="1rem" />
                  </StyledStarStack>
                </CreditUsage>

                <RHFUploadSelect />

                <ScopeSelector />
              </Stack>

              <RHFSearchModeSelect name="mode" />
            </StyledStack>
          </Stack>

          <StyledRHFInputField
            role="search"
            type="text"
            name="name"
            disabled={isSubmitting}
            autoComplete="off"
            helperText={undefined} //? we don't want to show the error message
            placeholder={t(
              'page.home.hero.search.placeholder',
              'Ask anything...',
            )}
            endAdornment={
              <StyledSearchButton
                variant="outlined"
                color="inherit"
                onClick={handleSubmit(onSubmit)}
                disabled={!isDirty || isSubmitting}
                disableRipple
                disableTouchRipple
                disableFocusRipple
              >
                <SendIcon
                  style={{
                    transform: 'rotate(-45deg)',
                  }}
                />
              </StyledSearchButton>
            }
          />
          {uploadedImages && uploadedImages.previews.length > 0 && (
            <StyledPreviewContainer>
              {uploadedImages.previews.map((image) => (
                <StyledImageBox key={image.name}>
                  <StyledImage
                    src={image.preview}
                    alt={image.name}
                  />
                  <StyledIconButton
                    size="small"
                    onClick={() => handleRemoveImage(image.name)}
                  >
                    <Close fontSize="small" />
                  </StyledIconButton>
                </StyledImageBox>
              ))}
            </StyledPreviewContainer>
          )}
        </FormProvider>
      </PopoverProvider>
    </StyledSearchFormWrapper>
  );
}
