import { IconButtonProps } from '@mui/material/IconButton';

import StyledIconButton from '@components/workspace/setup/sections/steps/components/featured-icon/styles';

type Props = React.PropsWithChildren<{
  size?: IconButtonProps['size'];
  component?: IconButtonProps['component'];
}>;

export default function FeaturedIcon({
  children,
  size = 'medium',
  component = 'button',
}: Props) {
  return (
    <StyledIconButton
      component={component}
      role="img"
      size={size}
      color="outlinedSecondary"
    >
      {children}
    </StyledIconButton>
  );
}
