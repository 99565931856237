import { useTheme } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';

import Text from '@components/text';
import DropZoneWrapper from '@components/workspace/setup/sections/steps/components/drop-zone';

import UploadIcon from '~icons/knowz-iconify/upload';

import { useTranslate } from '@tolgee/react';

export default function Upload() {
  const theme = useTheme();
  const { t } = useTranslate();

  return (
    <DropZoneWrapper>
      {({ getRootProps, getInputProps }) => (
        <ButtonBase
          {...getRootProps()}
          disableRipple
          disableTouchRipple
        >
          <input
            type="hidden"
            {...getInputProps()}
          />
          <UploadIcon
            fontSize="1.2em"
            color={theme.palette.border.brandAlt}
          />
          &nbsp;
          <Text
            variant="textSm"
            weight="semibold"
            color={
              theme.palette[
                'Component colors/Components/Buttons/Tertiary color/button-tertiary-color-fg'
              ]
            }
          >
            {t(
              'page.workspace.createWorkspaceModal.step2.subSteps.step2.buttons.upload',
              'Upload an icon instead',
            )}
          </Text>
        </ButtonBase>
      )}
    </DropZoneWrapper>
  );
}
