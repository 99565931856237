import { useState } from 'react';

import LinkIcon from '@mui/icons-material/Link';
import SearchIcon from '@mui/icons-material/Search';
import Card from '@mui/material/Card';
import Grid2 from '@mui/material/Grid2';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';

import EllipsisText from '@components/ellipsis-typography';
import usePromptHistoryState from '@components/pages/hooks/usePromptHistoryState';
import SectionHeading from '@components/pages/search/components/SectionHeading';
import StyledListItem from '@components/pages/search/components/shared-blocks/followups/styles';

import useAppSettings from '@hooks/useAppSettings';
import useGetThreadIdFromQueryParam from '@hooks/useGetThreadFromQueryParam';

import { RootType } from '@state/store';

import { useTranslate } from '@tolgee/react';
import { useDispatch, useSelector } from 'react-redux';

type Props = {
  followups: Array<string> | [];
  disabled?: boolean;
};

export default function Followups({ followups, disabled = false }: Props) {
  const { t } = useTranslate();

  const { setNewHistoryStatePrompt } = usePromptHistoryState();

  const searchMode = useSelector((state: RootType) => state.searchResult.mode);

  const { threadId } = useGetThreadIdFromQueryParam();

  const [destroy, setDestroy] = useState(false);

  const { userGeolocation } = useAppSettings();

  const dispatch = useDispatch();

  if (followups.length === 0 || destroy === true) return null;

  function handleFollowup(followup: string) {
    dispatch({
      type: 'searchResult/askAIEmitted',
      payload: {
        thread: threadId,
        prompt: followup,
        mode: searchMode,
        context: {
          latLng: [userGeolocation.lat, userGeolocation.lng],
        },
      },
    });
    setNewHistoryStatePrompt(followup);
    setDestroy(true);
  }

  return (
    <Card>
      <Grid2 size={{ xs: 12 }}>
        <SectionHeading
          icon={<LinkIcon fontSize="small" />}
          title={t(
            'page.search.sections.relatedQuestions',
            'Related Questions',
          )}
        >
          <Stack
            component={List}
            gap={2}
          >
            {followups.map((singleFollowup, i: number) => (
              <StyledListItem
                key={i}
                disableGutters
                disablePadding
              >
                <ListItemButton
                  disabled={disabled}
                  onClick={() => handleFollowup(singleFollowup)}
                  sx={{
                    color: 'text.secondary',
                    height: 80,
                    p: 2,
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  <ListItemIcon>
                    <SearchIcon />
                  </ListItemIcon>
                  <ListItemText>
                    <EllipsisText
                      lineClamp={2}
                      variant="textSm"
                    >
                      {singleFollowup}
                    </EllipsisText>
                  </ListItemText>
                </ListItemButton>
              </StyledListItem>
            ))}
          </Stack>
        </SectionHeading>
      </Grid2>
    </Card>
  );
}
