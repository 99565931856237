import { auth } from '@lib/agent';

import { VerifyEmailBodyType } from '@shared-types/auth';

import { useMutation } from '@tanstack/react-query';
import { useTranslate } from '@tolgee/react';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';

const verifyEmail = async (data: VerifyEmailBodyType) =>
  await auth.verifyEmail(data);

export default function useVerifyEmail() {
  const { t } = useTranslate();
  return useMutation({
    mutationFn: verifyEmail,
    onError: async (error) => {
      if (isAxiosError(error) && error.response?.status === 429) {
        toast.error(
          t(
            'response.errors.code.429',
            'Too many requests. Please try again later.',
          ),
        );
        return;
      }
      if (isAxiosError(error) && error.response?.status === 400) {
        toast.error(
          t(
            'response.errors.wrongCodeVerification',
            'Invalid code. Please try again.',
          ),
        );
        return;
      }
      toast.error(
        t(
          'response.errors.code.520',
          'Something went wrong. Please try again later.',
        ),
      );
      console.error(error);
    },
  });
}
