import React from 'react';

import { StyledButton } from '@components/pages/command-designer/sections/toolbox/styles';

import PlusSquare from '~icons/knowz-iconify/plus-square';

import { useTranslate } from '@tolgee/react';

interface AddPackageButtonProps {
  setPackageModalOpen: (open: boolean) => void;
}

const AddPackageButton: React.FC<AddPackageButtonProps> = ({
  setPackageModalOpen,
}) => {
  const { t } = useTranslate();
  return (
    <StyledButton
      fullWidth
      size="xlarge"
      startIcon={<PlusSquare />}
      onClick={() => setPackageModalOpen(true)}
    >
      {t('page.commandDesigner.addPackageButton', 'Configure Packages')}
    </StyledButton>
  );
};

export default AddPackageButton;
