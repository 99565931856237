import { sourceConfigurations } from '@lib/agent';

import useWorkspace from '@hooks/useWorkspace';

import { useMutation } from '@tanstack/react-query';
import {
  DefaultParamType,
  TFnType,
  TranslationKey,
  useTranslate,
} from '@tolgee/react';
import { toast } from 'react-toastify';

export default useDeleteSourceConfigurationConfigParams;

export function useDeleteSourceConfigurationConfigParams() {
  const { t } = useTranslate();
  const { workspace } = useWorkspace();

  return useMutation({
    mutationFn: deleteSourceConfigurationConfigParams,
    mutationKey: ['deleteOneSourceConfiguration', workspace?.id],
    onError: (error) => onError(t, error),
  });
}

async function deleteSourceConfigurationConfigParams(id: number) {
  return await sourceConfigurations.deleteOneSourceConfiguration(id);
}

function onError(
  t: TFnType<DefaultParamType, string, TranslationKey>,
  error: unknown,
) {
  toast.error(
    t('response.errors.code.520', 'Unknown error. Please try again later.'),
  );
  console.error(error);
}
