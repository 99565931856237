import { useState } from 'react';

import Stack from '@mui/material/Stack';

import ModalWrapper from '@components/settings/components/modal-wrapper';
import { allyProps } from '@components/settings/components/tab-panel';
import CONFIG from '@components/settings/config';
import Desktop from '@components/settings/desktop';
import Mobile from '@components/settings/mobile';
import { StyledTab, StyledTabs } from '@components/settings/styles';
import Text from '@components/text';

import useResponsive from '@hooks/useResponsive';

import { useTranslate } from '@tolgee/react';

export type Props = {
  open: boolean;
  onClose: () => void;
};

export default function Settings({ open, onClose }: Props) {
  const isItDesktop = useResponsive('up', 'md');
  const { t } = useTranslate();
  const [currentTabIndex, setCurrentTabIndex] = useState<number | false>(() => {
    return isItDesktop ? 0 : false;
  });

  function handleTabChange(
    _event: React.SyntheticEvent,
    newValue: number | false,
  ) {
    setCurrentTabIndex(newValue);
  }

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      tabs={
        <StyledTabs
          orientation="vertical"
          value={currentTabIndex}
          onChange={handleTabChange}
        >
          {CONFIG.TAB_ITEMS.map((item, index) => (
            <StyledTab
              icon={
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={3}
                >
                  {item.icon}
                  <Text
                    variant="textMd"
                    weight="semibold"
                  >
                    {t(item.mappedlabel, item.defaultLabel)}
                  </Text>
                </Stack>
              }
              {...allyProps(index)}
              disableRipple
              key={item.id}
            />
          ))}
        </StyledTabs>
      }
    >
      {isItDesktop ? (
        <Desktop
          tab={currentTabIndex}
          onClose={onClose}
        />
      ) : (
        <Mobile
          handleTabChange={handleTabChange}
          tab={currentTabIndex}
        />
      )}
    </ModalWrapper>
  );
}
