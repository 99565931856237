import { styled } from '@mui/material';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import { useTranslate } from '@tolgee/react';

const StyledStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export default function DataNotFound() {
  const { t } = useTranslate();

  return (
    <StyledStack>
      <Alert severity="info">
        {t('pages.knowledge.components.table.notFound', 'No data found')}
      </Alert>
    </StyledStack>
  );
}
