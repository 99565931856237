import { useState } from 'react';

import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import { Box } from '@mui/material';
import Button from '@mui/material/ButtonBase';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';

import Category from '@components/pages/live/sections/cards/smart-tags-card/kinds/category';
import List from '@components/pages/live/sections/cards/smart-tags-card/kinds/list';
import { StyledCard } from '@components/pages/live/styles';
import Text from '@components/text';

import { useTranslate } from '@tolgee/react';

export default function SmartTagsCard() {
  const { t } = useTranslate();
  const [showStatus, setShowStatus] = useState<'category' | 'list'>('category');

  return (
    <StyledCard sx={{ flex: 1 }}>
      <Stack gap={4}>
        <Stack
          direction="row"
          gap={3}
          sx={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Stack
            direction="row"
            gap={3}
          >
            <AutoAwesomeOutlinedIcon />
            <Text
              variant="textLg"
              weight="semibold"
              color="text.secondary"
            >
              {t(
                'page.live.grids.mode.cards.smartTagsCard.title',
                'Use Smart Tags',
              )}
            </Text>
          </Stack>
          <Stack
            direction="row"
            gap={5}
          >
            <ButtonGroup
              variant="outlined"
              disableElevation
              disableFocusRipple
              disableRipple
            >
              <Button
                disableRipple
                disableTouchRipple
                onClick={() => setShowStatus('category')}
                sx={{
                  p: 2,
                  backgroundColor:
                    showStatus === 'category'
                      ? 'background.secondary'
                      : 'transparent',
                  borderRadius: 2,
                }}
              >
                <GridViewOutlinedIcon color="disabled" />
              </Button>
              <Button
                disableRipple
                disableTouchRipple
                onClick={() => setShowStatus('list')}
                sx={{
                  p: 2,
                  backgroundColor:
                    showStatus === 'list'
                      ? 'background.secondary'
                      : 'transparent',
                  borderRadius: 2,
                }}
              >
                <FormatListBulletedOutlinedIcon color="disabled" />
              </Button>
            </ButtonGroup>
          </Stack>
        </Stack>
        <Text
          variant="textSm"
          color="text.secondary"
        >
          {t(
            'page.live.grids.mode.cards.smartTagsCard.description',
            'Automatically flag words and phrases based on pre-set criteria, like customer support needs, workplace compliance, NSFW content and more.',
          )}
        </Text>

        <Box sx={{ display: showStatus === 'list' ? 'block' : 'none' }}>
          <List />
        </Box>
        <Box sx={{ display: showStatus === 'category' ? 'block' : 'none' }}>
          <Category />
        </Box>
      </Stack>
    </StyledCard>
  );
}
