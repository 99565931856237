// TODO: too many ts errors
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';

import FormField from '@components/pages/sso/components/FormField';
import FormProvider from '@components/react-hook-form/FormProvider';

import { useTranslate } from '@tolgee/react';
// import { CommandTriggerType } from '@shared-types/agent'; TODO
import { DialogProps } from '@toolpad/core';
import { useForm } from 'react-hook-form';

export default function TriggerPicker({
  open,
  onClose,
  payload: eventType,
}: DialogProps) {
  const { t } = useTranslate();
  const formMethods = useForm<{ id: number }>();
  const { handleSubmit } = formMethods;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function onSubmit(data: any) {
    if (data.id) data.id = parseInt(data.id);
    if (data.threadId) data.threadId = parseInt(data.threadId);

    onClose({ eventType, data });
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={() => onClose()}
    >
      <FormProvider
        methods={formMethods}
        onSubmit={handleSubmit(onSubmit)}
      >
        <DialogTitle>
          {t(
            'page.commandDesigner.triggerPicker.triggerOptions',
            '{eventName} Trigger options',
            {
              eventName: eventType.charAt(0).toUpperCase() + eventType.slice(1),
            },
          )}
        </DialogTitle>
        {eventType === 'source' && (
          <FormGroup>
            <FormField
              element="text"
              name="id"
              label={t(
                'page.commandDesigner.triggerPicker.sourceId',
                'Source ID',
              )}
            />
          </FormGroup>
        )}
        {eventType === 'semantic' && (
          <FormGroup>
            <FormField
              element="text"
              name="id"
              label={t(
                'page.commandDesigner.triggerPicker.historyBlockId',
                'History Block ID',
              )}
            />
            <FormField
              element="text"
              name="threadId"
              label={t(
                'page.commandDesigner.triggerPicker.threadId',
                'Thread ID',
              )}
            />
          </FormGroup>
        )}
        <DialogActions>
          <Button
            onClick={() => onClose()}
            variant="outlined"
          >
            {t('page.commandDesigner.triggerPicker.cancel', 'Cancel')}
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            variant="contained"
          >
            {t(
              'page.commandDesigner.triggerPicker.triggerCommand',
              'Trigger Command',
            )}
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
