import { TFnType } from '@tolgee/react';
import * as Yup from 'yup';

const generateIconSchema = (t: TFnType) =>
  Yup.object().shape({
    prompt: Yup.string()
      .trim()
      .max(
        255,
        t(
          'page.workspace.validations.generateIcon.max',
          "Description can't be more than 255 characters",
        ),
      )
      .required(
        t(
          'page.workspace.validations.generateIcon.required',
          "Description can't be empty",
        ),
      ),
  });

export default generateIconSchema;
