import { TFnType } from '@tolgee/react';
import * as Yup from 'yup';

const emailSchema = (t: TFnType) =>
  Yup.object().shape({
    email: Yup.string()
      .trim()
      .email(t('page.workspace.validations.email.invalid', 'Invalid email'))
      .required(
        t('page.workspace.validations.email.required', 'Email is required'),
      ),
  });

export default emailSchema;
