import { TFnType } from '@tolgee/react';
import * as Yup from 'yup';

const verifyName = (t: TFnType) =>
  Yup.object().shape({
    name: Yup.string()
      .required(
        t('page.liveSession.validations.name.required', 'Name is required'),
      )
      .matches(/^(?!knowz ai)/, {
        message: t(
          'page.liveSession.validations.name.reservedKeyword',
          'Name cannot contain "knowz ai"',
        ),
      }), //TODO: translate
  });

export default verifyName;
