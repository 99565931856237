import { auth } from '@lib/agent';

import type { SendResetTokenType } from '@shared-types/auth';

import { useMutation } from '@tanstack/react-query';
import { useTranslate } from '@tolgee/react';
import { toast } from 'react-toastify';

async function sendResetToken(data: SendResetTokenType) {
  await auth.sendResetToken(data);
}

export default function useSendResetToken() {
  const { t } = useTranslate();

  return useMutation({
    mutationFn: sendResetToken,
    onSuccess: () => {
      toast.success(
        t(
          'layout.auth.desktop.modal.steps.sendResetToken.response.success',
          'Your code has been successfully sent.',
        ),
      );
    },
    onError: (response) => {
      toast.error(
        t(
          'layout.auth.desktop.modal.steps.sendResetToken.response.error',
          response.message,
        ),
      );
      console.error(response);
    },
  });
}
