import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

export const StyledTextArea = styled('textarea')(({ theme }) => ({
  appearance: 'none',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  resize: 'none',
  background: 'transparent',
  fontSize: '16px',
  lineHeight: '24px',
  padding: '0.7rem',
  fontFamily: 'Inter, sans-serif',
  fontWeight: 400,
  color: 'transparent',
  caretColor: theme.palette.text.primary,
  outline: 'none',
  overflow: 'hidden',
  border: 'none',
}));

export const StyledProperties = styled(Stack)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  padding: theme.spacing(4, 6),
  marginTop: '78px',
  borderTop: `1px solid ${theme.palette.background.card.main}`,
}));

export const StyledHeader = styled(Stack)`
  text-transform: capitalize;
  padding: ${({ theme }) => theme.spacing(4)} ${({ theme }) => theme.spacing(6)};
`;

export const StyledModifiableTitle = styled('input')(({ theme }) => ({
  appearance: 'none',
  boxSizing: 'border-box',
  background: theme.palette.background.card.light,
  color: theme.palette.text.primary,
  outline: 'none',
  width: '100%',
  border: 'none',
  ...theme.typography.textXl,

  '&::placeholder': {
    color: theme.palette.text.primary,
  },
}));
