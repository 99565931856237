/* eslint-disable import/prefer-default-export */
import { useCallback, useEffect, useMemo, useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { BlockStep } from '@components/pages/command-designer/extensions/block-step/blockStepExtension';
import { InputBlockWithContext } from '@components/pages/command-designer/sections/step-editor/InputBlockWithContext';
import SingleObject from '@components/pages/command-designer/sections/step-editor/SingleObject';
import Text from '@components/text';

import { BagItemType, BagItemTypes, PropertyType } from '@lib/step/types';

import { useTranslate } from '@tolgee/react';
import { DialogProps } from '@toolpad/core';
import { useConfirm } from 'material-ui-confirm';
import { useStepEditor } from 'sequential-workflow-designer-react';

type PayloadType = {
  type: 'input' | 'output';
  id?: string;
};

const getDefaultProperty = (type: 'input' | 'output'): PropertyType => ({
  id: '',
  usage: type === 'input' ? 'definition' : 'output',
  name: '',
  description: '',
  wrapper: 'item',
  types: [],
  isOptional: false,
  // options: [], // TODO: add support for options
});

function slugify(str: string): string {
  str = str.replace(/^\s+|\s+$/g, ''); // trim leading/trailing white space
  str = str.toLowerCase(); // convert string to lowercase
  str = str
    .replace(/[^a-z0-9 _]/g, '') // remove any non-alphanumeric characters
    .replace(/\s+/g, '_') // replace spaces with lowdash
    .replace(/_+/g, '_'); // remove consecutive lowdash

  return str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace('-', '').replace('_', ''),
    );
}

export function BlockPropertyEditorDialog({
  open,
  onClose,
  payload: { id, type },
}: DialogProps<PayloadType, void>) {
  const { t } = useTranslate();
  const confirm = useConfirm();

  const { step, notifyChildrenChanged } = useStepEditor();
  const [propertyType, setPropertyType] = useState<PropertyType>(
    id
      ? (step as BlockStep).propertyTypes?.find((p) => p.id === id) ||
          getDefaultProperty(type)
      : getDefaultProperty(type),
  );

  const [variant, setVariant] = useState<'default' | 'code' | 'short' | null>(
    propertyType.variant || null,
  );

  const canSave = useMemo(() => {
    return propertyType.name && propertyType.types.length > 0;
  }, [propertyType]);

  useEffect(() => {
    const supportsVariants =
      propertyType.usage === 'definition' && propertyType.wrapper === 'item';

    if (!supportsVariants) {
      setVariant(null);
    } else if (variant === null) {
      setVariant('default');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyType.usage, propertyType.wrapper]);

  useEffect(() => {
    setPropertyType({ ...propertyType, types: [] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyType.usage]);

  useEffect(() => {
    setPropertyType({ ...propertyType, id: slugify(propertyType.name) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyType.name]);

  const potentialTypes = useMemo(() => {
    return BagItemTypes.filter((type) =>
      propertyType.usage !== 'definition'
        ? type.match(/^ref-/)
        : !type.match(/^ref-/),
    );
  }, [propertyType.usage]);

  function removeStepProperty(id: string) {
    const blockStep = step as BlockStep;

    blockStep.propertyTypes = blockStep.propertyTypes.filter(
      (p) => p.id !== id,
    );
  }

  const handleDelete = useCallback(
    async function () {
      try {
        await confirm({
          title: t(
            'page.commandDesigner.blockEditor.deleteProperty',
            'Delete Property',
          ),
          description: t(
            'page.commandDesigner.blockEditor.deleteConfirmation',
            'Are you sure you want to delete this property?',
          ),
        });

        removeStepProperty(id!);
        notifyChildrenChanged();
        onClose();
      } catch (_) {
        // ignore
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id],
  );

  const handleSave = useCallback(
    function () {
      const blockStep = step as BlockStep;

      if (!blockStep.propertyTypes) {
        blockStep.propertyTypes = [];
      }

      if (variant && variant !== 'default') {
        propertyType.variant = variant;
      } else if (propertyType.variant) {
        delete propertyType.variant;
      }

      id && removeStepProperty(id);
      removeStepProperty(propertyType.id);
      blockStep.propertyTypes.unshift(propertyType);

      notifyChildrenChanged();
      onClose();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [propertyType, variant],
  );

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={(_, reason) => {
        if (reason && reason === 'backdropClick') return;
        onClose();
      }}
      sx={{
        '& .MuiDialog-container': {
          '& > .MuiPaper-root': {
            width: '90vw',
            maxWidth: '800px',
            height: '90vh',
            maxHeight: '800px',
          },
        },
      }}
    >
      <DialogTitle>
        <Text
          variant="displayXs"
          component="span"
          sx={{ px: 2 }}
        >
          {type === 'input'
            ? t(
                'page.commandDesigner.blockEditor.blockInputPropertyDefinition',
                'Block Input Property Definition',
              )
            : t(
                'page.commandDesigner.blockEditor.blockOutputPropertyDefinition',
                'Block Output Property Definition',
              )}
        </Text>

        <Text
          variant="textMd"
          sx={{ px: 2 }}
        >
          {type === 'input'
            ? t(
                'page.commandDesigner.blockEditor.inputArgumentConfig',
                'Configure an input argument for using in your block steps. Commands including this block will be able to pass data to your block by using this input.',
              )
            : t(
                'page.commandDesigner.blockEditor.outputConfig',
                "Configure an output for your block. This output will need to be defined by the code in your block's steps.",
              )}
        </Text>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ px: 2 }}>
          <SingleObject
            name={t(
              'page.commandDesigner.blockEditor.propertyName',
              'Property name',
            )}
            description={
              type === 'input'
                ? t(
                    'page.commandDesigner.blockEditor.propertyNameDescription',
                    'Assign a name to this input for reference in your block steps',
                  )
                : t(
                    'page.commandDesigner.blockEditor.propertyOutputDescription',
                    'Assign a name to this output for users to access it outside this block',
                  )
            }
            variant="short"
            value={{ type: 'string', data: propertyType.name }}
            onChange={({ data }: any) => {
              setPropertyType({
                ...propertyType,
                name: data,
              });
            }}
          />

          <SingleObject
            name={t(
              'page.commandDesigner.blockEditor.propertyDescription',
              'Property description',
            )}
            description={t(
              'page.commandDesigner.blockEditor.propertyDescriptionDescription',
              'Describe this property for users and agents to understand its purpose',
            )}
            value={{ type: 'string', data: propertyType.description }}
            onChange={({ data }: any) => {
              setPropertyType({
                ...propertyType,
                description: data,
              });
            }}
          />

          <RadioGroup
            value={propertyType.wrapper}
            onChange={(event) => {
              setPropertyType({
                ...propertyType,
                wrapper: event.target.value as 'item' | 'list',
              });
            }}
          >
            <FormControlLabel
              value="item"
              control={<Radio />}
              label={t(
                'page.commandDesigner.blockEditor.singleItemProperty',
                'This property should be a single item',
              )}
            />

            <FormControlLabel
              value="list"
              control={<Radio />}
              label={t(
                'page.commandDesigner.blockEditor.listProperty',
                'This property expect a list of items',
              )}
            />
          </RadioGroup>

          {type === 'input' && (
            <RadioGroup
              value={propertyType.usage}
              onChange={(event) => {
                setPropertyType({
                  ...propertyType,
                  usage: event.target.value as 'input' | 'definition',
                });
              }}
            >
              <FormControlLabel
                value="definition"
                control={<Radio />}
                label={t(
                  'page.commandDesigner.blockEditor.definitionProperty',
                  'This property should be defined by the user in the step configuration menu',
                )}
              />

              <FormControlLabel
                value="input"
                control={<Radio />}
                label={t(
                  'page.commandDesigner.blockEditor.mappedProperty',
                  "This property should be mapped by the user from a previous block's output",
                )}
              />
            </RadioGroup>
          )}

          <FormControlLabel
            control={<Checkbox />}
            label={t(
              'page.commandDesigner.blockEditor.optionalProperty',
              'This property is optional',
            )}
            checked={propertyType.isOptional}
            onChange={(_event, checked) => {
              setPropertyType({
                ...propertyType,
                isOptional: checked,
              });
            }}
          />

          <InputBlockWithContext
            name={t(
              'page.commandDesigner.blockEditor.definitionSupportedTypes',
              'Definition supported types',
            )}
            description={t(
              'page.commandDesigner.blockEditor.choosePropertyTypes',
              'Choose what types can be used for this property',
            )}
          >
            <Autocomplete
              multiple
              options={potentialTypes}
              value={propertyType.types}
              onChange={(event, value) => {
                setPropertyType({
                  ...propertyType,
                  types: value as BagItemType[],
                });
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    size="small"
                  />
                );
              }}
            />
          </InputBlockWithContext>

          {type === 'input' && (
            <InputBlockWithContext
              name={t(
                'page.commandDesigner.blockEditor.definitionEditingMode',
                'Definition editing mode',
              )}
            >
              <RadioGroup
                value={variant}
                onChange={(event) => {
                  setVariant(
                    event.target.value as 'default' | 'code' | 'short',
                  );
                }}
              >
                <FormControlLabel
                  value="default"
                  control={<Radio disabled={variant === null} />}
                  label={t(
                    'page.commandDesigner.blockEditor.fullDialogEditor',
                    'Full Dialog Editor',
                  )}
                />

                <FormControlLabel
                  value="code"
                  control={<Radio disabled={variant === null} />}
                  label={t(
                    'page.commandDesigner.blockEditor.fullDialogCodeEditor',
                    'Full Dialog Code Editor',
                  )}
                />

                <FormControlLabel
                  value="short"
                  control={<Radio disabled={variant === null} />}
                  label={t(
                    'page.commandDesigner.blockEditor.shortInputField',
                    'Inlined Short Input Field',
                  )}
                />
              </RadioGroup>
            </InputBlockWithContext>
          )}
        </Box>
      </DialogContent>

      <DialogActions sx={{ mt: 3 }}>
        <Stack
          justifyContent="space-between"
          direction="row-reverse"
          sx={{ width: '100%' }}
        >
          <Box>
            <Button
              onClick={() => onClose()}
              variant="outlined"
            >
              {t('common.cancel', 'Cancel')}
            </Button>

            <Button
              disabled={!canSave}
              onClick={() => handleSave()}
              variant="contained"
              sx={{ ml: 2 }}
            >
              {t('common.save', 'Save')}
            </Button>
          </Box>

          {id && (
            <Button
              color="error"
              onClick={() => handleDelete()}
              variant="outlined"
            >
              {t('common.delete', 'Delete')}
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
