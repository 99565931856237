import { useEffect, useState } from 'react';

// import Badge from '@mui/material/Badge';
import CloseIcon from '@mui/icons-material/Close';
import TabContext from '@mui/lab/TabContext';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import GroupWrapper from '@components/pages/knowledge-base/components/table/action-drawer/panels/details/group-wrapper';
import {
  StyledTab,
  StyledTabList,
  StyledTabPanel,
} from '@components/pages/knowledge-base/components/table/action-drawer/styles';
import Engagement from '@components/pages/users/engagement';
import useGetMembershipById from '@components/pages/users/hooks/useGetMembershipById';
import Folder from '@components/pages/users/side-panel/Folder';
import {
  getConnectedId,
  getEmailAlias,
  RING_FENCED_CONNECTORS,
} from '@components/pages/users/side-panel/ringfenceHelper';
import Text from '@components/text';

import calculateFullName from '@lib/calculateFullName';

import useGetAllSourceConfigurations from '@hooks/useGetAllSourceConfigurations';

import { useTranslate } from '@tolgee/react';
import sha256 from 'crypto-js/sha256';
import { format } from 'date-fns';

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  membershipId: string | null;
};

type TabValueType = 'details' | 'access';

function getGravatarUrl(email: string | null): string | null {
  return `https://www.gravatar.com/avatar/${sha256(email)}?s=64&d=retro&r=pg`;
}

export default function SidePanel({ isOpen, setIsOpen, membershipId }: Props) {
  const { t } = useTranslate();
  const { sourceConfigs = [] } = useGetAllSourceConfigurations();
  const [activeTab, setActiveTab] = useState<TabValueType>('details');
  const handleChange = (_event: React.SyntheticEvent, tab: TabValueType) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    setActiveTab('details');
  }, [membershipId]);

  const { membershipData: membership } = useGetMembershipById({
    membershipId,
  });

  let name = '';

  if (membership) {
    if (!membership.user?.firstName && !membership.user?.lastName) {
      name =
        membership.user?.email ||
        membership.emailInvite ||
        getEmailAlias(membership) ||
        'Unknown User';
    }

    name = calculateFullName({
      firstName: membership.user?.firstName,
      lastName: membership.user?.lastName,
    });
  } else {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>; // TODO
  }

  const emailAlias = getEmailAlias(membership);
  const email =
    membership?.user?.email || membership?.emailInvite || emailAlias;

  return (
    <Drawer
      open={isOpen}
      anchor="right"
      onClose={() => setIsOpen(false)}
    >
      <Stack
        direction="row"
        sx={{ p: 4, justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Stack gap={2}>
          <Text
            variant="textSm"
            weight="semibold"
          >
            {name}
          </Text>
        </Stack>

        <IconButton
          color="tertiarySecondary"
          onClick={() => setIsOpen(false)}
        >
          <CloseIcon />
        </IconButton>
      </Stack>

      <Divider />

      <Box sx={{ p: 4, width: '460px', maxHeight: '100%', overflow: 'auto' }}>
        <TabContext value={activeTab}>
          <StyledTabList
            variant="fullWidth"
            onChange={handleChange}
            aria-label="action drawer tabs"
            TabIndicatorProps={{
              style: {
                bottom: '50%',
              },
              sx: {
                height: 'calc(100% - 5px)',
                transform: 'translateY(50%)',
                backgroundColor: 'background.card.light',
                borderRadius: 2,
              },
            }}
          >
            <StyledTab
              label={'User Details'}
              value="details"
            />
            <StyledTab
              label={'Knowledge Access'}
              value="access"
            />
          </StyledTabList>

          <StyledTabPanel value="details">
            <Card
              sx={{ marginTop: 4, backgroundColor: 'background.card.main' }}
            >
              <Stack
                direction="row-reverse"
                justifyContent="space-between"
              >
                <Stack gap={4}>
                  <Avatar
                    sx={{ width: 64, height: 64 }}
                    src={
                      (membership?.user?.profilePicture ||
                        getGravatarUrl(email)) ??
                      undefined
                    }
                    alt={`${name} avatar`}
                  />
                  {/* TODO: status */}
                  {/* <Badge color="secondary" badgeContent={<CheckIcon />} /> */}
                </Stack>
                <Stack gap={4}>
                  <GroupWrapper
                    title={t('page.users.sidePanel.details.id', '#id')}
                  >
                    {membership?.id}
                  </GroupWrapper>
                  <GroupWrapper
                    title={t(
                      'page.users.sidePanel.details.firstName',
                      'First Name',
                    )}
                  >
                    {membership?.user?.firstName || '-'}
                  </GroupWrapper>
                  <GroupWrapper
                    title={t(
                      'page.users.sidePanel.details.lastName',
                      'Last Name',
                    )}
                  >
                    {membership?.user?.lastName || '-'}
                  </GroupWrapper>
                  <GroupWrapper
                    title={t('page.users.sidePanel.details.email', 'Email')}
                  >
                    {email || emailAlias || '-'}
                  </GroupWrapper>

                  {emailAlias &&
                    emailAlias !==
                      (membership?.user?.email || membership?.emailInvite) && (
                      <GroupWrapper title={'Secondary Email'}>
                        {emailAlias}
                      </GroupWrapper>
                    )}

                  <GroupWrapper
                    title={t(
                      'page.users.sidePanel.details.inviteDate',
                      'Invite Date',
                    )}
                  >
                    {membership?.inviteDate &&
                      format(new Date(membership?.inviteDate), 'MMM dd, yyyy')}
                  </GroupWrapper>
                  <GroupWrapper
                    title={t('page.users.sidePanel.details.role', 'Role')}
                  >
                    <Text
                      variant="textMd"
                      sx={{
                        textTransform: 'capitalize',
                      }}
                    >
                      {membership?.role}
                    </Text>
                  </GroupWrapper>
                </Stack>
              </Stack>
            </Card>

            <Card
              sx={{ marginTop: 4, backgroundColor: 'background.card.main' }}
            >
              <Text
                sx={{ marginBlockEnd: 2 }}
                variant="textXl"
                component="h5"
              >
                Engagement
              </Text>
              <Engagement
                sx={{ marginTop: 4 }}
                membershipId={membershipId}
              />
            </Card>
          </StyledTabPanel>

          <StyledTabPanel value="access">
            <Card sx={{ backgroundColor: 'background.card.main' }}>
              <Text
                sx={{ marginBlockEnd: 2 }}
                variant="textXl"
                component="h5"
              >
                Linked identities
              </Text>
              {sourceConfigs
                .filter((sourceConfig) =>
                  Boolean(RING_FENCED_CONNECTORS[sourceConfig.handler]),
                )
                .map((sourceConfig) => (
                  <GroupWrapper
                    key={`connector-${sourceConfig.id}`}
                    title={RING_FENCED_CONNECTORS[sourceConfig.handler].name}
                  >
                    {getConnectedId(
                      membership,
                      RING_FENCED_CONNECTORS[sourceConfig.handler].type,
                    ) || '-'}
                  </GroupWrapper>
                ))}
            </Card>

            <Card
              sx={{ marginTop: 4, backgroundColor: 'background.card.main' }}
            >
              <Text
                sx={{ marginBlockEnd: 2 }}
                variant="textXl"
                component="h5"
              >
                Knowledge Base access
              </Text>
              {membershipId && <Folder membershipId={membershipId} />}
            </Card>
          </StyledTabPanel>
        </TabContext>
      </Box>
    </Drawer>
  );
}
