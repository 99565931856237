import { auth } from '@lib/agent';

import type { ValidateResetToken } from '@shared-types/auth';

import { useMutation } from '@tanstack/react-query';
import { useTranslate } from '@tolgee/react';
import { toast } from 'react-toastify';

const validateResetToken = async (data: ValidateResetToken) =>
  await auth.validateResetToken(data);

export default function useValidateResetToken() {
  const { t } = useTranslate();

  return useMutation({
    mutationFn: validateResetToken,
    onSuccess: () => {
      toast.success(
        t(
          'layout.auth.desktop.modal.steps.verifyCode.toast.success',
          'Code has been successfully validated.',
        ),
      );
    },
    onError: (response) => {
      if (response.status === 400) {
        toast.error(
          t(
            'layout.auth.desktop.modal.steps.verifyCode.toast.error',
            'Invalid code. Please enter the correct code',
          ),
        );
      } else {
        toast.error(
          t(
            'response.errors.code.520',
            'Something went wrong. Please try again later.',
          ),
        );
      }

      console.error(response);
    },
  });
}
