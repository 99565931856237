import Tooltip from '@mui/material/Tooltip';

import useQueryParams from '@hooks/useQueryParams';

import PlusIcon from '~icons/knowz-iconify/plus';

import useUserFunnelType from '@layouts/components/credit-usage/hooks/useUserFunnelType';
import {
  StyledBox,
  StyledButtonBase,
} from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/sections/owned-workspaces/styles';
import Workspaces from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/sections/owned-workspaces/workspaces';
import { useTranslate } from '@tolgee/react';

export default function OwnedWorkspaces() {
  const { setQueryParam } = useQueryParams();
  const { t } = useTranslate();
  function handleOpenWorkspaceSetup() {
    setQueryParam('action', 'setup-workspace');
  }
  const { userFunnelType } = useUserFunnelType();
  const shouldDisableCreateButton =
    userFunnelType === 'visitor' || userFunnelType === 'free';

  return (
    <>
      <Workspaces />
      <Tooltip
        arrow
        enterTouchDelay={0}
        placement="top"
        title={
          shouldDisableCreateButton
            ? t(
                'page.workspace.createButton.cta.visitor',
                'Upgrade now to create more Knowledge Bases',
              )
            : null
        }
      >
        <StyledBox>
          <StyledButtonBase
            disableRipple
            disabled={shouldDisableCreateButton}
            disableTouchRipple
            onClick={handleOpenWorkspaceSetup}
          >
            <PlusIcon />
            &nbsp;
            {t('page.workspace.createButton.panel', 'New Knowledge Base')}
          </StyledButtonBase>
        </StyledBox>
      </Tooltip>
    </>
  );
}
