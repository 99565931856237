import { TFnType } from '@tolgee/react';
import * as Yup from 'yup';

const messageFieldSchema = (t: TFnType) =>
  Yup.object().shape({
    text: Yup.string()
      .trim()
      .required(
        t(
          'page.assistantsSession.messageField.required',
          "Message can't be empty",
        ),
      ),
  });

export default messageFieldSchema;
