import { useNavigate } from 'react-router-dom';

import { memberships } from '@lib/agent';

import useQueryAfterWorkspaceLoaded from '@hooks/useQueryAfterWorkspaceLoaded';

import paths from '@router/paths';
import { useTranslate } from '@tolgee/react';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';

export default function useGetMembershipById({
  membershipId,
}: {
  membershipId: string | null;
}) {
  const { t } = useTranslate();
  const navigateTo = useNavigate();

  const {
    data: membershipData,
    isLoading: isMembershipDataLoading,
    isError: isMembershipDataError,
  } = useQueryAfterWorkspaceLoaded({
    queryKey: ['membership', membershipId],
    options: { enabled: membershipId !== null },
    queryFn: async () => {
      try {
        const { data } = await memberships.getOneById({
          membershipId: membershipId!,
        });
        return data;
      } catch (error) {
        if (isAxiosError(error)) {
          toast.error(
            t(
              'response.errors.code.403',
              'Not allowed to access this resource.',
            ),
          );

          return navigateTo(paths.home.pathname);
        }
        throw new Error(
          `An error occurred while fetching membership: ${error}`,
        );
      }
    },
  });

  return {
    membershipData,
    isMembershipDataLoading,
    isMembershipDataError,
  };
}
