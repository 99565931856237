import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import useAuth from '@hooks/useAuth';
import useWorkspace from '@hooks/useWorkspace';

import Diamond from '~icons/knowz-iconify/diamond';

import StyledListItem from '@layouts/main/sections/toolbar/components/expanded-list-item/styles';
import WorkspacesPopover from '@layouts/main/sections/toolbar/components/workspaces-popover';
import CONFIG_TOOLBAR from '@layouts/main/sections/toolbar/config';
import { StyledNavLink } from '@layouts/main/sections/toolbar/styles';
import paths from '@router/paths';
import { useTranslate } from '@tolgee/react';

export default function ExpandedListItem() {
  const { t } = useTranslate();
  const { me } = useAuth();
  const { setupMode } = useWorkspace();

  return (
    <Box sx={{ p: 3 }}>
      <WorkspacesPopover type="expanded toolbar" />
      {CONFIG_TOOLBAR.TOOLBAR_ITEMS.filter(
        ({ isAlpha }) => me?.alpha || !isAlpha,
      )
        .filter(({ setupMode: singleSetupMode }) =>
          singleSetupMode.includes(setupMode),
        )
        .map(({ defaultLabel, icon, mappedLabel, url }, i) => (
          <StyledListItem
            to={url}
            key={i}
            component={StyledNavLink}
            disablePadding
            disableGutters
          >
            <ListItemButton>
              <ListItemIcon sx={{ fontSize: '1em' }}>{icon}</ListItemIcon>
              <ListItemText
                primary={t(mappedLabel, defaultLabel)}
                primaryTypographyProps={{
                  variant: 'textMd',
                }}
              />
            </ListItemButton>
          </StyledListItem>
        ))}
      <StyledListItem
        to={paths.enterprise.pathname}
        component={Link}
        target="_blank"
        disablePadding
        disableGutters
      >
        <ListItemButton>
          <ListItemIcon>
            <Diamond fontSize="0.9em" />
          </ListItemIcon>
          <ListItemText
            primary={t(
              'layout.toolbar.buttons.enterpriseSolutions',
              'Enterprise Solutions',
            )}
            primaryTypographyProps={{
              variant: 'textMd',
            }}
          />
        </ListItemButton>
      </StyledListItem>
    </Box>
  );
}
