import { secrets } from '@lib/agent';

import { queryClient } from '@providers/ReactQueryProvider';

import useQueryAfterWorkspaceLoaded from '@hooks/useQueryAfterWorkspaceLoaded';
import useWorkspace from '@hooks/useWorkspace';

import { useMutation } from '@tanstack/react-query';
import { TFnType, useTranslate } from '@tolgee/react';
import { toast } from 'react-toastify';

export function useCreateOneSecret() {
  const { t } = useTranslate();
  const { getNamespacedQueryKey } = useWorkspace();

  return useMutation({
    mutationFn: secrets.createOneSecret,
    mutationKey: ['createOneSecret'],
    onError: (error) => onError(t, error),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getNamespacedQueryKey('secrets'),
      });
    },
  });
}

export function useGetAllSecrets() {
  const { t } = useTranslate();

  const { data, isLoading: isLoadingSecrets } = useQueryAfterWorkspaceLoaded({
    queryKey: ['secrets'],
    queryFn: async () => {
      try {
        return await secrets.getAllSecrets();
      } catch (error) {
        onError(t, error);
      }
    },
  });

  return { secrets: data, isLoadingSecrets };
}

function onError(t: TFnType, error: unknown) {
  toast.error(
    t('response.errors.code.520', 'Unknown error. Please try again later.'),
  );
  console.error(error);
}
