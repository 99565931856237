import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import { Stack } from '@mui/material';
import { useTheme } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import Text from '@components/text';

import { getInterval, getOrdinal } from '@lib/getOrdinalNumber';

import useAuth from '@hooks/useAuth';

import { LanguageType } from '@shared-types/translation';

import ArrowRightIcon from '~icons/knowz-iconify/arrow-right';

import StyledIconButton from '@layouts/components/credit-usage/credit-copy/styles';
import useUserFunnelType, {
  UserFunnelType,
} from '@layouts/components/credit-usage/hooks/useUserFunnelType';
import paths from '@router/paths';
import { useTolgee, useTranslate } from '@tolgee/react';
import { getDay } from 'date-fns';

const SKELETON_WIDTH = '100%';
const SKELETON_HEIGHT = 20;

const TRANSLATIONS_TITLE: { [key in UserFunnelType]: string } = {
  free: 'layout.toolbar.buyAICredit.cta.free.title',
  pack: 'layout.toolbar.buyAICredit.cta.pack.title',
  subscribed: 'layout.toolbar.buyAICredit.cta.subscribed.title',
  visitor: 'layout.toolbar.buyAICredit.cta.visitor.title',
};

const TRANSLATIONS_SUBTITLE: {
  [key in UserFunnelType]: { default: string; mappedKey: string };
} = {
  free: {
    default:
      'To make the most of Knowz sign up now and buy a credit Pack or select a plan for the best value.',
    mappedKey: 'layout.toolbar.buyAICredit.cta.free.subtitle',
  },
  pack: {
    default:
      'Top up anytime with a credit pack or upgrade to a plan for the best value.',
    mappedKey: 'layout.toolbar.buyAICredit.cta.pack.subtitle',
  },
  subscribed: {
    default:
      'Top up anytime with a credit pack or upgrade to a plan for the best value.',
    mappedKey: 'layout.toolbar.buyAICredit.cta.subscribed.subtitle',
  },
  visitor: {
    default:
      'To make the most of Knowz sign up now and buy a credit Pack or select a plan for the best value.',
    mappedKey: 'layout.toolbar.buyAICredit.cta.visitor.subtitle',
  },
};

export default function CreditCopy({
  showButton,
  color,
}: {
  showButton?: boolean;
  color?: 'default' | 'inverted';
}) {
  const theme = useTheme();
  const { me, isUserAuthenticated } = useAuth();
  const { t } = useTranslate();
  const { userFunnelType } = useUserFunnelType();
  const { getLanguage } = useTolgee();
  const language = getLanguage() as LanguageType | undefined;
  // TODO: use this once t() helper supports placeholders
  const nextUpdatePlaceholder = useMemo((): string | null => {
    if (me?.subscription) {
      return (
        getDay(me.subscription.next_update) +
        getOrdinal(getDay(me.subscription.next_update), language ?? 'en')
      );
    }

    return 'beginning';
  }, [language, me?.subscription]);

  if (!isUserAuthenticated || !me) {
    return Array.from({ length: 2 }).map((_, index) => (
      <Skeleton
        key={index}
        width={SKELETON_WIDTH}
        height={SKELETON_HEIGHT}
      />
    ));
  }

  return (
    <Stack gap={1}>
      <Text
        variant="textXs"
        weight="semibold"
      >
        {t(TRANSLATIONS_TITLE[userFunnelType], {
          date: nextUpdatePlaceholder,
          interval: getInterval(
            me.subscription?.interval ?? 'month',
            language ?? 'en',
          ),
        })}
      </Text>

      <Stack
        direction="row"
        gap={1}
        justifyContent="space-between"
      >
        <Text
          variant="textXs"
          {...(color === 'inverted' ? {} : { color: 'text.secondary' })}
        >
          {t(
            TRANSLATIONS_SUBTITLE[userFunnelType].mappedKey,
            TRANSLATIONS_SUBTITLE[userFunnelType].default,
          )}
        </Text>

        {showButton && (
          <Stack justifyContent="flex-end">
            <StyledIconButton
              component={NavLink}
              to={paths.upgradeAccount.pathname}
              color="tertiaryPrimary"
              size="small"
            >
              <ArrowRightIcon color={theme.palette.primary.main} />
            </StyledIconButton>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
