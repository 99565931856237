import { PropsWithChildren } from 'react';

import getCurrentLanguageFromLocalStorage from '@lib/getCurrentLanguageFromLocalStorage';

import { LanguageType } from '@shared-types/translation';

import * as Sentry from '@sentry/react';
import { DevTools, FormatSimple, Tolgee, TolgeeProvider } from '@tolgee/react';

const DEFAULT_LANGUAGE = 'en';

export const AVAILABLE_LANGUAGES: Array<LanguageType> = [
  DEFAULT_LANGUAGE,
  'es',
  'de',
  'fr',
  'id',
  'pt',
] as const;

const tolgee = Tolgee()
  .use(DevTools())
  .use(FormatSimple())
  .init({
    availableLanguages: [...AVAILABLE_LANGUAGES],
    defaultLanguage: getCurrentLanguageFromLocalStorage(DEFAULT_LANGUAGE),
    fallbackLanguage: DEFAULT_LANGUAGE,

    // for development
    apiUrl: import.meta.env.VITE_APP_TOLGEE_API_URL,
    apiKey: import.meta.env.VITE_APP_TOLGEE_API_KEY,
    projectId: import.meta.env.VITE_APP_TOLGEE_PROJECT_ID,

    // for production
    staticData: {
      // en: () => import('../i18n/en.json'),
      // hi: () => import('../i18n/hi.json'),
    },
    onTranslationMissing: (info) => {
      console.error(`Translation missing for ${info.key}`);
      Sentry.captureException(new Error(`Translation missing for ${info.key}`));

      return 'Translation missing';
    },
  });

export default function TranslationProvider({ children }: PropsWithChildren) {
  return <TolgeeProvider tolgee={tolgee}>{children}</TolgeeProvider>;
}
