import { styled } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';

import Text from '@components/text';

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  margin: theme.spacing(0.25, 1.5),
  minWidth: theme.spacing(57.25),
  height: theme.spacing(9),
  justifyContent: 'space-between',
  flex: 1,
}));

export const StyledStack = styled(Stack)(() => ({
  width: '100%',
  alignItems: 'center',
}));

export const StyledText = styled(Text)(({ theme }) => ({
  padding: theme.spacing(1.75, 4),
}));
