import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import Modal from '@components/modal';
import renameSchema from '@components/pages/knowledge-base/components/modals/rename/schemas';
import StyledCard from '@components/pages/knowledge-base/components/modals/styles';
import useKnowledgeBaseData from '@components/pages/knowledge-base/hooks/useKnowledgeBaseData';
import useRenameByTypeAndId from '@components/pages/knowledge-base/hooks/useRename';
import FormProvider from '@components/react-hook-form/FormProvider';
import RHFInputField from '@components/react-hook-form/RHFInputField';
import Text from '@components/text';

import { queryClient } from '@providers/ReactQueryProvider';

import useGetFolderDataByPath from '@hooks/useGetFolderDataByPath';
import useQueryParams from '@hooks/useQueryParams';
import useWorkspace from '@hooks/useWorkspace';

import { FolderType } from '@shared-types/folders';
import { SourceWithMetaDataType } from '@shared-types/sources';

import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslate } from '@tolgee/react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

type Props = {
  name: string;
  id: number;
  type: 'source' | 'folder';
};

export default function Rename({ name, id, type }: Props) {
  const { t } = useTranslate();
  const { getNamespacedQueryKey } = useWorkspace();
  const { handleCloseRenameModal, isRenameModalOpenById } =
    useKnowledgeBaseData();
  const { getQueryParamByKey } = useQueryParams();
  const currentPath = getQueryParamByKey<string | null>('path');
  const currentPage = parseInt(getQueryParamByKey<string>('page', '1'));

  const { folderData } = useGetFolderDataByPath({
    path: currentPath,
  });
  const sourceId = isRenameModalOpenById!;
  const methods = useForm({
    resolver: yupResolver(renameSchema),
    defaultValues: {
      name: name ?? '',
    },
  });
  const isItRootFolder = folderData?.id === null;
  const calculateFolderId =
    typeof folderData?.id === 'undefined' ? null : folderData?.id;
  const folderQueryKey = getNamespacedQueryKey('folders', calculateFolderId);
  const sourceQueryKey = getNamespacedQueryKey('sources', {
    ...(!isItRootFolder && { folderId: folderData?.id }),
    page: currentPage,
  });

  const { mutateAsync: mutateRenameAsync } = useRenameByTypeAndId({
    type,
    onSuccess: () => {
      toast.success(
        t(
          'page.knowledgeBase.admin.actions.rename.toast.success',
          'Source has been renamed successfully',
        ),
      );
    },
    onMutate: () => {
      if (type === 'folder') {
        const prevFolders = queryClient.getQueryData<FolderType[] | null>(
          folderQueryKey,
        );

        if (!prevFolders) return;
        queryClient.setQueryData(folderQueryKey, () => {
          const indexOfFolder = prevFolders.findIndex(
            (folder) => folder.id === sourceId,
          );
          const folderData = prevFolders[indexOfFolder];
          const newFoldersData = [
            ...prevFolders.slice(0, indexOfFolder),
            {
              ...folderData,
              name: methods.getValues('name'),
            },
            ...prevFolders.slice(indexOfFolder + 1),
          ];
          return newFoldersData;
        });

        handleCloseRenameModal();
        return;
      }

      //? Source
      const prevSources =
        queryClient.getQueryData<SourceWithMetaDataType | null>(sourceQueryKey);

      if (!prevSources) return;

      queryClient.setQueryData(sourceQueryKey, (): SourceWithMetaDataType => {
        const indexOfSource = prevSources.data.findIndex(
          (source) => source.id === sourceId,
        );
        const sourceData = prevSources.data[indexOfSource];
        const newSourcesData = [
          ...prevSources.data.slice(0, indexOfSource),
          {
            ...sourceData,
            name: methods.getValues('name'),
          },
          ...prevSources.data.slice(indexOfSource + 1),
        ];
        return {
          meta: prevSources.meta,
          data: newSourcesData,
        };
      });
      handleCloseRenameModal();
    },
    onSettled: () => {
      if (type === 'folder') {
        queryClient.invalidateQueries({
          queryKey: folderQueryKey,
        });
        return;
      }
      queryClient.invalidateQueries({
        queryKey: sourceQueryKey,
      });
    },
  });

  const {
    handleSubmit,
    formState: { errors, isDirty },
  } = methods;

  async function onSubmit(data: { name: string }) {
    await mutateRenameAsync({
      id: sourceId,
      name: data.name,
    });
  }

  return (
    <Modal
      onClose={handleCloseRenameModal}
      open={sourceId === id}
      disableRestoreFocus
      width={{
        xs: '100%',
        md: 500,
      }}
    >
      <StyledCard
        component={Stack}
        gap={4}
      >
        <Text
          variant="textXl"
          fontWeight={600}
        >
          {t('page.knowledgeBase.modals.rename.title', 'Rename')}
        </Text>
        <FormProvider
          methods={methods}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Stack gap={1}>
            <Text variant="textMd">
              {t('page.knowledgeBase.modals.rename.form.name.label', 'Rename')}
            </Text>
            <RHFInputField
              autoFocus
              type="text"
              name="name"
              helperText={errors.name?.message}
              placeholder={t(
                'page.knowledgeBase.modals.rename.form.name.placeholder',
                'Enter a name',
              )}
            />
          </Stack>
          <Stack
            gap={2}
            direction="row"
            sx={{ justifyContent: 'end', mt: 6 }}
          >
            <Button
              variant="outlined"
              onClick={handleCloseRenameModal}
            >
              {t(
                'page.knowledgeBase.modals.rename.form.buttons.cancelButton',
                'Cancel',
              )}
            </Button>
            <LoadingButton
              onClick={handleSubmit(onSubmit)}
              disabled={!isDirty}
              variant="contained"
            >
              {t(
                'page.knowledgeBase.modals.rename.form.buttons.submitButton',
                'Save',
              )}
            </LoadingButton>
          </Stack>
        </FormProvider>
      </StyledCard>
    </Modal>
  );
}
