import { styled } from '@mui/material/styles';

import { convertPxToRem } from '@lib/fonts';

import 'sequential-workflow-designer/css/designer-dark.css';
import 'sequential-workflow-designer/css/designer-light.css';
import 'sequential-workflow-designer/css/designer.css';

import CONFIG_TOOLBAR from '@layouts/main/sections/toolbar/config';

export const CONFIG = {
  PADDING: 16,
  HEADER_HEIGHT: 60,
  TOP_MENUS: 60 + 16 * 2,
  EDITOR_WIDTH: 400,
};

export const StyledApp = styled('div', {
  shouldForwardProp: (props) => props !== 'isRunning' && props !== 'isLoggedIn',
})<{ isRunning: boolean; isLoggedIn: boolean }>(
  ({ theme, isRunning, isLoggedIn }) => ({
    height: '100vh',

    '.sqd-designer': {
      background: 'transparent',
    },

    '.sqd-line-grid-path': {
      // stroke: theme.palette.background.paper,
      stroke: 'transparent',
    },

    '.sqd-workspace': {
      // background: theme.palette.background.paper,
      background: 'transparent',
    },

    '.sqd-designer-react': {
      height: '100%',
    },

    '.sqd-editor': {
      height: '100%',
    },

    '.sqd-control-bar': {
      left: CONFIG.PADDING,
      bottom: CONFIG.PADDING,
      background: theme.palette.background.card.light,
      boxShadow: theme.customShadows.card,
      borderRadius: theme.shape.borderRadius * 4,
      zIndex: theme.zIndex.drawer,
    },

    '.sqd-control-bar-button': {
      border: 'none',
      background: 'transparent',

      '&:hover': {
        background: 'transparent',
      },

      '& .sqd-icon-path': {
        fill: theme.palette.text.primary,
      },
    },

    '.sqd-toolbox-toggle-icon': {
      fill: theme.palette.text.primary,
    },

    '.sqd-toolbox-header-title': {
      fontSize: theme.typography.displayXs.fontSize,
      fontWeight: theme.typography.textXl.fontWeight,
      lineHeight: theme.typography.displayXs.lineHeight,
    },

    '.sqd-toolbox': {
      zIndex: theme.zIndex.drawer,
      top: CONFIG.TOP_MENUS,
      left: CONFIG.PADDING,
      padding: CONFIG.PADDING / 2,
      boxShadow: theme.customShadows.card,
      borderRadius: theme.shape.borderRadius * 4,
      width: convertPxToRem(CONFIG_TOOLBAR.WIDTH_EXPANDED),
      background: theme.palette.background.card.light,
    },

    '.sqd-scrollbox-body': {
      width: '100%',
    },

    '.sqd-toolbox-filter': {
      width: '100%',
      margin: '0 0 0.5rem 0',
      ...theme.typography.textMd,
    },

    '.sqd-toolbox-item': {
      width: '100%',
      margin: 0,
      border: 'none',
      boxShadow: 'none',
      ...theme.typography.textMd,
      background: 'transparent',

      '.sqd-toolbox-item-text': {
        color: theme.palette.text.primary,
      },

      '&:hover': {
        background: theme.palette.action.hover,
      },

      '.sqd-toolbox-item-icon-image': {
        // TODO: remove this filter once we support icons instead of images
        filter: theme.palette.mode === 'dark' ? 'invert(1);' : '',
      },
    },

    '.sqd-toolbox-group-title': {
      display: 'none',
    },

    '.sqd-smart-editor-toggle': {
      display: 'none',
    },

    '.sqd-smart-editor': {
      position: 'fixed',
      width: CONFIG.EDITOR_WIDTH,
      top: CONFIG.TOP_MENUS,
      right: CONFIG.PADDING,
      overflow: 'hidden',
      boxShadow: theme.customShadows.card,
      borderRadius: theme.shape.borderRadius * 4,
      height: `calc(100% - ${convertPxToRem(CONFIG.PADDING)} - ${
        CONFIG.TOP_MENUS
      }px)`,
    },

    '.sqd-step-editor': {
      height: '100%',
    },

    '.sqd-editor-react': {
      display: 'flex',
      flexDirection: 'column',
      // minHeight: '100%',
      height: '100%',
      background: theme.palette.background.card.light,
    },

    // Canvas
    '.sqd-join': {
      strokeWidth: 1,
      stroke: theme.palette.background.card.main,
    },

    '.sqd-step-task .sqd-step-task-rect.sqd-selected': {
      strokeWidth: 1,
      stroke: theme.palette.brand.purple.main,
    },

    '.sqd-region.sqd-selected': {
      strokeWidth: 1,
      stroke: theme.palette.brand.purple.main,
      strokeDasharray: 1,
    },

    '.sqd-region': {
      stroke: '#cecece',
      strokeWidth: 1,
      strokeDasharray: 3,
    },

    '.sqd-root-start-stop-circle': {
      fill: theme.palette.brand.purple.main,
    },

    ...(isRunning && {
      '.sqd-workspace-canvas': {
        pointerEvents: 'none',
      },

      '.sqd-step-task .sqd-step-task-rect.sqd-selected': {
        strokeWidth: 2,
        stroke: theme.palette.error.main,
      },
    }),
  }),
);

export const StyledMenuToggle = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: CONFIG.TOP_MENUS,
  right: CONFIG.PADDING,
  zIndex: theme.zIndex.drawer,
  background: theme.palette.background.card.light,
  padding: '27px 22px', // 12 + 15, 12 + 10
  borderRadius: theme.shape.borderRadius * 4,
  width: CONFIG.EDITOR_WIDTH,
  cursor: 'pointer',

  '&.collapsed': {
    boxShadow: theme.customShadows.card,
  },

  '& > div': {
    position: 'relative',
  },
}));

export default StyledApp;
