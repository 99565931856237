import { contextRegex } from '@components/pages/command-designer/dialogs/smart-prompt-editor/contextDirectiveDescriptor';
import { BlockStep } from '@components/pages/command-designer/extensions/block-step/blockStepExtension';

import getRawIconAsBase64 from '@lib/getRawIcon';
import * as StepsDefinitions from '@lib/step';
import {
  BagItemType,
  PropertyType,
  StepsDefinitionsType,
  StepType,
} from '@lib/step/types';

import { HashMap } from '@shared-types/utils';

import {
  Definition,
  Sequence,
  Step,
  StepsConfiguration,
  ValidatorConfiguration,
} from 'sequential-workflow-designer';

export const stepsConfig: StepsConfiguration = {
  iconUrlProvider: (componentType: string, type: string) => {
    if (type.match(/-trigger$/))
      return 'https://static.webrand.com/icons/v69/svgs/lightning.svg';

    if (type.match(/-yield$/)) return getRawIconAsBase64('pause-only');

    return (
      (
        {
          'slack-message': 'https://www.svgrepo.com/show/327394/logo-slack.svg',
          'update-manifest':
            'https://static.webrand.com/icons/v93/svgs/diskette.svg',
          'load-manifest':
            'https://static.webrand.com/icons/v93/svgs/cell-align---20.svg',
          code: 'https://static.webrand.com/icons/v93/svgs/page-number-format.svg',
          structured:
            'https://static.webrand.com/icons/v93/svgs/group---20.svg',
          'dynamic-switch':
            'https://static.webrand.com/icons/v93/svgs/share.svg',
          'reference-iterator':
            'https://static.webrand.com/icons/v93/svgs/arrows-circle.svg',
          transient: 'https://static.webrand.com/icons/v93/svgs/ai---stars.svg',
          assist: 'https://static.webrand.com/icons/v93/svgs/ai---stars.svg',
          match: 'https://static.webrand.com/icons/v93/svgs/search---stars.svg',
          'design-automation':
            'https://static.webrand.com/icons/v93/svgs/fill-form-pencil.svg',
          'send-email': 'https://static.webrand.com/icons/v93/svgs/email.svg',
          request:
            'https://static.webrand.com/icons/v93/svgs/external-link.svg',
        } as HashMap<string>
      )[type] || 'https://static.webrand.com/icons/v93/svgs/effects.svg'
    );
  },

  isDraggable: (step: Step, parentSequence: Sequence) => {
    return !step.type.match(/-trigger$/);
  },

  isDeletable: (step: Step, parentSequence: Sequence) => {
    return true;
  },

  isDuplicable: (step: Step, parentSequence: Sequence) => {
    return true;
  },

  canInsertStep: (step: Step, targetSequence, targetIndex) => {
    return true;
  },

  canMoveStep: (sourceSequence, step: Step, targetSequence, targetIndex) => {
    return true;
  },

  canDeleteStep: (step: Step, parentSequence: Sequence) => {
    return true;
  },
};

export const validatorConfig: ValidatorConfiguration = {
  step: (step: Step, parentSequence: Sequence, definition: Definition) => {
    return areMandatoryPropertiesDefined() && areUsedContextVariablesValid();

    function areMandatoryPropertiesDefined() {
      const propertyTypes = getStepPropertiesDefinition(step) || [];

      for (const prop of propertyTypes) {
        if (!prop.isOptional && !step.properties[prop.id]?.data) {
          return false;
        }
      }

      return true;
    }

    function areUsedContextVariablesValid() {
      for (const id in step.properties) {
        if (step.properties[id]?.type === 'string') {
          const data = step.properties[id].data || '';

          const usedVariables = data.matchAll(contextRegex);
          const result = [];
          const types = getStepContextTypes(step as StepType);

          result.push(...GLOBAL_CONTEXT);

          for (const type of types) {
            const availableContextOfType = getAvailableOptionsOfType(
              step.id,
              type,
              definition,
            );

            result.push(
              ...availableContextOfType.map((value) => ({ value, type })),
            );
          }

          for (const usedVariable of usedVariables) {
            const type = usedVariable[2] as BagItemType;
            const value = usedVariable[1].replace(/(.+)\..*$/, '$1'); // TODO: remove this workaround once we support custom types in JSON

            const availableContextOfType = result.filter(
              (r) => r.type === type,
            );

            if (!availableContextOfType.find((r) => r.value === value)) {
              console.error(`Variable ${value} of type ${type} not found`);
              return false;
            }
          }
        }
      }

      return true;
    }
  },

  root: (definition) => {
    return (
      hasMoreThanOneStep() && startsWithTrigger() && !hasMoreThanOneTrigger()
    );

    function hasMoreThanOneStep() {
      return definition.sequence.length > 1;
    }

    function startsWithTrigger() {
      return !!definition.sequence[0].type.match(/-trigger$/);
    }

    function hasMoreThanOneTrigger() {
      return (
        definition.sequence.filter((s) => s.type.match(/-trigger$/)).length > 1
      );
    }
  },
};

export type GlobalContextType = {
  value?: string;
  type?: BagItemType;
  name?: string;
};

export const GLOBAL_CONTEXT: GlobalContextType[] = [
  { value: 'jwt', type: 'ref-global' },
  { value: 'ownerId', type: 'ref-global' },
];

function getStepFingerprint(step: StepType): string {
  return `${step.componentType}-${step.type}`;
}

export function getStepPropertiesDefinition(step: StepType): PropertyType[] {
  if (step.type === 'block') return (step as BlockStep).propertyTypes || [];
  const def = getStepDefinition(step);
  return def?.propertyTypes || [];
}

export function getStepContextTypes(step: StepType): BagItemType[] {
  const def = getStepDefinition(step);
  return def?.contextTypes || [];
}

export function getStepDescriptionDefinition(step: StepType): PropertyType[] {
  if (step.type === 'block') return (step as BlockStep).description || [];
  const def = getStepDefinition(step);
  return def?.description || [];
}

export function getStepDefinition(step: StepType): KnowzStepDefinition | null {
  const fingerprint = getStepFingerprint(step);
  const key = Object.keys(StepsDefinitions).find((key) => {
    const def = (StepsDefinitions as StepsDefinitionsType)[key];
    return getStepFingerprint(def.step) === fingerprint;
  });

  return key ? (StepsDefinitions as StepsDefinitionsType)[key] : null;
}

type ScopeType = {
  isIsolatedScope: boolean;
  availableItems: string[];
};

export function getAvailableOptionsOfType(
  id: string,
  type: string,
  definition: Definition,
): string[] {
  let currentScope: ScopeType = {
    isIsolatedScope: false,
    availableItems: [],
  };

  const scopes: ScopeType[] = [currentScope];

  visitSequence(
    (step: StepType) => {
      if (step.id === id) return true;
      currentScope.availableItems.push(...getStepOutputs(step, type));

      return false;
    },
    (step: StepType) => {
      const isBlockScope = step.type === 'block';

      currentScope = {
        isIsolatedScope: isBlockScope,
        availableItems: isBlockScope
          ? getBlockStepInputs(step as BlockStep, type)
          : [],
      };

      scopes.push(currentScope);
    },
    () => {
      scopes.pop();
      currentScope = scopes[scopes.length - 1];
    },
    definition.sequence,
  );

  if (currentScope.isIsolatedScope) {
    return currentScope.availableItems;
  }

  const opts = [];

  for (const scope of scopes) {
    opts.push(...scope.availableItems);
  }

  return opts;
}

function visitSequence(
  visitor: (step: StepType) => boolean,
  enterScope: (step: StepType) => void,
  leaveScope: () => void,
  sequence: Sequence,
): boolean {
  const boundVisitSequence = visitSequence.bind(
    null,
    visitor,
    enterScope,
    leaveScope,
  );

  for (const step of sequence) {
    const finishVisit = visitor(step as StepType);
    if (finishVisit) return true;

    if ((step as StepType).sequence) {
      enterScope(step);
      const finishVisit = boundVisitSequence((step as StepType).sequence!);
      if (finishVisit) return true;
      leaveScope();
    }

    if ((step as StepType).branches) {
      for (const branch in (step as StepType).branches) {
        enterScope(step);
        const finishVisit = boundVisitSequence(
          (step as StepType).branches![branch],
        );
        if (finishVisit) return true;
        leaveScope();
      }
    }
  }

  return false;
}

function getStepOutputs(step: StepType, type: string): string[] {
  const result = [];
  const stepPropertiesDefinition = getStepPropertiesDefinition(step);
  const outputs = stepPropertiesDefinition.reduce((acc, p) => {
    if (p.usage === 'output') acc.push(p.id);
    return acc;
  }, []);

  for (const output of outputs) {
    const property = step.properties?.[output];

    if (property && property.data && property.type === type) {
      result.push(property.data);
    }
  }

  return result;
}

function getBlockStepInputs(step: BlockStep, type: string): string[] {
  const result: string[] = [];
  const stepPropertiesDefinition = getStepPropertiesDefinition(step);
  const inputs = stepPropertiesDefinition.reduce((acc, p) => {
    if (p.usage !== 'output') acc.push(p.id);
    return acc;
  }, []);

  for (const input of inputs) {
    const property = step.propertyTypes.find((p) => p.id === input);

    if (property) {
      const isSupportedType =
        property.types.includes(type) ||
        property.types.includes(type.replace('ref-', ''));

      /**
       * Block property definitions will be treated as references and
       * exposed for usage in the parent scope
       */
      isSupportedType && result.push(input);
    }
  }

  return result;
}
