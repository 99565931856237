import { useMemo, useState } from 'react';

// import SwapVertIcon from '@mui/icons-material/SwapVert';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
// import Checkbox from '@mui/material/Checkbox';
import MuiTable from '@mui/material/Table';
import MuiTableBody from '@mui/material/TableBody';
import MuiTableCell from '@mui/material/TableCell';
import MuiTableHead from '@mui/material/TableHead';
import MuiTableRow from '@mui/material/TableRow';

import {
  StyledMuiTableHeadCell,
  StyledPaper,
} from '@components/pages/knowledge-base/components/table/styles';
// import {
//   StyledCheckbox,
//   StyledCheckboxIcon,
//   StyledIntermittentCheckbox,
// } from '@components/pages/knowledge-base/styles';
import { StyledContainerForFixedHeader } from '@components/pages/styles';
import CONFIG from '@components/pages/users/config';
import Header from '@components/pages/users/header';
// import MuiTableSortLabel from '@mui/material/TableSortLabel';
import useDeleteMembershipById from '@components/pages/users/hooks/useDeleteMembershipById';
import useGetAllMemberships from '@components/pages/users/hooks/useGetAllMemberships';
import Skeleton from '@components/pages/users/loading-skeleton';
import SidePanel from '@components/pages/users/side-panel';
import { StyledStack } from '@components/pages/users/styles';
import UserOptions from '@components/pages/users/UserOptions';
import Pagination from '@components/pagination';
import Text from '@components/text';

import calculateFullName from '@lib/calculateFullName';

import useQueryParams from '@hooks/useQueryParams';

import { MinimalUserType } from '@shared-types/auth';
import { MembershipType } from '@shared-types/memberships';

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useTranslate } from '@tolgee/react';
import { format } from 'date-fns';
import { useConfirm } from 'material-ui-confirm';

export default function Users() {
  const { getQueryParamByKey } = useQueryParams();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedMembership, setSelectedMembership] =
    useState<MembershipType | null>(null);

  const { t } = useTranslate();
  const confirm = useConfirm();

  const { mutateMembership } = useDeleteMembershipById();

  const { allMembershipsData, areAllMembershipsLoading } = useGetAllMemberships(
    {
      limit: CONFIG.PAGINATION.LIMIT,
      page: parseInt(getQueryParamByKey('page', '1')),
    },
  );

  const columns = useMemo<ColumnDef<MembershipType>[]>(
    () => [
      // {
      //   id: 'select',
      //   header: ({ table }) => {
      //     return (
      //       <Checkbox
      //         color="primary"
      //         icon={<StyledCheckboxIcon />}
      //         checkedIcon={<StyledCheckbox />}
      //         indeterminateIcon={<StyledIntermittentCheckbox />}
      //         checked={table.getIsAllRowsSelected()}
      //         indeterminate={table.getIsSomeRowsSelected()}
      //         onChange={table.getToggleAllRowsSelectedHandler()}
      //       />
      //     );
      //   },
      //   cell: ({ row }) => (
      //     <Checkbox
      //       icon={<StyledCheckboxIcon />}
      //       checkedIcon={<StyledCheckbox />}
      //       indeterminateIcon={<StyledIntermittentCheckbox />}
      //       checked={row.getIsSelected()}
      //       disabled={!row.getCanSelect()}
      //       indeterminate={row.getIsSomeSelected()}
      //       onChange={row.getToggleSelectedHandler()}
      //     />
      //   ),
      // },
      {
        header: 'User ID',
        accessorKey: 'id',
        cell: ({ row }) => {
          const user = row.original.user as MinimalUserType;

          return (
            <Text
              variant="textSm"
              color="text.primary"
            >
              {user?.email || row.original.emailInvite}
            </Text>
          );
        },
      },
      {
        header: 'Name',
        accessorKey: 'user',
        cell: ({ getValue }) => {
          const user = getValue<MinimalUserType>();

          return (
            <Text
              variant="textSm"
              color="text.primary"
            >
              {calculateFullName({
                firstName: user?.firstName,
                lastName: user?.lastName,
              })}
            </Text>
          );
        },
      },
      {
        header: 'Invite Date',
        accessorKey: 'inviteDate',
        cell: ({ getValue, column }) => {
          const inviteDate = getValue<string>();
          return (
            <Text
              variant="textSm"
              color="text.secondary"
              sx={{
                textAlign: CONFIG.COLUMN_STYLES[column.id]?.align || 'left',
              }}
            >
              {format(new Date(inviteDate), 'MMM dd, yyyy')}
            </Text>
          );
        },
      },
      {
        header: 'Status',
        accessorKey: 'status',
        cell: ({ getValue, column }) => {
          const status = getValue<number>();
          return (
            <Text
              variant="textSm"
              color="text.secondary"
              sx={{
                textAlign: CONFIG.COLUMN_STYLES[column.id]?.align || 'left',
              }}
            >
              {CONFIG.STATUS[status]}
            </Text>
          );
        },
      },
      {
        header: 'Role',
        accessorKey: 'role',
        cell: ({ getValue, column, row }) => {
          const role = getValue<MembershipType['role']>();
          return (
            <Text
              variant="textSm"
              color="text.secondary"
              sx={{
                textTransform: 'capitalize',
                textAlign: CONFIG.COLUMN_STYLES[column.id]?.align || 'left',
              }}
            >
              {role}
            </Text>
          );
        },
      },
      {
        header: '',
        accessorKey: 'options',
        cell: ({ row }) => {
          const membership = row.original as MembershipType;

          return (
            <Stack
              direction="row"
              sx={{}}
            >
              <IconButton
                aria-label="details"
                color="tertiarySecondary"
                onClick={() => {
                  setSelectedMembership(membership);
                  setIsDrawerOpen(true);
                }}
              >
                <TroubleshootIcon />
              </IconButton>

              <UserOptions
                options={[
                  {
                    text: 'Remove',
                    value: 'remove',
                    onClick: () => {
                      confirm({
                        title: t(
                          'page.users.admin.actions.delete.title',
                          'Delete User',
                        ),
                        description: t(
                          'page.users.admin.actions.delete.content',
                          'Are you sure you want to delete this user?',
                        ),
                      })
                        .then(() => mutateMembership({ id: membership.id }))
                        .catch((error) => console.error(error));
                    },
                  },
                ]}
              />
            </Stack>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const tableData = useReactTable({
    data: allMembershipsData?.data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    enableRowSelection: true,
  });

  return (
    <StyledContainerForFixedHeader
      disableGutters
      maxWidth={false}
    >
      <SidePanel
        membershipId={selectedMembership?.id || null}
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
      />

      <StyledStack gap={6}>
        <Header />
        <StyledPaper>
          <MuiTable>
            <MuiTableHead>
              <MuiTableRow>
                {tableData?.getHeaderGroups().map((headerGroup, i) => {
                  return headerGroup.headers.map((header) => {
                    // const isItSelectCell = header.id === 'select';
                    // const areTheySelectOrActionCell =
                    //   isItSelectCell || header.id === 'action';

                    return (
                      <StyledMuiTableHeadCell
                        align={
                          CONFIG.COLUMN_STYLES[header.column.id]?.align ||
                          'left'
                        }
                        key={header.column.id}
                        sx={
                          {
                            // ...(isItSelectCell && {
                            //   width: '10px',
                            // }),
                          }
                        }
                      >
                        {/* <MuiTableSortLabel
                          active={!areTheySelectOrActionCell}
                          hideSortIcon={areTheySelectOrActionCell}
                          IconComponent={SwapVertIcon}
                          sx={{
                            width: '100%',
                            justifyContent: 'space-between',
                          }}
                        > */}
                        <span>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </span>
                        {/* </MuiTableSortLabel> */}
                      </StyledMuiTableHeadCell>
                    );
                  });
                })}
              </MuiTableRow>
            </MuiTableHead>

            <MuiTableBody>
              {areAllMembershipsLoading ? (
                <Skeleton
                  headItemsConfig={tableData
                    .getAllColumns()
                    .map((column) => CONFIG.COLUMN_STYLES[column.id])}
                  headItems={tableData
                    .getAllColumns()
                    .map((column) => column.id)}
                  numberOfRows={CONFIG.PAGINATION.LIMIT}
                />
              ) : (
                tableData.getRowModel().rows.map((row) => (
                  <MuiTableRow key={row.original.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <MuiTableCell
                          key={cell.id}
                          sx={{
                            ...(CONFIG.COLUMN_STYLES[cell.column.id]?.width
                              ? {
                                  width:
                                    CONFIG.COLUMN_STYLES[cell.column.id]?.width,
                                }
                              : {}),
                          }}
                          align={
                            CONFIG.COLUMN_STYLES[cell.column.id]?.align ||
                            'left'
                          }
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </MuiTableCell>
                      );
                    })}
                  </MuiTableRow>
                ))
              )}
            </MuiTableBody>
          </MuiTable>
        </StyledPaper>

        {allMembershipsData &&
          allMembershipsData.meta.pagination.pageCount > 0 && (
            <Pagination
              pageCount={allMembershipsData.meta.pagination.pageCount}
            />
          )}
      </StyledStack>
    </StyledContainerForFixedHeader>
  );
}
