import Stack from '@mui/material/Stack';

import GroupWrapper from '@components/pages/knowledge-base/components/table/action-drawer/panels/details/group-wrapper';

import { useTranslate } from '@tolgee/react';

type Props = {
  name: React.ReactNode;
  format: React.ReactNode;
  location: React.ReactNode;
  createdBy: React.ReactNode;
  size: React.ReactNode;
  updatedAt: React.ReactNode;
  createdAt: React.ReactNode;
};

export default function Slot({
  createdAt,
  createdBy,
  format,
  location,
  name,
  size,
  updatedAt,
}: Props) {
  const { t } = useTranslate();

  return (
    <Stack gap={4}>
      <GroupWrapper
        title={t(
          'page.knowledgeBase.admin.header.actionDrawer.tab1.name',
          'Name',
        )}
      >
        {name}
      </GroupWrapper>
      <GroupWrapper
        title={t(
          'page.knowledgeBase.admin.header.actionDrawer.tab1.format',
          'Format',
        )}
      >
        {format}
      </GroupWrapper>
      <GroupWrapper
        title={t(
          'page.knowledgeBase.admin.header.actionDrawer.tab1.location',
          'Location',
        )}
      >
        {location}
      </GroupWrapper>
      <GroupWrapper
        title={t(
          'page.knowledgeBase.admin.header.actionDrawer.tab1.createdBy',
          'Created By',
        )}
      >
        {createdBy}
      </GroupWrapper>
      <GroupWrapper
        title={t(
          'page.knowledgeBase.admin.header.actionDrawer.tab1.size',
          'Size',
        )}
      >
        {size}
      </GroupWrapper>
      <GroupWrapper
        title={t(
          'page.knowledgeBase.admin.header.actionDrawer.tab1.updatedAt',
          'Updated At',
        )}
      >
        {updatedAt}
      </GroupWrapper>
      <GroupWrapper
        title={t(
          'page.knowledgeBase.admin.header.actionDrawer.tab1.createdAt',
          'Created At',
        )}
      >
        {createdAt}
      </GroupWrapper>
    </Stack>
  );
}
