import { useEffect, useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import SimpleDropdown from '@components/dropdown/simple';
import CONFIG from '@components/mode-dropdown/config';
import {
  StyledButton,
  StyledContentWrapper,
  StyledStackMode,
  StyledTextDescription,
  StyledTextName,
} from '@components/mode-dropdown/styles';
import Tooltip from '@components/search-form/react-hook-form-search-mode-select/tooltip';
import { SearchModeType } from '@components/search-form/types';
import Text from '@components/text';

import useAppDispatch from '@hooks/useAppDispatch';
import useAppSelector from '@hooks/useAppSelector';
import useAuth from '@hooks/useAuth';
import useGetCredit from '@hooks/useGetCredit';
import useLocalStorage from '@hooks/useLocalStorage';
import useQueryParams from '@hooks/useQueryParams';

import { modeUpdated } from '@state/slices/search-result';

import { useTranslate } from '@tolgee/react';

type Props = {
  onChange: (mode: keyof SearchModeType) => void;
};

export default function ModeDropdown({ onChange }: Props) {
  const { t } = useTranslate();

  const { me } = useAuth();

  const { setQueryParam } = useQueryParams();

  const { creditsData } = useGetCredit();

  const dispatch = useAppDispatch();

  const [isModeDropDownOpen, setIsModeDropDownOpen] = useState<boolean>(false);

  const hasSecureAccount =
    me?.auth_type === 'email' || me?.auth_type === 'oauth2';
  const hasCredits = creditsData && creditsData.creditAsPercentage > 0;

  const searchMode = useAppSelector((state) => state.searchResult.mode);

  const { value: defaultSearchMode } = useLocalStorage<keyof SearchModeType>(
    'defaultSearchMode',
    'fluid',
  );

  useEffect(() => {
    onChange(defaultSearchMode);
    dispatch(modeUpdated(defaultSearchMode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleModeDropdownClick() {
    if (!hasSecureAccount) {
      setQueryParam('action', 'secure-account');
      return null;
    }
    if (!hasCredits) {
      setQueryParam('action', 'upgrade-account');
      return null;
    }

    setIsModeDropDownOpen(!isModeDropDownOpen);
  }

  function handleModeSelectClick(selectedMode: keyof SearchModeType) {
    dispatch(modeUpdated(selectedMode));
  }

  return (
    <SimpleDropdown
      disablePortal
      sx={{
        menuItemSx: {
          p: 0,
        },
        popperSx: {
          width: '100%',
          transform: `translate(0, -${120 / 2.2}px)!important`,
        },
      }}
      placement="top-end"
      modifiers={[
        {
          data: {
            transform: '0',
          },
          name: 'offset',
          options: {
            offset: [8, 20],
          },
        },
      ]}
      options={Object.values(CONFIG.SEARCH_MODES).map(
        ({
          mappedDescription,
          defaultDescription,
          icon,
          name,
          defaultName,
          mappedName,
        }) => {
          return {
            text: (
              <StyledStackMode
                direction="row"
                sx={{
                  backgroundColor:
                    name === searchMode
                      ? 'background.card.light'
                      : 'transparent',
                }}
              >
                {icon}
                <StyledContentWrapper>
                  <StyledTextName
                    variant="textXl"
                    weight="semibold"
                  >
                    {t(mappedName, defaultName)}
                  </StyledTextName>
                  <StyledTextDescription variant="textMd">
                    {t(mappedDescription, defaultDescription)}
                  </StyledTextDescription>
                </StyledContentWrapper>
              </StyledStackMode>
            ),
            onClick: () => {
              handleModeSelectClick(name);
              onChange(name);
            },
            value: name,
          };
        },
      )}
      trigger={{
        element: (
          <StyledButton
            disableFocusRipple={true}
            disableRipple={true}
            endIcon={
              isModeDropDownOpen ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowUpIcon />
              )
            }
          >
            <Tooltip>
              <Text
                variant="textSm"
                weight="semibold"
                color="textPrimary"
              >
                {t(
                  CONFIG.SEARCH_MODES[searchMode].mappedName,
                  CONFIG.SEARCH_MODES[searchMode].defaultName,
                )}
              </Text>
            </Tooltip>
          </StyledButton>
        ),
        onClick: () => {
          handleModeDropdownClick();
        },
      }}
    />
  );
}
