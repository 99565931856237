// TODO: too many ts errors, need to fix
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import {
  BagItem,
  InputBlockProps,
  InputBlockWithContext,
} from '@components/pages/command-designer/sections/step-editor/InputBlockWithContext';
import { OptionsSelector } from '@components/pages/command-designer/sections/step-editor/OptionsSelector';
import {
  InputReference,
  InputReferences,
} from '@components/pages/command-designer/sections/step-editor/References';
import SingleObject from '@components/pages/command-designer/sections/step-editor/SingleObject';

import { BagItemType } from '@lib/step/types';

export default function InputBlock(props: InputBlockProps) {
  const { wrapper, usage, types, t } = props;

  if (usage === 'definition') {
    for (const type of types) {
      if (type.startsWith('ref-')) {
        const err = t(
          'page.commandDesigner.stepEditor.inputBlock.definitionError',
          'Definition properties should not be typed as reference.\nBroken property {id}, {name}.',
          { id: props.id, name: props.name },
        );

        throw new Error(err);
      }
    }

    return wrapper === 'item' ? (
      <SingleObject {...props} />
    ) : (
      <ObjectsArray {...props} />
    );
  }

  for (const type of types) {
    if (!type.startsWith('ref-')) {
      const err = t(
        'page.commandDesigner.stepEditor.inputBlock.propertyError',
        'Input and output properties should be typed as reference.\nBroken property {id}, {name}.',
        { id: props.id, name: props.name },
      );

      throw new Error(err);
    }
  }

  if (usage === 'output') {
    return <OutputReference {...props} />;
  }

  return wrapper === 'item' ? (
    <InputReference {...props} />
  ) : (
    <InputReferences {...props} />
  );
}

const StyledMarginWrapper = styled('div')`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  button:hover svg {
    fill: ${({ theme }) => theme.palette.error.main};
  }
`;

function ObjectsArray({
  id,
  types,
  name,
  description,
  value,
  onChange,
}: InputBlockProps) {
  value = value || [];

  function addValue(type: BagItemType) {
    const data = `${name.replace(/s$/, '')} ${(value as BagItem[]).length}`;
    (value as BagItem[]).push({ type, data });
    onChange(value);
  }

  function removeValue(i: number) {
    (value as BagItem[]).splice(i, 1);
    onChange(value);
  }

  return (
    <InputBlockWithContext
      name={name}
      description={description}
    >
      {value.map((v, i) => (
        <StyledMarginWrapper key={`object-array-${id}-${i}`}>
          <TextField
            fullWidth
            size="small"
            value={v.data}
            onChange={(event) => {
              value[i].data = event.target.value;
              onChange(value);
            }}
          />
          <IconButton
            size="small"
            color="tertiarySecondary"
            onClick={() => {
              removeValue(i);
            }}
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
        </StyledMarginWrapper>
      ))}

      <OptionsSelector
        types={types}
        onClick={addValue}
      />
    </InputBlockWithContext>
  );
}

function OutputReference({
  types,
  name,
  description,
  value,
  onChange,
}: InputBlockProps) {
  if (!value) value = { type: types[0] };
  const data = (value as BagItem).data || '';

  return (
    <InputBlockWithContext
      name={name}
      description={description}
    >
      <TextField
        fullWidth
        size="small"
        value={data}
        onChange={(event) => {
          value.data = event.target.value;
          onChange(value);
        }}
      />
    </InputBlockWithContext>
  );
}
