import { TFnType } from '@tolgee/react';
import * as Yup from 'yup';

const createFolderSchema = (t: TFnType) =>
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .max(
        60,
        t(
          'page.knowledgeBase.modals.createFolder.validations.max',
          "Name can't be longer than 60 characters",
        ),
      )
      .required(
        t(
          'page.knowledgeBase.modals.createFolder.validations.required',
          "Name can't be empty",
        ),
      ),
  });

export default createFolderSchema;
