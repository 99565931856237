import { workspaces } from '@lib/agent';

import { queryClient } from '@providers/ReactQueryProvider';

import useWorkspace from '@hooks/useWorkspace';

import {
  WorkspaceRequestType,
  WorkspaceResponseType,
} from '@shared-types/workspace';

import { useMutation } from '@tanstack/react-query';
import { useTranslate } from '@tolgee/react';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';

export default function useCreateWorkspace(
  onSuccess:
    | ((
        data: WorkspaceResponseType,
        variables: WorkspaceRequestType,
        context: unknown,
      ) => Promise<unknown> | unknown)
    | undefined,
) {
  const { t } = useTranslate();
  const { getNamespacedQueryKey } = useWorkspace();
  const {
    isPending: isCreateWorkspacePending,
    mutateAsync: mutateCreateWorkspaceAsync,
  } = useMutation({
    mutationKey: ['createNewWorkspace'],
    mutationFn: async ({ name }: WorkspaceRequestType) => {
      const { data } = await workspaces.createOne({
        name,
      });

      return data;
    },
    onError: (error) => {
      if (isAxiosError(error) && error.response?.status === 429) {
        toast.error(
          t(
            'response.errors.code.429',
            'Too many requests. Please try again later.',
          ),
        );
        return;
      }
      console.error(error);
    },
    onSuccess: (data, variables, context) => {
      const getQuery = queryClient.getQueryCache().find({
        queryKey: getNamespacedQueryKey('workspaces'),
        exact: false,
      });

      if (getQuery == null) return;

      const workspacesCache = queryClient.getQueryData(
        getQuery.queryKey,
      ) as WorkspaceRequestType[];

      if (getQuery && workspacesCache) {
        workspacesCache.push(data);
        queryClient.setQueryData(getQuery.queryKey, workspacesCache);
      }

      onSuccess && onSuccess(data, variables, context);
    },
  });

  return {
    isCreateWorkspacePending,
    mutateCreateWorkspaceAsync,
  };
}
