import { useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import {
  StyledButton,
  StyledStackContent,
  StyledTextContent,
} from '@components/invitation-form/components/invite-limit-tooltip/styles';
import Text from '@components/text';

import ChevronRightIcon from '~icons/knowz-iconify/chev-right';
import UsersPlusIcon from '~icons/knowz-iconify/users-plus';

import { useTranslate } from '@tolgee/react';

type Props = {
  children: React.JSX.Element;
  limitSize: number;
};

export default function InviteLimitTooltip({ children, limitSize }: Props) {
  const theme = useTheme();
  const { t } = useTranslate();

  return (
    <Tooltip
      placement="top"
      arrow
      leaveDelay={200}
      title={
        <>
          <StyledStackContent>
            <StyledTextContent>
              <UsersPlusIcon fontSize="1.4em" />
              <Text
                variant="textMd"
                weight="semibold"
              >
                {t(
                  'page.workspace.createWorkspaceModal.step3.cta.tooltip.title',
                  'Your plan can invite up to {limitSize} users',
                  {
                    limitSize,
                  },
                )}
              </Text>
            </StyledTextContent>
            <Text variant="textSm">
              {t(
                'page.workspace.createWorkspaceModal.step3.cta.tooltip.description',
                'If you need more please enquire about our Enterprise Solutions.',
              )}
            </Text>
          </StyledStackContent>
          <StyledButton>
            {t(
              'page.workspace.createWorkspaceModal.step3.cta.tooltip.buttons.enterprise',
              'Enterprise Solutions',
            )}{' '}
            &nbsp;
            <ChevronRightIcon
              fontSize="1.3em"
              color={theme.palette.utility.gray700}
            />
          </StyledButton>
        </>
      }
    >
      {children}
    </Tooltip>
  );
}
