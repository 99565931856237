import { LanguageType } from '@shared-types/translation';

export default function getCurrentLanguageFromLocalStorage(
  fallbackLang: LanguageType,
): LanguageType {
  const lang = window.localStorage.getItem('lang');

  if (!lang) {
    return fallbackLang;
  }

  return JSON.parse(lang);
}
